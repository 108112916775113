export const Data =
{
  company_name: 'HDFC ERGO',
  company_logo: 'assets/image/hdfc_ergo_logo.png',
  pdf_link: 'https://www.youtube.com/',
  company_tagline: 'e@Secure Insurance',
  plan_premium: '50,583',
  gst_amount: '8,352',
  total_premium: '30,935',
  sum_insured: '40,00,000',
  available_features:
    [
      { flag: true, name: 'Legal Protection' },
      { flag: true, name: 'Identity Theft Cover' },
      { flag: true, name: 'Social Media Cover' },
      { flag: false, name: 'Cyber Stalking' },
      { flag: true, name: 'IT Theft Loss Cover' },
      { flag: true, name: 'Malware Cover' },
      { flag: false, name: 'Phishing Cover' },
      { flag: false, name: 'E-mail Spoofing Cover' },
      { flag: true, name: 'Media Liability Claims' },
      { flag: true, name: 'Cyber Extortion Cover' },
      { flag: true, name: 'Privacy Breach' },
      { flag: false, name: 'Counselling Services' },
      { flag: true, name: 'IT Consultant Services' },
    ]
}
