import React, { useState} from 'react';

export const Info = () => {
  const [toggle, setToggle] = useState(false)
  return (
    <div className="fullwidth coverages">
      Coverages For <b style={{ fontSize: '12px', fontWeight: '700' }}>CYBER PROTECTION COVER</b>
      <div className="scroll-top d-flex align-items-center flex-column flex-nowrap">
        <div className="covered" style={{ display: 'block' }}>
          <button onClick={() => setToggle(1)} className="cover-btn" >What's Covered ?</button>
          <button onClick={() => setToggle(2)} className="cover-btn" >What's Not Covered ?</button>
        </div>
        <div className="cover-popup cover-popup1 mx-auto fadeIn" style={{ display: (toggle) ? 'block' : 'none' }}>
          <div className="coverdSection what-covered whatsCoverd" style={{ display: (toggle === 1) ? '' : 'none' }}>
            <p>Please refer Policy Wording under insurance company / plan name.</p>
          </div>
          <div className="coverdSection not-covered whatsNotCoverd" style={{ display: (toggle === 2) ? '' : 'none' }}>
            <p>Please refer Policy Wording under insurance company / plan name.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
