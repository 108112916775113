import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const UserDetails = ({ name, email, mobile_no, setEdit, edit }) => {
  return (
    <div className="col-md-12" style={{ paddingLeft: "0", paddingRight: "0" }}>
      <div className="car-left p-0">
        <ul className="row m-0">
          <li
            className="col-md-3 col-sm-6"
            style={{ paddingLeft: "0", paddingRight: "0" }}
          >
            <div>
              <p style={{ fontSize: "13px", marginLeft: "7px" }}>
                <Details>Name : </Details>
                <b
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "700",
                    fontSize: "13px",
                  }}
                >
                  {name}
                </b>
              </p>
            </div>
          </li>
          <li
            className="col-md-5 col-sm-6"
            style={{ paddingLeft: "0", paddingRight: "0" }}
          >
            <p style={{ fontSize: "13px", marginLeft: "7px" }}>
              <Details>Email ID : </Details>
              <b
                style={{
                  textTransform: "uppercase",
                  fontWeight: "700",
                  fontSize: "13px",
                }}
              >
                {email}
              </b>{" "}
            </p>
          </li>
          <li
            className="col-md-4 col-sm-6"
            style={{ paddingLeft: "0", paddingRight: "0" }}
          >
            <p style={{ fontSize: "13px", marginLeft: "7px" }}>
              <Details>Mobile No. : </Details>
              <b
                style={{
                  textTransform: "uppercase",
                  fontWeight: "700",
                  fontSize: "13px",
                }}
              >
                {mobile_no}
              </b>
            </p>
          </li>
        </ul>
        <div className="edit-btn" style={{ paddingTop: "0" }}>
          {setEdit && (
            <button onClick={() => setEdit(!edit)} className="edit edit-car">
              Edit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

// PropTypes
UserDetails.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  mobile_no: PropTypes.string,
  setEdit: PropTypes.func,
  edit: PropTypes.bool,
};

// DefaultTypes
UserDetails.defaultProps = {
  name: "User Name",
  email: "example@mail.com",
  mobile_no: "9999999999",
  setEdit: null,
  edit: false,
};

const Details = styled.span`
  @-moz-document url-prefix() {
    font-size: 13px;
  }
`;
