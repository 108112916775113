import React from "react";
import "./footerCompare.scss";

const FooterCompare = () => {
  const todayDate = new Date();
  const presentYear = todayDate.getFullYear();
  return (
    <div className="footerCompare">
      <div className="alignment">
        <div className="footersInline" style={{fontFamily:"Inter-Medium"}}>
          <span>Category: </span>Composite Broker
        </div>
        <div className="footersInline" style={{fontFamily:"Inter-Medium"}}>
          <span className="footerBold">CIN No. </span>U67200MH2003PTC141621
        </div>
        <div className="footersInline" style={{fontFamily:"Inter-Medium"}}>
          <span>IRDAI Registration No. </span>217
        </div>
        <div className="footersCopy footersInline">
           <span>Copyright &copy; {presentYear}</span> <span>Alliance Insurance Brokers Pvt.
          Ltd.</span>
        </div>
      </div>
    </div>
  );
};

export default FooterCompare;
