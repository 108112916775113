import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { setToken } from "../../modules/Landing/landing.slice";
import styled from "styled-components";
import "./landingNavbar.scss";
import CallMe from "../Popup/CallMe";
import EmailComparison from "../Popup/EmailComparison";
import { useDispatch } from "react-redux";
import { setSearchQuery } from "../../modules/Landing/landing.slice";

export const LandingNavbar = ({ isGreetings, props }) => {
  // const history = useHistory();
  const initialStyle = {
    display: "block",
    color: "#f6f6f6",
    expert: "inline-block",
  };

  const [navStyle, setNavStyle] = useState(initialStyle);
  // const { isAuthorized } = useSelector((state) => state.greeting);
  const [modal, setModal] = useState(false);
  const [modals, setModals] = useState(false);
  const location = useLocation();
  // const [done, setDone] = useState(false);
  // const dispatch = useDispatch();
  const [navCheck, setNavCheck] = useState(false);
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const id = query.get("enquiry_id");

  useEffect(() => {
    dispatch(
      setSearchQuery(
        location.search
          .replace("enquiry_id=" + id + "&", "")
          .replace("enquiry_id=" + id, "")
          .replace("dropout=true&", "")
          .replace("dropout=true", "")
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      location.pathname.includes("/loader") ||
      location.pathname.includes("/success") ||
      location.pathname.includes("/error")
    ) {
      setNavStyle({
        display: "none",
        color: "transparent",
        expert: "none",
      });
    } else if (location.pathname.includes("/detail-form")) {
      setNavStyle({
        display: "block",
        color: "transparent",
        expert: "none",
      });
    } else if (JSON.stringify(navStyle) !== JSON.stringify(initialStyle)) {
      setNavStyle(initialStyle);
    }
    setNavCheck(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div
      className={`headerDiv ${!isGreetings && "headerDiv__shadow"}`}
      style={{
        display: navStyle.display,
        backgroundColor: navStyle.color,
        borderBottom:
          navStyle.color === "transparent" ? "none" : "2px solid #dfe3e8",
      }}
    >
      <div className="head-left">
        <a className="logo" href="https://www.elephant.in/">
          {" "}
          <img
            src="/assets/image/logo.svg"
            alt="Logo"
            style={{ height: "54px", width: "160px" }}
          />
        </a>
      </div>
      {location.pathname.includes("/compare") ? (
        <div
          className="head-right"
          style={{
            paddingTop: "10px",
            paddingRight: "25px",
            display: navStyle.expert,
          }}
        >
          <CallButton style={{ paddingRight: "25px" }}>
            <a href="tel:18002669639" className="navigation__contactbtn2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#656565"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M21 15.46l-5.27-.61-2.52 2.52c-2.83-1.44-5.15-3.75-6.59-6.59l2.53-2.53L8.54 3H3.03C2.45 13.18 10.82 21.55 21 20.97v-5.51z" />
              </svg>
            </a>
          </CallButton>
          {/* <ConfirmButton width={'190px'} onClick={() => setModals(true)} style={{ backgroundColor: "rgb(246, 246, 246);" }}>
                <img
                  src={require("../../assets/images/mail.png")}
                  style={{ marginRight: "5px" }}
                  alt="imgLogo"
                  width="20"
                  height="20"
                />
            Email Comparison
          </ConfirmButton> */}
          <ConfirmButton
            onClick={() => setModal(true)}
            style={{ marginLeft: "20px" }}
          >
            <svg
              version="1.1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0,0h24v24h-24Z" fill="none"></path>
              <path
                fill="#666"
                d="M6.62,10.79c1.44,2.83 3.76,5.14 6.59,6.59l2.2,-2.2c0.27,-0.27 0.67,-0.36 1.02,-0.24c1.12,0.37 2.33,0.57 3.57,0.57c0.55,0 1,0.45 1,1v3.49c0,0.55 -0.45,1 -1,1c-9.39,0 -17,-7.61 -17,-17c0,-0.55 0.45,-1 1,-1h3.5c0.55,0 1,0.45 1,1c0,1.25 0.2,2.45 0.57,3.57c0.11,0.35 0.03,0.74 -0.25,1.02l-2.2,2.2Z"
              ></path>
            </svg>
            Talk To Expert
          </ConfirmButton>
          <EmailComparison show={modals} onClose={setModals}></EmailComparison>
          <CallMe show={modal} onClose={setModal}></CallMe>
        </div>
      ) : (
        <div
          className="head-right"
          style={{
            paddingTop: "10px",
            paddingRight: "25px",
            display: navStyle.expert,
          }}
        >
          <CallButton style={{ paddingRight: "25px" }}>
            <a href="tel:18002669639" className="navigation__contactbtn2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#656565"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M21 15.46l-5.27-.61-2.52 2.52c-2.83-1.44-5.15-3.75-6.59-6.59l2.53-2.53L8.54 3H3.03C2.45 13.18 10.82 21.55 21 20.97v-5.51z" />
              </svg>
            </a>
          </CallButton>
          <ConfirmButton
            onClick={() => setModal(true)}
            style={{ marginLeft: "20px" }}
          >
            <svg
              version="1.1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0,0h24v24h-24Z" fill="none"></path>
              <path
                fill="#666"
                d="M6.62,10.79c1.44,2.83 3.76,5.14 6.59,6.59l2.2,-2.2c0.27,-0.27 0.67,-0.36 1.02,-0.24c1.12,0.37 2.33,0.57 3.57,0.57c0.55,0 1,0.45 1,1v3.49c0,0.55 -0.45,1 -1,1c-9.39,0 -17,-7.61 -17,-17c0,-0.55 0.45,-1 1,-1h3.5c0.55,0 1,0.45 1,1c0,1.25 0.2,2.45 0.57,3.57c0.11,0.35 0.03,0.74 -0.25,1.02l-2.2,2.2Z"
              ></path>
            </svg>
            Talk To Expert
          </ConfirmButton>
          <CallMe show={modal} onClose={setModal}></CallMe>
        </div>
      )}
      <div className="head-right" style={{ paddingTop: "18px" }}>
        <nav role="navigation">
          <div
            id="menuToggle2"
            style={{ paddingTop: "20px", background: navStyle.color }}
          >
            <input
              type="checkbox"
              className="navigation__checkbox"
              id="navi-toggle"
              checked={navCheck}
              onChange={(e) => {
                setNavCheck(e.target.checked);
              }}
            />
            <label htmlFor="navi-toggle" className="navigation__button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#666666"
                width="38px"
                height="38px"
              >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
              </svg>
            </label>
            <div className="navigation__nav">
              <label htmlFor="navi-toggle" className="navigation__close">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#fff"
                >
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </label>
              <ul className="navigation__list">
                <li>
                  <a
                    href="https://www.elephant.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.elephant.in/about-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://elephant.in/#whyelephant"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Why Elephant?
                  </a>
                </li>
                <li>
                  <a
                    href="https://pos.elephant.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Become an Agent
                  </a>
                </li>
                <li>
                  <a
                    href="https://elephant.in/customerlogin"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Claims
                  </a>
                </li>
                <li>
                  <a
                    href="https://elephant.in/corporate-signup"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Corporate Sign-Up
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.elephant.in/contact_us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="clearfix"></div>
    </div>
  );
};

const ConfirmButton = styled.button`
  background-color: rgb(246, 246, 246);
  border: solid 1px #d2d3d4;
  padding: 11px 0;
  border-radius: 4px;
  z-index: 2;
  width: ${({ width }) => width || "180px"};
  height: 54px;
  font-size: 16px;
  color: #666666;
  margin-right: 0px;
  font-weight: 600;
  margin-top: -7px;
  @media (max-width: 768px) {
    display: none;
  }
  & svg {
    width: 22px;
    height: 22px;
  }
`;

const CallButton = styled.span`
  display: none;

  @media (max-width: 768px) {
    display: inline-block;
    padding-top: 5px;

    & > a > svg {
      width: 38px;
      height: 38px;
      border: 1px solid #777;
      padding: 4px;
      border-radius: 50%;
    }
  }
`;

// const MailButton = styled.span`
// display: none;
// @media (max-width: 800px) {
//   display: inline-block;
//   padding-top: 5px;
//   & > a > img {
//     width: 38px;
//     height: 38px;
//     border: 1px solid #777;
//     padding: 4px;
//     border-radius: 50%;
//   }
// }
// `;
