import React, { useState, useEffect } from "react";
import _ from "lodash";
import swal from "sweetalert";
import { UserDetails, InputForm } from "../../../components";
import {
  loadAllQuotes,
  updateUser,
  trackProgress,
  clear_compare,
  updateProgress,
  setSumInsureds,
  setPlanTypes,
} from "../plans.slice";
import { useLocation, useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { calculateSI } from "../plans.helper";
import CustomDropDown from "../../../components/CutomDropDown/CustomDropDown";
import "./style.scss";

export const InputCard = ({ searchQuery }) => {
  const comma = (str) => {
    return str.toLocaleString("en-IN");
  };
  // const [lastSeen, setLastSeen] = useState("");
  const [load, setLoad] = useState(false);
  const { policies } = useSelector((state) => state.plans);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const Lead_Source = query.get("utm_source") || "Direct Traffic";
  const Lead_Medium = query.get("utm_medium");
  const Campaign_Name = query.get("utm_campaign");

  useEffect(() => {
    // const value = calculateSI(lastSeen);
    // const value2 = calculateSI(sumInsured);
    // if (!policies?.length && family && load) {
    //   swal(
    //     "Sorry!",
    //     "The family add-on cover has been deleted since it can be availed at a Sum Insured of Rs. 5,00,000 or Rs. 20,00,000 or Rs. 50,00,000 or Rs. 1,00,00,000."
    //   );
    //   setFamily(false);
    // }
    // else
    if (!policies?.length && family && load) {
      swal(
        "Sorry!",
        "This family add-on cover can be availed at a Sum Insured of Rs. 5,00,000 or Rs. 20,00,000 or Rs. 50,00,000 or Rs. 1,00,00,000."
      );
      setFamily(false);
    } else if (!policies?.length && malware && load) {
      swal(
        "Sorry!",
        "This feature can not be availed for the Selected sum assured"
      );
      setMalware(false);
    }
    setLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policies]);

  const history = useHistory();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  // const query = new URLSearchParams(location.search);
  const { sumInsureds, planTypes } = useSelector((state) => state.plans);
  const InitialState = {
    initialFamily:
      query.get("plan_type") === "2" ||
      query.get("plan_type") === "4" ||
      planTypes === 2 ||
      planTypes === 4,
    initialMalware:
      query.get("plan_type") === "3" ||
      query.get("plan_type") === "4" ||
      planTypes === 3 ||
      planTypes === 4,
    initialSumInsured:
      (query.get("sum_insured") &&
        `Rs. ${comma(Number(query.get("sum_insured")))}`) ||
      sumInsureds ||
      "Rs. 1,00,000",
    initialPlanType: Number(query?.get("plan_type")) || planTypes || 1,
  };

  const { initialFamily, initialMalware, initialPlanType, initialSumInsured } =
    InitialState;

  const [family, setFamily] = useState(initialFamily);
  const [malware, setMalware] = useState(initialMalware);
  const [planType, setPlanType] = useState(initialPlanType);
  const [sumInsured, setSumInsured] = useState(initialSumInsured);
  const [open, getOpen] = useState(false);
  const { user, success } = useSelector((state) => state.plans);
  // const { wait } = useSelector((state) => state.greeting);
  useEffect(() => {
    if (!family && !malware) {
      setPlanType(1);
      dispatch(setPlanTypes(1));
    } else if (!family && malware) {
      setPlanType(3);
      dispatch(setPlanTypes(3));
    } else if (family && !malware) {
      setPlanType(2);
      dispatch(setPlanTypes(2));
    } else if (family && malware) {
      setPlanType(4);
      dispatch(setPlanTypes(4));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [malware, family]);

  useEffect(() => {
    const value = calculateSI(sumInsured);
    if (!enquiry_id) {
      dispatch(
        trackProgress({
          firstname: user?.firstname,
          lastname: user?.lastname,
          email: user?.email,
          mobile_no: user?.mobile_no,
          has_seen_plan: 1,
          sum_insured: [value],
          plan_type: planType,
        })
      );
    } else {
      const id = enquiry_id;
      dispatch(
        updateProgress({
          plan_type: planType,
          sum_insured: Number(value),
          user_journey_id: id,
          Lead_Source,
          Lead_Medium,
          Campaign_Name,
          is_sms_opt: true,
          is_privacy_policy: true,
        })
      );
    }
    dispatch(loadAllQuotes({ sum_insured: value, plan_type: planType }));
    dispatch(clear_compare());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sumInsured, planType, dispatch]);

  const tracking_id = enquiry_id;
  useEffect(() => {
    history.replace({
      search: `enquiry_id=${enquiry_id}${searchQuery ? "&" + searchQuery : ""}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracking_id, history]);

  useEffect(() => {
    if (success) {
      setEdit(false);
    }
  }, [success]);

  const options = [
    // "Rs. 50,000",
    "Rs. 1,00,000",
    "Rs. 3,00,000",
    "Rs. 5,00,000",
    "Rs. 10,00,000",
    "Rs. 15,00,000",
    "Rs. 20,00,000",
    "Rs. 25,00,000",
    "Rs. 50,00,000",
    "Rs. 75,00,000",
    "Rs. 1,00,00,000",
  ];

  let familyBtnText = (sumInsured) => {
    switch (sumInsured) {
      case "Rs. 3,00,000":
      case "Rs. 10,00,000":
      case "Rs. 15,00,000":
      case "Rs. 25,00,000":
      case "Rs. 75,00,000":
        return "NA";
      default:
        return (
          <div>
            <button disabled>{family ? "- REMOVE" : "+ ADD"}</button>
          </div>
        );
    }
  };

  let protectionBtnText = (sumInsured) => {
    switch (sumInsured) {
      case "Rs. 3,00,000":
      case "Rs. 10,00,000":
      case "Rs. 15,00,000":
      case "Rs. 25,00,000":
      case "Rs. 75,00,000":
        return "Covered";
      default:
        return (
          <div>
            <button disabled>{malware ? "- REMOVE" : "+ ADD"}</button>
          </div>
        );
    }
  };

  let familyToolTipText = (sumInsured) => {
    switch (sumInsured) {
      case "Rs. 3,00,000":
      case "Rs. 10,00,000":
      case "Rs. 15,00,000":
      case "Rs. 25,00,000":
      case "Rs. 75,00,000":
        return "Family cover is not available with Bajaj Allianz";
      default:
        return "Coverage for entire family including spouse, 2 dependent children (no age limit) – available only with HDFC ERGO";
    }
  };

  let protectionToolTipText = (sumInsured) => {
    switch (sumInsured) {
      case "Rs. 3,00,000":
      case "Rs. 10,00,000":
      case "Rs. 15,00,000":
      case "Rs. 25,00,000":
      case "Rs. 75,00,000":
        return "Automatically covered under Bajaj Allianz";
      default:
        return "Covers cost of restoration and recollection of digital data maximum upto 10% of Sum Insured (automatically covered under Bajaj Allianz; optional in case of HDFC ERGO)";
    }
  };

  return (
    <>
      <div className="container test20">
        <div className="fullwidth carDetails">
          <div
            className="row bg-color"
            style={{ borderRadius: "8px", padding: "5px" }}
          >
            <UserDetails
              name={`${user?.firstname} ${user?.lastname}`}
              email={user?.email}
              mobile_no={user?.mobile_no}
              edit={edit}
              setEdit={(val) => setEdit(val)}
            />
            {!_.isEmpty(user) && (
              <InputForm
                firstname={user?.firstname}
                lastname={user?.lastname}
                email={user?.email}
                mobile_no={user?.mobile_no}
                edit={edit}
                setEdit={(val) => setEdit(val)}
                dispatch={dispatch}
                updateUser={updateUser}
                enquiry_id={enquiry_id}
              />
            )}
          </div>
          <div
            className="row bg-white"
            style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" }}
          >
            <div className="col-md-3 col-sm-6 whiteBox temp border-rgt compulsory idv">
              <div
                className="covers temp cash-tip addRemove"
                // onClick={() => {
                //   if (familyBtnText(sumInsured) !== "NA")
                //     setFamily((prev) => !prev);
                // }}
              >
                <span style={{ fontWeight: "normal" }}>Family</span>
                {/* <div className='smart-tooltip'>
                  <p>{familyToolTipText(sumInsured)}</p>
                </div> */}

                <div className="add">
                  <button>{familyBtnText(sumInsured)}</button>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-sm-6 whiteBox temp border-rgt compulsory">
              <div
                className="covers temp cash-tip addRemove"
                // onClick={() => {
                //   if (protectionBtnText(sumInsured) !== "Covered")
                //     setMalware((prev) => !prev);
                // }}
              >
                <span style={{ fontWeight: "normal" }}>
                  Protection of Digital Assets from Malware
                </span>
                {/* <div className='smart-tooltip'>
                  <p>{protectionToolTipText(sumInsured)}</p>
                </div> */}
                <div className="add">
                  <button>{protectionBtnText(sumInsured)}</button>
                </div>
              </div>
            </div>
            <div
              className={`col-md-4 col-sm-6 whiteBox compulsory`}
              style={{
                background: open && "#fff",
                borderRadius: open && "5px",
                borderRight: open && "1px solid #fff",
                borderBottom: open && "3px solid #efc02e",
                boxShadow: open && "0 0px 10px rgba(0, 0, 0, 0.22)",
              }}
            >
              <div className="covers filterSelect">
                <CustomDropDown
                  searchable
                  items={options}
                  isOpen={getOpen}
                  selectedItem={sumInsured}
                  onChange={(value) => {
                    // setLastSeen(sumInsured);
                    setSumInsured(value);
                    dispatch(setSumInsureds(value));
                  }}
                  style={{ padding: "0px" }}
                ></CustomDropDown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
