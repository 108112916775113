import HttpClient from "../../api/httpClient";

const plan = (data) =>
  HttpClient("/common/get/single-plan", { method: "POST", data });

const savePlan = (data) =>
  HttpClient("/common/save/user-proposal", { method: "POST", data });

const region = (data) =>
  HttpClient("/common/get/state-city", { method: "POST", data });

const list = () => HttpClient("/common/get/features", { method: "GET" });

const tracking = (data) =>
  HttpClient("/common/create/user-journey", { method: "POST", data });

const trackingUpdate = (data) =>
  HttpClient("/common/update/user-journey", { method: "POST", data });

const savePersonal = (data) =>
  HttpClient("/common/user/save-personal-details", { method: "POST", data });

const getAllDetails = (enquiry_id) =>
  HttpClient(`/common/user/get-proposal?user_journey_id=${enquiry_id}`, {
    method: "GET",
  });

const getPersonal = (enquiry_id) =>
  HttpClient(`/common/user/get_personal-details?user_journey_id=${enquiry_id}`, {
    method: "GET",
  });

const saveSecurity = (data) =>
  HttpClient("/common/user/save_security", { method: "POST", data });

const getSecurity = (enquiry_id) =>
  HttpClient(`/common/user/get_security?user_journey_id=${enquiry_id}`);

const journeyUpdate = (data) =>
  HttpClient('/common/update/user-journey-status', { method: "POST", data });

export {
  plan,
  savePlan,
  region,
  list,
  tracking,
  savePersonal,
  trackingUpdate,
  getPersonal,
  getAllDetails,
  saveSecurity,
  getSecurity,
  journeyUpdate
};
