import React from "react";
import {
  IconlessCardRow,
  HeaderContainer,
  Header,
  BottomHeader,
  CardContentBox,
  SpanTag,
} from "./style";

const IconlessCard = ({
  children,
  title,
  round = false,
  styles,
  removeBottomHeader = false,
  headerStyle = {},
  marginTop,
  image = "",
  imageStyle = {},
  color,
  fontweight,
  padding,
  paddingLeft
}) => {
const style = round ? { borderBottomRightRadius: "0" } : {};

  return (
    <IconlessCardRow style={{ style, ...styles }} padding={padding}>
      <HeaderContainer style={{ paddingLeft: `${paddingLeft ? paddingLeft : "10px"}` }} color={color || "black"} fontweight={fontweight}>
        <Header style={headerStyle} fontweight={fontweight} >
          {!!image && (
            <SpanTag style={imageStyle}>
              <img src={image} alt={"N/A"} width={77} height={77} />
            </SpanTag>
          )}
          {title}
          {removeBottomHeader ? "" : <BottomHeader></BottomHeader>}
        </Header>
      </HeaderContainer>
      <CardContentBox marginTop={marginTop}>{children}</CardContentBox>
    </IconlessCardRow>
  );
};

export default IconlessCard;
