import { createSlice } from "@reduxjs/toolkit";
import { service } from "./plans.service";
import _ from "lodash";


export const plans = createSlice({
  name: "plans",
  initialState: {
    loading: false,
    error: null,
    success: null,
    compare: [],
    compare_active: false,
    policies: [],
    phone: "",
    email: "",
    user: {},
    sendDetails: { sum_assured: 100000, plan_type: 1 },
    features: [],
    trackingresponse: {},
    sumInsureds: "",
    planTypes: "",
    userData: {}
  },
  reducers: {
    setSendDetails: (state, { payload }) => {
      state.sendDetails = { ...payload };
    },
    loading: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    success: (state, { payload }) => {
      state.loading = null;
      state.error = null;
      state.success = payload;
    },

    error: (state, { payload }) => {
      let message = " ";
      if (typeof payload === "string") message = payload;
      else if (typeof payload === "object") {
        for (const property in payload) {
          message = `${message}
          ${payload[property][0]}`;
        }
      }
      state.loading = null;
      state.error = message;
      state.success = null;
    },

    clear: (state) => {
      state.error = null;
      state.success = null;
    },

    clear_loading: (state) => {
      state.loading = null;
      state.error = null;
      state.success = null;
    },

    set_compare: (state, { payload }) => {
      const flag = state.compare?.some((policy) => policy.id === payload.id);
      if (state.compare.length === 4 && !flag) {
        const copyState = _.cloneDeep(state.compare);
        copyState.pop();
        copyState.push(payload);
        state.compare = copyState;
      }
      else if (!flag) state.compare = [...state.compare, payload];
    },

    remove_compare: (state, { payload }) => {
      const newData = state.compare.filter((elem) => payload !== elem.id);
      state.compare = newData;
    },

    clear_compare: (state) => {
      state.compare = [];
    },

    set_compare_active: (state, { payload }) => {
      state.compare_active = payload;
    },

    clear_compare_active: (state) => {
      state.compare_active = false;
    },

    policies: (state, { payload }) => {
      state.policies = payload;
      state.loading = null;
    },

    clear_policies: (state) => {
      state.policies = [];
    },

    load_user: (state, { payload }) => {
      state.user = payload;
      state.loading = null;
    },

    clear_user: (state) => {
      state.user = {};
    },

    load_features: (state, { payload }) => {
      state.features = payload;
      state.loading = null;
    },

    clear_features: (state) => {
      state.features = [];
    },

    trackLog: (state, action) => {
      state.trackingresponse = action.payload;
    },

    setPhone: (state, action) => {
      state.phone = action.payload;
    },

    setEmail: (state, action) => {
      state.email = action.payload;
    },

    setPlanTypes: (state, action) => {
      state.planTypes = action.payload
    },

    setSumInsureds: (state, action) => {
      state.sumInsureds = action.payload
    },

  },
});

export const {
  loading,
  success,
  error,
  clear,
  clear_loading,
  set_compare,
  clear_compare,
  set_compare_active,
  clear_compare_active,
  remove_compare,
  policies,
  clear_policies,
  load_user,
  clear_user,
  load_features,
  clear_features,
  trackLog,
  setSendDetails,
  setPhone,
  setEmail,
  setPlanTypes,
  setSumInsureds,
  setFamilyBtn
} = plans.actions;

//---------- Action creators ----------//

export const loadAllQuotes = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(loading());
      const { data } = await service.getAllQuotes(payload);
      if (data.data) {
        dispatch(policies(data.data));
      }
      else {
        if (data.errors) dispatch(error(data.errors));
        if (data.message) dispatch(error(data.message));
      }
    }
    catch (err) {
      dispatch(error("Something Went Wrong"));
    }
  };
};

export const updateUser = (payload, enquiry_id) => {
  return async (dispatch) => {
    try {
      dispatch(loading());
      const { data } = await service.updateUser({
        ...payload,
        user_journey_id: enquiry_id,
      });
      if (data?.status) {
        dispatch(load_user(payload));
        dispatch(setPhone(payload.mobile_no));
        dispatch(setEmail(payload.email));
      }
      else {
        if (data.errors) dispatch(error(data.errors));
        if (data.message) dispatch(error(data.message));
      }
    }
    catch (err) {
      dispatch(error("Something Went Wrong"));
    }
  };
};

// export const loadUser = (userData) => {
//   return async (dispatch) => {
//     try {
//       if (userData) {
//         dispatch(load_user(userData));
//         dispatch(setPhone(userData.mobile_no));
//         dispatch(setEmail(userData.email));
//       }
//     }
//     catch (err) {
//       dispatch(error("Something Went Wrong"));
//     }
//   };
// };

export const loadAllFeatures = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(loading());
      const { data } = await service.getAllFeatures(payload);
      if (data.data) {
        dispatch(load_features(data.data));
      }
      else {
        if (data.errors) dispatch(error(data.errors));
        if (data.message) dispatch(error(data.message));
      }
    }
    catch (err) {
      dispatch(error("Something Went Wrong"));
    }
  };
};

export const trackProgress = (data) => {
  return async (dispatch) => {
    try {
      const trackingresponse = await service.tracking(data);
      if (trackingresponse?.data) {
        dispatch(trackLog(trackingresponse));
      }
      else {
        console.error("Tracking failed");
      }
    }
    catch (err) {
      console.error("Tracking failed");
    }
  };
};

export const updateProgress = (data) => {
  return async (dispatch) => {
    try {
      dispatch(
        setSendDetails({
          sum_assured: data.sum_insured,
          plan_type: data.plan_type,
        })
      );
      const trackingresponse = await service.trackingUpdate(data);
      if (trackingresponse?.data) {
        dispatch(trackLog(trackingresponse));
      }
      else {
        console.error("Tracking failed");
      }
    }
    catch (err) {
      console.error("Tracking failed");
    }
  };
};

export const sendEmail = (data) => {
  return async (dispatch) => {
    try {
      const response = await service.sendQuotes(data);
      if (response?.data) {
        // dispatch(success("Email Sent"));
      }
      else {
        // dispatch(error("Email Not Sent , Try Again"));
      }
    }
    catch (err) {
      dispatch(error("Something Went Wrong"));
    }
  };
};

export default plans.reducer;
