/* eslint-disable eqeqeq */
import React from "react";
import styled from "styled-components";
import { Button } from "../";
import { useLocation } from "react-router-dom";

const Success = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  return (
    <Container>
      <div class="avtar paymentMsg">
        <div class="avtar-img">
          <img src="/assets/image/laxmiImg.png" alt="success" />
        </div>
        <div class="icon">
          <img src="/assets/image/success.png" alt="success" />
        </div>
        <h2>Thank you for making the payment</h2>

        {query.get("policyNo") == "" ? (
          ""
        ) : (
          <h4>Policy Number: {query.get("policyNo")}</h4>
        )}

        {/* <h4>Policy Number: {query.get("policyNo")}</h4> */}

        <p className="mb-2">Your Transaction is Successful!</p>
        <p className="mb-2">
          Your policy copy will be shared in some time on your email id.
        </p>

        <a
          href={`${
            process.env.REACT_APP_API_BASE_URL.split("in/api")[0]
          }in/policy_pdf/${query.get("policyNo")}.pdf`}
          rel="noopener noreferrer"
          target="_blank"
          download
          className="brochure"
        >
          <Button>
            <p className="p-0">Download Policy</p>
          </Button>
        </a>

        {/* -------------------------------production------------------------ */}
        {/* <a
          href={`https://apicyber.elephant.in/policy_pdf/${policyNo}.pdf`}
          rel="noopener noreferrer"
          target="_blank"
          download
          className="brochure"
        >
          <Button >
            <p className="p-0">Download Policy</p>
          </Button>
        </a> */}
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin: 0;
`;

export default Success;
