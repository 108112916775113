import React from 'react';
import './404.css';

const Error = () => {

    return (
        <section className="container pageHeading">
            <div className="row">
                <div className="col-12 nlSVG d-flex justify-content-center">
                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 288 185.5">
                        <defs>
                            <linearGradient id="Degradado_sin_nombre_57" x1="244.98" y1="89.37" x2="246.49" y2="89.37" gradientTransform="matrix(1, 0.06, -0.06, 1, 5.44, -13.44)" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stop-color="#0088c6" stop-opacity="0.8"></stop>
                                <stop offset="0.99" stop-color="#0088c6" stop-opacity="0"></stop>
                            </linearGradient>
                        </defs>
                        <title>404</title>
                        <path className="cls-1-404" d="M107.8,162.55c-.07-.63-1-1.19-1.46-1.59a1.1,1.1,0,0,0-1.65,0c-1.16,1.23-1,3.81-.62,5.61s2.09,3.78,2.26,5,1.62,4,1.79,2.71C108.25,173.2,108.05,165.05,107.8,162.55Z"></path>
                        <path className="cls-2-404" d="M107.42,171.4a32.81,32.81,0,0,1-1.39-5.89c-.3-1.61-.51-2.93-.51-2.94l.16,0s.22,1.34.51,2.94a30.42,30.42,0,0,0,1.37,5.83Z"></path>
                        <path className="cls-2-404" d="M106.15,166a4.44,4.44,0,0,1-1.39-1.07l.14-.09a4.78,4.78,0,0,0,1.32,1Z"></path>
                        <path className="cls-2-404" d="M106.1,165.2l-.11-.12a3.24,3.24,0,0,0,.87-1.31H107A3.23,3.23,0,0,1,106.1,165.2Z"></path>
                        <path className="cls-2-404" d="M106.75,168.16l-.12-.11a2.16,2.16,0,0,0,.41-.58l.16.07A2.49,2.49,0,0,1,106.75,168.16Z"></path>
                        <path className="cls-2-404" d="M106.86,169.52c-.15-.1-.89-.63-1-.86l.16,0a4.07,4.07,0,0,0,.9.78Z"></path>
                        <path className="cls-3-404" d="M131.69,167.82c1.21-.48,3.92.52,3.8,2.78s-4.36,2.29-6.63,2.43a4.65,4.65,0,0,0-3.35,1.42l-.66-1.64S128.86,169,131.69,167.82Z"></path>
                        <path className="cls-2-404" d="M126.24,172.61l-.09-.18a45.14,45.14,0,0,1,7.39-2.4l0,.2A45.07,45.07,0,0,0,126.24,172.61Z"></path>
                        <path className="cls-2-404" d="M131.27,170.82a.62.62,0,0,1,0-.5c.21-.65,1.3-1.2,1.35-1.22l.09.18s-1.07.54-1.24,1.1a.42.42,0,0,0,0,.35Z"></path>
                        <path className="cls-2-404" d="M130.37,171l.14-.14c.31.3,1.2,1,1.72.9l.05.2C131.55,172.21,130.42,171.09,130.37,171Z"></path>
                        <path className="cls-2-404" d="M128.94,171.36a1.91,1.91,0,0,1,.93-1l.08.19a1.68,1.68,0,0,0-.81.9Z"></path>
                        <path className="cls-4-404" d="M125.49,174.51c.06.08,1.07-3.82,3.58-5.51,1.81-1.22,5.84-6.67,5.32-9.62-.39-2.16-2-4.59-5.16-1.49S127,165,126.3,167.17c-.59,1.88-3.23,5.13-3.47,5.94S125.49,174.51,125.49,174.51Z"></path>
                        <path className="cls-2-404" d="M125.57,171.52l-.15-.09a42.73,42.73,0,0,1,2.82-4c1.43-1.84,3.17-8.56,3.19-8.63l.16,0c-.07.28-1.77,6.82-3.22,8.69A39.24,39.24,0,0,0,125.57,171.52Z"></path>
                        <path className="cls-2-404" d="M130.37,162.67l.07-.15c.46.2,1.64-.83,2.06-1.25l.11.12C132.45,161.55,131,163,130.37,162.67Z"></path>
                        <path className="cls-2-404" d="M129.19,165.78a5.61,5.61,0,0,1-.61-2.9h.16a5.62,5.62,0,0,0,.58,2.8Z"></path>
                        <path className="cls-2-404" d="M127.69,168.44v-.16c.32,0,.87,0,1-.2l.14.1C128.61,168.49,127.79,168.45,127.69,168.44Z"></path>
                        <path className="cls-1-404" d="M234.26,162.55c-.06-.63-1-1.19-1.45-1.59a1.1,1.1,0,0,0-1.65,0c-1.16,1.23-1,3.81-.62,5.61s2.09,3.78,2.26,5,1.62,4,1.78,2.71C234.72,173.2,234.52,165.05,234.26,162.55Z"></path>
                        <path className="cls-2-404" d="M233.89,171.4a32.81,32.81,0,0,1-1.39-5.89c-.3-1.61-.51-2.93-.51-2.94l.16,0s.22,1.34.51,2.94a30.42,30.42,0,0,0,1.37,5.83Z"></path>
                        <path className="cls-2-404" d="M232.61,166a4.68,4.68,0,0,1-1.39-1.07l.15-.09a4.78,4.78,0,0,0,1.32,1Z"></path>
                        <path className="cls-2-404" d="M232.57,165.2l-.11-.12s.81-.79.87-1.31h.16A3.23,3.23,0,0,1,232.57,165.2Z"></path>
                        <path className="cls-2-404" d="M233.22,168.16l-.12-.11a2.42,2.42,0,0,0,.41-.58l.15.07A2.29,2.29,0,0,1,233.22,168.16Z"></path>
                        <path className="cls-2-404" d="M233.33,169.52c-.15-.1-.89-.63-1-.86l.16,0a4.07,4.07,0,0,0,.9.78Z"></path>
                        <path className="cls-3-404" d="M258.16,167.82c1.21-.48,3.92.52,3.8,2.78s-4.36,2.29-6.63,2.43a4.65,4.65,0,0,0-3.35,1.42l-.66-1.64S255.33,169,258.16,167.82Z"></path>
                        <path className="cls-2-404" d="M252.71,172.61l-.1-.18A45.76,45.76,0,0,1,260,170l.05.2A45.07,45.07,0,0,0,252.71,172.61Z"></path>
                        <path className="cls-2-404" d="M257.74,170.82a.62.62,0,0,1,0-.5c.21-.65,1.3-1.2,1.35-1.22l.09.18s-1.07.54-1.25,1.1a.42.42,0,0,0,0,.35Z"></path>
                        <path className="cls-2-404" d="M256.84,171l.14-.14c.31.3,1.2,1,1.71.9l.06.2C258,172.21,256.89,171.09,256.84,171Z"></path>
                        <path className="cls-2-404" d="M255.41,171.36a1.91,1.91,0,0,1,.93-1l.08.19a1.68,1.68,0,0,0-.81.9Z"></path>
                        <path className="cls-4-404" d="M252,174.51c.06.08,1.07-3.82,3.57-5.51,1.81-1.22,5.85-6.67,5.32-9.62-.38-2.16-2-4.59-5.16-1.49s-2.26,7.15-2.92,9.28c-.59,1.88-3.23,5.13-3.47,5.94S252,174.51,252,174.51Z"></path>
                        <path className="cls-2-404" d="M252,171.52l-.14-.09a39,39,0,0,1,2.82-4c1.43-1.84,3.17-8.56,3.19-8.63l.16,0c-.07.28-1.77,6.82-3.22,8.69A41.7,41.7,0,0,0,252,171.52Z"></path>
                        <path className="cls-2-404" d="M256.84,162.67l.07-.15c.46.2,1.64-.83,2.06-1.25l.11.12C258.92,161.55,257.5,163,256.84,162.67Z"></path>
                        <path className="cls-2-404" d="M255.65,165.78a5.56,5.56,0,0,1-.6-2.9h.16a5.62,5.62,0,0,0,.58,2.8Z"></path>
                        <path className="cls-2-404" d="M254.16,168.44v-.16c.32,0,.87,0,1-.2l.13.1C255.08,168.49,254.26,168.45,254.16,168.44Z"></path>
                        <path className="cls-5-404" d="M55.92,162.84h10.5a.6.6,0,0,0,.45-1l-10.1-10.9-.89.82L65,161.63H55.92Z"></path>
                        <path className="cls-5-404" d="M55.38,37.74H67.23v130H55.38ZM66,39H56.59V166.53H66Z"></path>
                        <rect className="cls-6-404" x="53.76" y="161.37" width="15.09" height="10.82" transform="translate(122.62 333.56) rotate(180)"></rect>
                        <path className="cls-5-404" d="M55.92,118.08h10.5a.62.62,0,0,0,.56-.36.64.64,0,0,0-.11-.66l-10.1-10.9-.89.83L65,116.87H55.92Z"></path>
                        <path className="cls-5-404" d="M55.92,129.27h10.5a.6.6,0,0,0,.45-1l-10.1-10.91-.89.83L65,128.06H55.92Z"></path>
                        <path className="cls-5-404" d="M55.92,140.46h10.5a.6.6,0,0,0,.45-1l-10.1-10.91-.89.83L65,139.25H55.92Z"></path>
                        <path className="cls-5-404" d="M55.92,151.65h10.5a.6.6,0,0,0,.45-1l-10.1-10.9-.89.82L65,150.44H55.92Z"></path>
                        <path className="cls-5-404" d="M55.92,107.16h10.5a.62.62,0,0,0,.56-.36.64.64,0,0,0-.11-.66l-10.1-10.9-.89.83L65,106H55.92Z"></path>
                        <path className="cls-5-404" d="M55.92,96.24h10.5a.62.62,0,0,0,.56-.36.64.64,0,0,0-.11-.66l-10.1-10.9-.89.82L65,95H55.92Z"></path>
                        <path className="cls-5-404" d="M55.92,85.32h10.5A.61.61,0,0,0,67,85a.62.62,0,0,0-.11-.65L56.77,73.4l-.89.82L65,84.11H55.92Z"></path>
                        <path className="cls-5-404" d="M55.92,74.4h10.5a.61.61,0,0,0,.45-1l-10.1-10.9-.89.82L65,73.19H55.92Z"></path>
                        <path className="cls-5-404" d="M55.92,63.47h10.5a.6.6,0,0,0,.45-1l-10.1-10.9-.89.82L65,62.26H55.92Z"></path>
                        <path className="cls-5-404" d="M55.92,52.55h10.5a.6.6,0,0,0,.45-1l-10.1-10.9-.89.82L65,51.34H55.92Z"></path>
                        <path className="cls-5-404" d="M55.92,41.63h10.5a.62.62,0,0,0,.56-.36.64.64,0,0,0-.11-.66l-10.1-10.9-.89.83L65,40.42H55.92Z"></path>
                        <rect className="cls-6-404" x="55.4" y="25.65" width="19.21" height="16.19" transform="translate(130.02 67.5) rotate(180)"></rect>
                        <rect className="cls-6-404" x="26.54" y="25.65" width="19.21" height="9.3" transform="translate(72.28 60.61) rotate(180)"></rect>
                        <path className="cls-5-404" d="M26.85,26.84h94.88a.61.61,0,1,0,0-1.21H26.85a.61.61,0,0,0,0,1.21Z"></path>
                        <rect className="cls-7-404" x="116.72" y="29.06" width="0.61" height="39.36"></rect>
                        <rect className="cls-5-404" x="48.61" y="170.25" width="25.39" height="4.76" transform="translate(122.62 345.26) rotate(180)"></rect>
                        <path className="cls-5-404" d="M64.94,26.68a.3.3,0,0,0,.21-.09l5.06-5.06h46.38l4.76,4.76a.3.3,0,0,0,.43,0,.32.32,0,0,0,0-.43l-4.94-4.93H70l-5.24,5.23a.3.3,0,0,0,0,.43A.31.31,0,0,0,64.94,26.68Z"></path>
                        <path className="cls-5-404" d="M26.45,26.68a.32.32,0,0,0,.27-.16l5.06-9.68H44.06l4.76,9.37a.3.3,0,0,0,.41.13.29.29,0,0,0,.13-.4l-4.93-9.7h-13l-5.24,10a.29.29,0,0,0,.13.4A.25.25,0,0,0,26.45,26.68Z"></path>
                        <path className="cls-2-404" d="M114.8,29.06A2.22,2.22,0,1,0,117,26.84,2.23,2.23,0,0,0,114.8,29.06Z"></path>
                        <path className="cls-5-404" d="M103.89,21.53a.31.31,0,0,0,.29-.22.3.3,0,0,0-.2-.38L59.15,7.26a.38.38,0,0,0-.21,0l-21.25,9a.31.31,0,0,0-.16.4.3.3,0,0,0,.4.16L59.08,7.88l44.73,13.63Z"></path>
                        <path className="cls-5-404" d="M66.11,26.72a.57.57,0,0,0,.22,0,.61.61,0,0,0,.35-.78L59.62,7.32A.62.62,0,0,0,59,6.93a.61.61,0,0,0-.54.49L54.81,25.54a.61.61,0,0,0,.47.71.62.62,0,0,0,.72-.48L59.23,9.71l6.32,16.62A.6.6,0,0,0,66.11,26.72Z"></path>
                        <path className="cls-8-404" d="M57.83,7.54a1.22,1.22,0,1,0,2.44,0,1.22,1.22,0,0,0-2.44,0Z"></path>
                        <path className="cls-5-404" d="M110.28,26.74a.37.37,0,0,0,.19-.07l5.94-4.81v4.5a.31.31,0,0,0,.61,0V21.23a.3.3,0,0,0-.17-.27.29.29,0,0,0-.32,0l-6.44,5.21a.3.3,0,0,0,.19.54Z"></path>
                        <path className="cls-5-404" d="M104.53,26.74a.37.37,0,0,0,.19-.07l5.94-4.81v4.5a.31.31,0,0,0,.61,0V21.23a.3.3,0,0,0-.17-.27.29.29,0,0,0-.32,0l-6.44,5.21a.3.3,0,0,0,.19.54Z"></path>
                        <path className="cls-5-404" d="M98.78,26.74a.37.37,0,0,0,.19-.07l5.94-4.81v4.5a.31.31,0,0,0,.61,0V21.23a.3.3,0,0,0-.17-.27.29.29,0,0,0-.32,0L98.59,26.2a.3.3,0,0,0,.19.54Z"></path>
                        <path className="cls-5-404" d="M93,26.74a.37.37,0,0,0,.19-.07l5.94-4.81v4.5a.31.31,0,0,0,.61,0V21.23A.3.3,0,0,0,99.6,21a.31.31,0,0,0-.33,0L92.84,26.2a.28.28,0,0,0,0,.42A.3.3,0,0,0,93,26.74Z"></path>
                        <path className="cls-5-404" d="M87.28,26.74a.37.37,0,0,0,.19-.07l5.94-4.81v4.5a.31.31,0,0,0,.61,0V21.23a.3.3,0,0,0-.17-.27.31.31,0,0,0-.33,0L87.08,26.2a.29.29,0,0,0,0,.42A.3.3,0,0,0,87.28,26.74Z"></path>
                        <path className="cls-5-404" d="M81.52,26.74a.32.32,0,0,0,.19-.07l6-4.81v4.5a.31.31,0,0,0,.61,0V21.23a.29.29,0,0,0-.18-.27.29.29,0,0,0-.32,0L81.33,26.2a.29.29,0,0,0,0,.42A.29.29,0,0,0,81.52,26.74Z"></path>
                        <path className="cls-5-404" d="M75.77,26.74a.32.32,0,0,0,.19-.07l6-4.81v4.5a.31.31,0,0,0,.61,0V21.23a.29.29,0,0,0-.18-.27A.29.29,0,0,0,82,21L75.58,26.2a.29.29,0,0,0,0,.42A.29.29,0,0,0,75.77,26.74Z"></path>
                        <path className="cls-5-404" d="M70,26.74a.32.32,0,0,0,.19-.07l6-4.81v4.5a.31.31,0,0,0,.61,0V21.23a.29.29,0,0,0-.18-.27.29.29,0,0,0-.32,0L69.83,26.2a.29.29,0,0,0,0,.42A.29.29,0,0,0,70,26.74Z"></path>
                        <path className="cls-5-404" d="M36,26.74a.31.31,0,0,0,.23-.11L44,17.51v8.85a.31.31,0,0,0,.61,0V16.69a.31.31,0,0,0-.2-.29.33.33,0,0,0-.34.09L35.8,26.24a.3.3,0,0,0,0,.42A.32.32,0,0,0,36,26.74Z"></path>
                        <path className="cls-5-404" d="M30.09,26.74a.3.3,0,0,0,.25-.14l5.89-8.9v8.66a.3.3,0,0,0,.6,0V16.69a.31.31,0,0,0-.21-.29.3.3,0,0,0-.34.12l-6.44,9.75a.3.3,0,0,0,.08.42A.34.34,0,0,0,30.09,26.74Z"></path>
                        <path className="cls-7-404" d="M115.12,69.23a1.91,1.91,0,1,0,1.9-1.91A1.91,1.91,0,0,0,115.12,69.23Z"></path>
                        <path className="cls-9-404" d="M135.43,155.37h-8.61v19.31H109.59V155.37H77.2V142.61l26.89-45.16h22.73v43.91h8.61Zm-25.84-14V130.05c0-4.88.52-12.25.72-17.13h-.41c-2,4.36-4.05,8.82-6.23,13.18l-8.82,15.26Z"></path>
                        <path className="cls-9-404" d="M142.38,135.65c0-26.57,11-39.65,27.3-39.65s27.41,13.29,27.41,39.65c0,26.57-11.11,40.48-27.41,40.48S142.38,162.22,142.38,135.65Zm37.27,0c0-21.07-4.46-25.43-10-25.43s-9.86,4.36-9.86,25.43,4.36,26.16,9.86,26.16S179.65,156.83,179.65,135.65Z"></path>
                        <path className="cls-9-404" d="M261.85,155.37h-8.61v19.31H236V155.37H203.62V142.61l26.89-45.16h22.73v43.91h8.61Zm-25.84-14V130.05c0-4.88.52-12.25.72-17.13h-.41c-2,4.36-4,8.82-6.23,13.18l-8.82,15.26Z"></path>
                        <path className="cls-6-404" d="M133.83,155.37h-8.62v19.31H108V155.37H75.6V142.61l26.88-45.16h22.73v43.91h8.62Zm-25.85-14V130.05c0-4.88.52-12.25.73-17.13h-.41c-2,4.36-4,8.82-6.23,13.18l-8.83,15.26Z"></path>
                        <path className="cls-6-404" d="M140.78,135.65c0-26.57,11-39.65,27.3-39.65s27.4,13.29,27.4,39.65c0,26.57-11.11,40.48-27.4,40.48S140.78,162.22,140.78,135.65Zm37.26,0c0-21.07-4.46-25.43-10-25.43s-9.86,4.36-9.86,25.43,4.36,26.16,9.86,26.16S178,156.83,178,135.65Z"></path>
                        <path className="cls-6-404" d="M260.25,155.37h-8.62v19.31H234.4V155.37H202V142.61L228.9,97.45h22.73v43.91h8.62Zm-25.85-14V130.05c0-4.88.52-12.25.73-17.13h-.42c-2,4.36-4.05,8.82-6.23,13.18l-8.82,15.26Z"></path>
                        <rect className="cls-7-404" x="196.84" y="132.58" width="0.54" height="43.8" transform="matrix(0.99, -0.16, 0.16, 0.99, -22.66, 34.41)"></rect>
                        <rect className="cls-7-404" x="183.66" y="132.58" width="0.54" height="43.8" transform="translate(-22.84 32.25) rotate(-9.44)"></rect>
                        <rect className="cls-7-404" x="181.87" y="141.79" width="13.3" height="0.54"></rect>
                        <rect className="cls-7-404" x="182.81" y="147.56" width="13.31" height="0.54"></rect>
                        <rect className="cls-7-404" x="183.75" y="153.33" width="13.3" height="0.54"></rect>
                        <rect className="cls-7-404" x="184.69" y="159.1" width="13.3" height="0.54"></rect>
                        <rect className="cls-7-404" x="185.64" y="164.87" width="13.3" height="0.54"></rect>
                        <rect className="cls-7-404" x="186.58" y="170.64" width="13.31" height="0.54"></rect>
                        <rect className="cls-7-404" x="154.45" y="154.21" width="43.8" height="0.54" transform="translate(-4.97 303.1) rotate(-80.55)"></rect>
                        <rect className="cls-7-404" x="167.64" y="154.21" width="43.8" height="0.54" transform="matrix(0.16, -0.99, 0.99, 0.16, 6.05, 316.1)"></rect>
                        <rect className="cls-7-404" x="178.3" y="141.79" width="13.3" height="0.54"></rect>
                        <rect className="cls-7-404" x="177.36" y="147.56" width="13.3" height="0.54"></rect>
                        <rect className="cls-7-404" x="176.41" y="153.33" width="13.31" height="0.54"></rect>
                        <rect className="cls-7-404" x="175.47" y="159.1" width="13.31" height="0.54"></rect>
                        <rect className="cls-7-404" x="174.53" y="164.87" width="13.3" height="0.54"></rect>
                        <rect className="cls-7-404" x="173.59" y="170.64" width="13.3" height="0.54"></rect>
                        <rect className="cls-7-404" x="178.87" y="132.2" width="16.23" height="1.62"></rect>
                        <polygon className="cls-10-404" points="184.47 122.81 183.89 116.88 183.15 110.54 183.72 110.46 185.04 116.47 187.1 116.64 189.24 110.54 189.82 110.63 188.25 117.13 188.34 122.65 184.47 122.81"></polygon>
                        <path className="cls-11-404" d="M184.05,110.21a.49.49,0,1,1-.49-.49A.49.49,0,0,1,184.05,110.21Z"></path>
                        <path className="cls-11-404" d="M189.82,110.38a.5.5,0,1,1-.5-.49A.49.49,0,0,1,189.82,110.38Z"></path>
                        <path className="cls-12-404" d="M187,115.75l0,0a1,1,0,0,0,.08-.14v0a.34.34,0,0,0,.06-.12l0,0s0-.09,0-.13a1.27,1.27,0,0,0,0-.43,1,1,0,0,0-.08-.25,1.07,1.07,0,0,0-.32-.44l-.1-.07-.08,0-.2-.07h0a.66.66,0,0,0-.23,0h-.05l-.19,0h0a.76.76,0,0,0-.2.06h0a1.09,1.09,0,0,0-.6.69c-.16.57.09,1.48.66,1.64a.92.92,0,0,0,.79-.22h0a2,2,0,0,0,.26-.25h0Z"></path>
                        <polygon className="cls-13-404" points="188.34 122.65 187.76 131.74 187.14 131.74 186.6 122.72 186.04 122.75 185.7 131.96 185.21 131.95 184.47 122.81 184.36 121.69 188.32 121.74 188.34 122.65"></polygon>
                        <polygon className="cls-12-404" points="187.76 131.74 189.15 131.89 189.1 132.11 187.07 132.11 187.14 131.74 187.76 131.74"></polygon>
                        <polygon className="cls-12-404" points="185.7 131.96 185.7 132.22 183.8 132.29 183.8 132.06 185.21 131.95 185.7 131.96"></polygon>
                        <polygon className="cls-14-404" points="245.44 87.41 246.33 88.99 247.11 91.97 249.72 96.76 249.27 96.85 246.07 92.83 244.59 89.5 245.44 87.41"></polygon>
                        <polygon className="cls-15-404" points="239.64 89.38 236.15 95.2 236.6 95.37 240.82 89.97 239.64 89.38"></polygon>
                        <path className="cls-16-404" d="M244.84,90.06l.89,2,.67-2.81-.07-.25-.57-1-.12,0L245.25,89Z"></path>
                        <path className="cls-11-404" d="M238.2,88.1c.62.1,1.39.74,1.29,1.36s-1,1-1.66.88a1.14,1.14,0,1,1,.37-2.24Z"></path>
                        <path className="cls-11-404" d="M237.4,90.07a2.42,2.42,0,0,0-.12.35c0,.09,0,.21.07.22s.08,0,.12,0a.81.81,0,0,0,.28-.33.39.39,0,0,0,0-.35.32.32,0,0,0-.27-.16A.65.65,0,0,1,237.4,90.07Z"></path>
                        <path className="cls-15-404" d="M239.5,89.33c0-.58-.73-1.14-1.3-1.23a1.13,1.13,0,0,0-1.31.94.37.37,0,0,0,0,.11,1,1,0,0,0,.26.08,1.1,1.1,0,0,0,.86-.4A7.28,7.28,0,0,1,239.5,89.33Z"></path>
                        <path className="cls-11-404" d="M238.16,88.71a.44.44,0,0,1,.15-.23c.23-.19.57,0,.6.31a.71.71,0,0,1,0,.25.31.31,0,0,1-.12.19.2.2,0,0,1-.2,0,.44.44,0,0,1-.2-.08A.51.51,0,0,1,238.16,88.71Z"></path>
                        <path className="cls-5-404" d="M239.38,92.36l-2.18,3.78.49,0,2.79-2.83.39-3.63s0-1.11-.5-1-.73.68-.73.68Z"></path>
                        <path className="cls-5-404" d="M239.44,88.72a17.9,17.9,0,0,1,4.81-1.46l1.23,2.4-5.53.9Z"></path>
                        <path className="cls-14-404" d="M244.25,87.26h.34a1.64,1.64,0,0,1,1.7,1.64l-1.83,3.3.33,4.75h-.5l-1.08-4.48Z"></path>
                        <ellipse className="cls-11-404" cx="236.23" cy="95.64" rx="0.56" ry="0.58"></ellipse>
                        <path className="cls-11-404" d="M238.05,96.22a.56.56,0,1,1-.55-.58A.57.57,0,0,1,238.05,96.22Z"></path>
                        <path className="cls-5-404" d="M244.79,96.94l.09.47-2.38.06s.13-.35.21-.32,1.58-.21,1.58-.21Z"></path>
                        <polygon className="cls-5-404" points="249.72 96.76 249.93 97.32 247.68 97.25 247.75 96.97 249.27 96.85 249.72 96.76"></polygon>
                        <path className="cls-17-404" d="M117,68.23c39.66,40.34,72.3,56.45,119.21,28"></path>
                    </svg>
                </div>
                <div className="col-12 nlHeading d-flex justify-content-center">Sorry, the page you were looking for could not be found.</div>
                <div className="col-12 nlSubHeading justify-content-center">You can return to our &nbsp;<a href="https://www.elephant.in/">home page</a>&nbsp;or&nbsp;<a href="https://www.elephant.in/contact_us">contact us</a>, if you can’t find what you are looking for.</div>
            </div >
        </section>
    );
};

export default Error;
