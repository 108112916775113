
export const featureTitle = [
    'Legal protection',
    'IT Theft Loss Cover / Unauthorized Online Transactions',
    'Social Media Cover/ Identity Theft Cover',
    'Media Liability Claims Cover / Damage to E-Reputation',
    'Phishing Cover',
    'E-mail Spoofing Cover',
    'Cyber Extortion Cover / E-Extortion',
    'Cyber Stalking / Cyber Bullying or Harassment',
    'Counselling Services / Psychological counseling',
    'Privacy Breach and Data Breach by Third Party',
    'IT Consultant Services Cover',
    'Malware Cover / Protection of Digital Assets from Malware',
    'Family',
]
