import styled from "styled-components";
import { ConfirmButton } from "../";

const CardComponent = styled.div`
  border: none;
  border-radius: 0 35px 0 35px;
  background-color: #fff;
  transition: all 0.3s ease 0s;
  box-shadow: 1px 4px 26px 7px #dcdcdc;
  width: auto;
  margin: 30px 10px 10px 10px;
  @media (max-width: 767px) {
    margin: 24px 10px;
  }
`;

const CardBody = styled(CardComponent)`
  padding: ${(props) => props.padding || "13.6px"};
`;

const CardRow = styled(CardBody)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
`;

const Row1 = styled(CardBody)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
`;

const IconlessCardRow = styled(CardBody)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 10px;
`;

const Container2 = styled.div`
  box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
`;

const HeaderContainer = styled.div`
  box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
  color: ${(props) => props.color || "black"};
`;

const Header = styled.div`
  font-size: 18px;
  font-weight: ${(props) => props.fontweight || "650"};
  letter-spacing: 0px;
  margin-left: 8px;
  .icon {
    font-size: 32px;
    color: #fff;
    background: #000;
    height: 40px;
    width: 40px;
    text-align: right;
    position: absolute;
    left: -24px;
    top: -24px;
    border-radius: 3px 5px 20px 5px;
    background-color: #f2c9fb;
  }
  @media only screen and (max-width: 767px) and (min-width: 480px) {
    margin-top: -5px;
  }
  @media (max-width: 767px) {
    margin-top: -5px;
  }
`;

const HeaderBlue = styled.div`
  margin-top: -16px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  .iconBlue {
    font-size: 32px;
    color: #fff;
    background: #000;
    height: 45px;
    width: 45px;
    text-align: right;
    position: absolute;
    left: -28px;
    top: -29px;
    border-radius: 100px;
    background-color: #6334e3;
  }
  @media only screen and (max-width: 767px) and (min-width: 480px) {
    margin-top: -5px;
  }
  @media (max-width: 767px) {
    margin-top: -5px;
  }
`;

const BottomHeader = styled.div`
  border-bottom: 1px dotted #d0ff37;
  margin-right: 0%;
  margin-left: 0%;
  width: inherit;
  margin-top: 8px;
`;

const Container3 = styled.div`
  box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: auto;
  padding-right: 18px;
  padding-left: 18px;
  margin-top: 3rem !important;
`;

const CardContentBox = styled.div`
  box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: auto;
  padding-right: 15px;
  padding-left: 10px;
  margin-top: ${(props) => props.marginTop || "3rem !important"};
`;

const SpanTag = styled.div`
  margin-left: -80px;
  margin-top: -80px;
  @media (max-width: 992px) {
    margin-left: -50px;
    margin-top: -60px;
    text-align: center;
  }
`;

const CloseBtn = styled(ConfirmButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  border-radius: 100%;
  background-color: #18478b;
  color: #fff;
  opacity: 1;
  padding: 0;
  font-weight: normal;
  font-size: 24px;
  text-shadow: none;
  margin-top: -35px;
`;

export {
  HeaderContainer,
  CardContentBox,
  CardRow,
  Row1,
  IconlessCardRow,
  Container2,
  Header,
  BottomHeader,
  Container3,
  HeaderBlue,
  SpanTag,
  CloseBtn
};
