import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { lowerCase, validateForm } from "../../utils";
import { numOnly, noNum } from "../../modules/Landing/utils";
import { ConfirmButton } from "../";
import styled from "styled-components";

export const InputForm = ({
  edit,
  firstname,
  lastname,
  email,
  mobile_no,
  dispatch,
  updateUser,
  setEdit,
  enquiry_id,
}) => {
  const [validate, setValidate] = useState({});
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      firstname: firstname,
      lastname: lastname,
      email: email,
      mobile_no: mobile_no,
    },
  });

  useEffect(() => {
    reset({
      firstname: firstname,
      lastname: lastname,
      email: email,
      mobile_no: mobile_no,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  const onSubmit = (data) => {
    const validation = validateForm(data);
    if (validation.formIsValid) {
      dispatch(updateUser(data, enquiry_id));
      setEdit(false);
    } else {
      setValidate(validation);
    }
  };

  return (
    <div
      className={`fullwidth details-tooltip ${edit ? "toggle" : ""}`}
      style={{ marginTop: "7px" }}
    >
      <div className='col-md-12 car-detail active'>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id='carDetailsForm'
          className='bg-white'
        >
          <div
            className='detail-title head-left'
            style={{
              textAlign: "left",
              backgroundColor: "#e7e7e8",
              padding: "5px",
              fontSize: "12px",
              fontWeight: "bold",
              borderRadius: "10px 0px 10px",
            }}
          >
            PERSONAL DETAILS
          </div>
          <CloseBtn
            type='button'
            onClick={() => {
              setEdit(false);
            }}
            className='float-right'
          >
            &times;
          </CloseBtn>
          <div className='detail-div'>
            <ul>
              <li>
                <label htmlFor='sel1'>First Name</label>
                <input
                  type='text'
                  className='requiredField text-capitalize'
                  ref={register}
                  placeholder='Enter First Name'
                  name='firstname'
                  onInput={(e) => {
                    lowerCase(e);
                    noNum(e);
                  }}
                />
                {!validate?.errors?.firstname && (
                  <p className='text-danger'>{validate?.errors?.firstname}</p>
                )}
              </li>
              <li>
                <label htmlFor='sel2'>Last Name</label>
                <input
                  type='text'
                  className='requiredField text-capitalize'
                  ref={register}
                  placeholder='Enter Last Name'
                  name='lastname'
                  onInput={(e) => {
                    lowerCase(e);
                    noNum(e);
                  }}
                />
                {validate?.errors?.lastname && (
                  <span className='text-danger'>
                    {validate?.errors?.lastname}
                  </span>
                )}
              </li>
              <li>
                <label htmlFor='sel3'>Email ID</label>
                <input
                  type='email'
                  className='requiredField'
                  ref={register}
                  onInput={lowerCase}
                  placeholder='Enter Email ID'
                  pattern='^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{1,})$'
                  name='email'
                />
                {validate?.errors?.email && (
                  <span className='text-danger'>{validate?.errors?.email}</span>
                )}
              </li>
              <li>
                <label htmlFor='sel4'>Mobile Number</label>
                <input
                  type='text'
                  maxlength='10'
                  onKeyPress={numOnly}
                  pattern='[6789][0-9]{9}'
                  title='Phone number with 6-9 and remaing 9 digit with 0-9'
                  className='requiredField'
                  ref={register}
                  placeholder='Enter Mobile Number'
                  name='mobile_no'
                />
                {validate?.errors?.mobile_no && (
                  <span className='text-danger'>
                    {validate?.errors?.mobile_no}
                  </span>
                )}
              </li>
            </ul>
            <div>
              <div className='row d-flex justify-content-end'>
                <ConfirmButton
                  type={"submit"}
                  className='btn text-right m-2'
                  style={{
                    cursor: "pointer",
                    border: "none",
                    backgroundColor: "white",
                    textDecoration: "underline",
                    color: "#0081c1",
                    height: "17px",
                    fontWeight: "normal",
                  }}
                >
                  <Save>SAVE</Save>
                </ConfirmButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const CloseBtn = styled(ConfirmButton)`
  width: 30px;
  height: 0px;
  border-radius: 100%;
  background-color: white;
  color: #a5a3a3;
  // opacity: 1;
  padding: 0;
  font-weight: normal;
  font-size: 25px;
  margin-top: -8px;
  text-shadow: none;
`;

const Save = styled.span`
  @-moz-document url-prefix() {
    position: relative;
    top: 50px;
    padding: 0;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: relative;
    top: 50px;
    padding: 0;
    right: 45px;
  }
`;
