import * as yup from "yup";

const mobile = /[6-9]{1}[0-9]{9}$/;

export const callSchema = yup.object({
  phone: yup
    .string()
    .required("Mobile No. is required")
    .min(10, "Mobile No. should be 10 digits")
    .matches(mobile, "Please enter valid mobile no.")
    .label("Mobile No."),
  // email: yup
  //   .string()
  //   .email("Please enter valid email id")
  //   .required("Email id is required")
  //   .label("Email ID")
});

export const mailSchema = yup.object({
  // phone: yup
  //   .string()
  //   .required("Mobile No. is required")
  //   .min(10, "Mobile No. should be 10 digits")
  //   .matches(mobile, "Please enter valid mobile no.")
  //   .label("Mobile No."),
  email: yup
    .string()
    .email("Please enter valid email id")
    .required("Email id is required")
    .label("Email ID")
});


