import { createSlice } from "@reduxjs/toolkit";
import {
  createUser,
  tracking,
  callMe,
  resendEmailApi,
  getToken,
  mailMe,
  loadDiscount,
} from "./serviceApi";
import { getFirstError } from "../../utils";
import { load_user, setPhone, setEmail } from "../plans/plans.slice";

// const ls = new SecureLS();
const greeting = createSlice({
  name: "greeting",
  initialState: {
    loading: false,
    response: {},
    error: null,
    trackingresponse: {},
    enquiry_id: null,
    setSearchQuery: "",
    discount: {},
    discountByMobile: false,
    discountByEmail: false,
    resendEmail: false,
    footerCompany: "",
    isPnbDomain:true,
  },

  reducers: {
    setisPnbDomain: (state, action) => {
      state.isPnbDomain = action.payload;
    },
    createUserToken: (state, action) => {
      state.response = action.payload;
    },
    trackLog: (state, action) => {
      state.trackingresponse = action.payload;
    },
    errorStatus: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    errorCleanup: (state, action) => {
      state.error = null;
      state.loading = false;
    },
    // setWait: (state, action) => {
    //   state.wait = action.payload;
    // },
    // setIsAuthorized: (state, action) => {
    //   state.isAuthorized = action.payload;
    // },
    setEnquiryId: (state, { payload }) => {
      state.enquiry_id = payload;
    },
    loading: (state, { payload }) => {
      state.loading = payload;
    },
    setSearchQuery: (state, { payload }) => {
      let fixString = payload.replace("?", "");
      fixString = fixString.replace("enquiry_id=" + state.enquiry_id + "&", "");
      state.searchQuery = fixString;
    },
    discount: (state, { payload }) => {
      state.discount = payload;
    },
    discountByMobile: (state, { payload }) => {
      state.discountByMobile = payload;
    },
    discountByEmail: (state, { payload }) => {
      state.discountByEmail = payload;
    },
    resendEmail: (state, { payload }) => {
      state.resendEmail = payload;
    },
    footerCompany: (state, { payload }) => {
      state.footerCompany = payload;
    },
  },
});

export const {
  setisPnbDomain,
  createUserToken,
  errorStatus,
  errorCleanup,
  trackLog,
  // setWait,
  // setIsAuthorized,
  setEnquiryId,
  loading,
  setSearchQuery,
  discount,
  discountByMobile,
  discountByEmail,
  resendEmail,
  footerCompany,
} = greeting.actions;

// Action creators
export const handleCreateUser = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await createUser(payload);
      if (response?.data) {
        dispatch(createUserToken(response));
        if (response?.data?.status) {
          // dispatch(setWait(false));
          dispatch(setEnquiryId(response?.data?.user_journey_id));
        } else {
          const { errors, data } = response?.data;
          let error = errors && getFirstError(errors);
          error = error ? error : data?.message;
          dispatch(errorStatus(error));
        }
      } else {
        const { errors, data } = response?.data;
        let error = errors && getFirstError(errors);
        error = error ? error : data?.message;
        dispatch(errorStatus(error));
      }
    } catch (err) {
      dispatch(errorStatus("Server Error. Please try again"));
    }
  };
};

export const trackProgress = (data) => {
  return async (dispatch) => {
    try {
      const trackingresponse = await tracking(data);
      if (trackingresponse?.data) {
        dispatch(trackLog(trackingresponse));
      } else {
        console.error("Tracking failed");
      }
    } catch (err) {
      console.error("Tracking failed");
    }
  };
};

export const talkToExpert = (data) => {
  return async () => {
    try {
      const response = await callMe(data);
      return response;
    } catch (err) {
      console.error("Talk to Expert Failed");
    }
  };
};

export const mailToExpert = (data) => {
  return async () => {
    try {
      const response = await mailMe(data);
      return response;
    } catch (err) {
      console.error("Talk to Expert Failed");
    }
  };
};

export const setToken = (data) => {
  return async (dispatch) => {
    try {
      const response = await getToken(data);
      const values = response.data?.data[0];
      if (values) {
        const user = {
          firstname: values.firstname,
          lastname: values.lastname,
          mobile_no: String(values.mobile_no),
          email: values.email,
        };
        // const token = values.api_token;
        const enquiry_id = values.user_journey_id;
        dispatch(load_user(user));
        dispatch(setPhone(user.mobile_no));
        dispatch(setEmail(user.email));
        dispatch(setEnquiryId(enquiry_id));
        // dispatch(setWait(false));
        // dispatch(setIsAuthorized(true));
      }
    } catch (err) {
      console.error("Failed to get Token");
    }
  };
};

export const discountPresent = (payload, loginData) => {
  return async (dispatch, getState) => {
    try {
      const {
        discountByEmail: discountByEmail1,
        discountByMobile: discountByMobile1,
      } = getState().greeting;
      if (!discountByMobile1 && !discountByEmail1) {
        const { data, message } = await loadDiscount(payload);
        console.log(data, message);
        if (data.isPnbDomain && message) {
          payload.email
            ? dispatch(discountByEmail(true))
            : dispatch(discountByMobile(true));
          dispatch(discount(data));
          dispatch(
            footerCompany(
              data?.company && data?.email_activation === "N"
                ? data.company
                : ""
            )
          );

          setTimeout(() => {
            !payload.email && dispatch(handleCreateUser(loginData));
          }, [5000]);

          return { data, message };
        } else {
          console.error("Tracking failed");
          dispatch(footerCompany(""));
          !payload.email && dispatch(handleCreateUser(loginData));
        }
      }
    } catch (err) {
      console.error("Tracking failed");
      !payload.email && dispatch(handleCreateUser(loginData));
    }
  };
};

export const ResendEmail = (payload) => {
  return async (dispatch) => {
    try {
      const { data, message, error } = await loadDiscount(payload);
      if (data.status) {
        dispatch(resendEmail(true));
      } else {
        console.error(message || error);
        // dispatch(resendEmail(true));
      }
      return data;
    } catch (err) {
      console.error(err);
    }
  };
};

export default greeting.reducer;
