import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers";
import { useDispatch, useSelector } from "react-redux";
import { Form, Col, Row } from "react-bootstrap";
import { ConfirmButton, Loader } from "../../components";
import FooterLanding from "../../components/FooterLanding/FooterLanding";
import { useForm } from "react-hook-form";
import { greetingSchema } from "./formValidation";
import styled from "styled-components";
import Whatsapp from "../../assets/images/whatsapp.svg";
import CheckImage from "../../assets/images/check-animation.gif"
import {
  handleCreateUser,
  errorStatus,
  discountPresent,
  discount,
  discountByMobile,
  discountByEmail,
  footerCompany,
  setEnquiryId,
  setisPnbDomain,
} from "./landing.slice";
import { setPhone } from "../plans/plans.slice";
import swal from "sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import "./landingPage.scss";
import { numOnly, noNum } from "./utils";
import {
  FormGroup,
  FormWrapper,
  Label,
  TextInput,
} from "../../components/Form/Form";
import { TopMobile, BottomMobile } from "./Advertisement";
import { lowerCase } from "../../utils";
// import Whatsapp from "../../assets/images/whatsapp.svg";
import VodafoneGreet from "./VodafoneGreet";
import Particle from "./particle";
import { EmailModal } from "./email-modal";
import _ from "lodash";
import { getUser } from "./serviceApi";
import Popup from "../../components/Popup/Popup";

const Landing = () => {
  //hooks,selectors
  const dispatch = useDispatch();
  const location = useLocation();
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
const [closeBtn, SetCloseBtn] = useState(false);
  const handleCloseThankYouModal = () => {
    setShowThankYouModal(false);
    resetForm();
  };
  const { register, handleSubmit, errors, watch, setValue, reset } = useForm({
    resolver: yupResolver(greetingSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const {
    response,
    enquiry_id,
    error,
    loading,
    searchQuery,
    discount: discountResponse,
    isPnbDomain,
  } = useSelector((state) => state.greeting);
  console.log("isPnbDomain", isPnbDomain)
  const { user } = useSelector((state) => state.plans);
  const query = new URLSearchParams(location.search);
  const [whatsapp, setWhatsapp] = useState(true);
  const Lead_Source = query.get("utm_source") || "Direct Traffic";
  const Lead_Medium = query.get("utm_medium");
  const Campaign_Name = query.get("utm_campaign");
  const token = query.get("token");
  const traceId = query.get("enquiry_id");
  const returnEmail = query.get("email_id");
  useEffect(() => {
    if (traceId)
      getUser({
        enquiry_id: traceId,
      })
        .then(({ data }) => setValue("email", data.work_email))
        .catch((err) => console.err(err));
  }, []);
  const history = useHistory();
  let phone = "";
  const attachRef = (ref) => {
    register(ref);
    phone = ref;
  };
  const handlePhone = () => {
    dispatch(discountByMobile(false));
    dispatch(setPhone(phone.value));
  };

  const [alert, setAlert] = useState(null);
  const [modal, setModal] = useState(false);

  //alerts for api response --------------------
  useEffect(() => {
    if (response?.data?.status && enquiry_id && alert) {
      SetCloseBtn(true)
      // setShowThankYouModal(true)
      // setShowLoader(false)
      // setTimeout(() => {
      // history.push(
      //   `/plans?enquiry_id=${enquiry_id}${searchQuery ? "&" + searchQuery : ""}`
      // );
      // }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, alert, enquiry_id]);

  useEffect(() => {
    if (discountResponse?.email_activation === "Y") {
      setModal(discountResponse.time);
    }
  }, [discountResponse]);

  //-------------------------
  Particle();
  //Alert if no response-----
  useEffect(() => {
    dispatch(errorStatus());

    return () => {
      dispatch(discount({}));
      dispatch(footerCompany(""));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const time = new Date();
  time.setSeconds(time.getSeconds() + modal);
  useEffect(() => {
    if (error) {
      swal(error, "", "error");
    }
    return () => {
      dispatch(errorStatus());
    };
  }, [error, dispatch]);

  const emailWatch = watch("email");
  useEffect(() => {
    setValue("email", returnEmail);
  }, [returnEmail]);

  useEffect(() => {
    if (emailWatch) {
      dispatch(discountByEmail(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailWatch]);

  const afterSubmit = (data, message = {}) => {
    setShowLoader(true);
    _.isEmpty(message)
      ? dispatch(
          discountPresent(
            { mobile_no: data.mobile_no },
            {
              ...data,
              Lead_Source,
              Lead_Medium,
              Campaign_Name,
              token,
              enquiry_id: traceId,
            }
          )
        )
      : dispatch(
          handleCreateUser({
            ...data,
            Lead_Source,
            Lead_Medium,
            Campaign_Name,
            token,
            enquiry_id: traceId,
          })
        );
    setAlert(1);
    setShowLoader(false);
  };
  //form on onSubmit
  const onSubmit = (data) => {
   
    dispatch(discountByEmail(false));
    dispatch(discountByMobile(false));
    dispatch(
      discountPresent({ email: data.email, r_url: window.location.href })
    ).then((emailData) => {
      if (!emailData) {
        afterSubmit({ ...data, is_sms_opt: true, is_privacy_policy: true });
      }
      console.log(emailData);
      if (
        emailData?.data?.isPnbDomain &&
        emailData?.data?.email_activation === "Y"
      )
        return;
      if (
        emailData?.data?.isPnbDomain &&
        emailData.data.email_activation === "N"
      ) {
        setTimeout(() => {
          afterSubmit(data, emailData.message);
        }, emailData.data.duration * 1000);
      }
      if (
        emailData?.data?.isPnbDomain ){
         dispatch(setisPnbDomain(true))
        }else{
          dispatch(setisPnbDomain(false))
        }
    });
    // setShowLoader(false);
  };
 
  useEffect(() => {
    dispatch(setEnquiryId(null));
  }, []);
  //------------------------
  const resetForm = () => {
    reset({
      values: {
        firstname: '',
        lastname: '',
        email:"",
        mobile_no:"",
        
      },
      errors: {},
      isDirty: false,
    });
  };
  const content1 = (<>
<div className={"popupThankyou"} style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding:" 26px 23px 36px", }}>
<img src={`${CheckImage}?${Math.random()}`} alt="Checked"  style={{width: "40px", height: "40px" }} />
<h4 style={{ fontFamily: "Inter-SemiBold" , color:"#202020", paddingTop: "8px",
    paddingBottom: "8px"}}>Thank You</h4>
<p style={{color: "#202020", fontSize:"14px"}}>Our Customer Sales Advisor will get in touch shortly.</p>
<button style={{cursor: "pointer", fontFamily: "Inter-SemiBold",
    fontSize: "14px",
    lineHeight: "18px",
    padding: "12px 18px",
    borderRadius: "4px", 
    border: "solid 1px #197690",
    color: "#197690",
    background: "#fff",
    marginTop: "20px"}} onClick={handleCloseThankYouModal} disabled={!closeBtn}>Close</button>
</div>
  
  </>)
  //  return !loading ? (
  return  (
    <div>
  <Popup
    forLandingPage={true}
    height={"222px"}
    width="522px"
    show={showThankYouModal}
    onClose={handleCloseThankYouModal}
    // content={ "hello"}
    content={content1}
    position="middle"
  />
      <Container>
        <FormContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              style={{ marginLeft: "0", marginRight: "0" }}
            >
              <Col className="landing-laxmi">
                <div
                  className="review-details3 w-100"
                  style={{ textAlign: "center" }}
                >
                  <Avatar src="/assets/image/laxmiImg.png" alt="avatarImage" />
                </div>
              </Col>
              <Col>
                <p className="heading__main">Hi! I'm Laxmi.</p>
              </Col>
              <Col>
                <p className="heading__secondary">
                  <span>
                    I’ll get you the right cyber insurance plan at best price in
                    just one click.
                    <br />
                    Let’s start with your basic details.
                  </span>
                </p>
              </Col>
            </Row>

            <TopMobile />

            <FormWrapper
              justifyContent={"space-between"}
              style={{ paddingTop: "18px" }}
            >
              <FormGroup md>
                <TextInput
                  lg
                  capital
                  name="firstname"
                  placeholder=" "
                  autoComplete="none"
                  id="firstname"
                  type="text"
                  ref={register}
                  defaultValue={user?.firstname}
                  onInput={(e) => {
                    lowerCase(e);
                    noNum(e);
                  }}
                  error={errors?.firstname && "danger"}
                  onkeypress="return (event.charCode > 64 && 
                    event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
                ></TextInput>
                <Label lg htmlFor="firstname">
                  First Name
                </Label>
                {errors.firstname && (
                  <p className="errorG pt-1 pb-4">{errors.firstname.message}</p>
                )}
              </FormGroup>
              <FormGroup md>
                <TextInput
                  lg
                  capital
                  name="lastname"
                  placeholder=" "
                  autoComplete="none"
                  id="lastname"
                  ref={register}
                  defaultValue={user?.lastname}
                  onInput={(e) => {
                    lowerCase(e);
                    noNum(e);
                  }}
                  error={errors?.lastname && "danger"}
                ></TextInput>
                <Label lg htmlFor="lastname">
                  Last Name
                </Label>
                {errors.lastname && (
                  <p className="errorG pt-1 pb-4">{errors?.lastname.message}</p>
                )}
              </FormGroup>
              <FormGroup md>
                <TextInput
                  lg
                  name="email"
                  placeholder=" "
                  autoComplete="none"
                  id="email"
                  type="email"
                  onInput={lowerCase}
                  defaultValue={user?.email}
                  // pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{1,})$"
                  ref={register}
                  error={errors?.email && "danger"}
                ></TextInput>
                <Label lg htmlFor="email">
                  Personal Email ID
                </Label>
                <p style={{ fontSize: "10px", marginBottom: "0" }}>
                  Your quote, policy and all communications will be sent on it.
                </p>
                {errors.email && (
                  <p className="errorG pt-1 pb-4">{errors?.email.message}</p>
                )}
              </FormGroup>
              <FormGroup md>
                <TextInput
                  lg
                  type="tel"
                  name="mobile_no"
                  placeholder=" "
                  autoComplete="none"
                  id="mobile_no"
                  onChange={handlePhone}
                  ref={attachRef}
                  defaultValue={user?.mobile_no}
                  onKeyPress={numOnly}
                  error={errors?.mobile_no && "danger"}
                  maxLength="10"
                  chars="0-9"
                ></TextInput>
                <Label lg htmlFor="mobile_no">
                  Mobile No.
                </Label>
                <p style={{ fontSize: "10px", marginBottom: "0" }}>
                  Mobile No. will be your Customer Login ID
                </p>
                {errors.mobile_no && (
                  <p className="errorG pt-1 pb-4">
                    {errors?.mobile_no.message}
                  </p>
                )}
              </FormGroup>
            </FormWrapper>
            <Row
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              style={{ marginLeft: "0", marginRight: "0" }}
            >
              <ButtonContainer>
                <ConfirmButton
                // onClick={() => setShowThankYouModal(true)}
                onClick={() => {
                  // setShowLoader(true)
                  SetCloseBtn(false);
                  (watch("firstname") !== "" && 
                  watch("lastname") !== "" && 
                  watch("email") !== ""&&
                  watch("mobile_no") !== "" && 
                  setShowThankYouModal(true));
                  setShowLoader(false);
                }}
                  width="320px"
                  style={{
                    fontSize: "17px",
                    fontWeight: "570",
                    padding: "0 30px",
                    color: "white",
                    borderRadius: "5px",
                  }}
                >
                  View Quotes
                  <i
                    className="space moving-left fa fa-long-arrow-right "
                    aria-hidden="true"
                    style={{ paddingLeft: "10px", fontSize: "1.3rem" }}
                  ></i>
                </ConfirmButton>
              </ButtonContainer>
              <div
                style={{
                  textAlign: "center",
                  paddingTop: "7px",
                  fontSize: "13px",
                  color: "GrayText",
                }}
              >
                {/* By clicking I agree to all{" "} */}
                Your information stays between us. We never, ever sell your info
                to third parties - we hate spam too.
                <div>
                  By clicking on “View Quotes”, you agree to our&nbsp;
                  <Term
                    target="_blank"
                    href="http://www.elephant.in/privacy_policy"
                  >
                    {"Privacy Policy"}
                  </Term>
                  &nbsp;and&nbsp;
                  <Term
                    target="_blank"
                    href="http://www.elephant.in/terms_condition"
                  >
                    Terms & Conditions
                  </Term>
                </div>
              </div>
            </Row>
            <SocialUpdateWrap>
              <img src={Whatsapp} alt="whatsapp" />
              <WhatsappTxt>Get updates on WhatsApp</WhatsappTxt>
              <Checkbox
                checked={whatsapp}
                onChange={() => {
                  setWhatsapp(!whatsapp);
                }}
                noMargin
              />
            </SocialUpdateWrap>
            <BottomMobile />
          </Form>
        </FormContainer>
      </Container>
      <FooterLanding></FooterLanding>
      {modal && (
        <EmailModal
          emailWatch={emailWatch}
          show={modal}
          onHide={() => {
            setModal(false);
            dispatch(discount({}));
          }}
          expiryTimestamp={time}
        />
      )}

      <VodafoneGreet />
      
      {console.log("SAHIL111", showLoader)}
      {/* {showLoader && <Loader />} */}
   
  {/* {isPnbDomain ? (!loading && showLoader&& !isPnbDomain) && <Loader /> : isPnbDomain && "" ?  showLoader && <Loader /> :""} */}

{(!loading && showLoader && !isPnbDomain)? <Loader /> : isPnbDomain && "" }
{showLoader&& !isPnbDomain && <Loader /> }
    </div>
  )
};
// ) : (
//   <Loader />
// );
const Checkbox = ({ checked, onChange }) => {
  return (
    <>
      <UpgradeCheckBox>
        <div class="custom-checkbox">
          <input
            class="custom-checkbox-input"
            type="checkbox"
            id="whatsapp-Check"
            checked={checked}
            onChange={onChange}
          />
          <label class="custom-checkbox-label" for="whatsapp-Check">
            <label
              class="custom-checkbox-label-aux"
              for="whatsapp-Check"
            ></label>
          </label>
        </div>
      </UpgradeCheckBox>
    </>
  );
};

//styles
const Container = styled.div`
  margin-top: 20px; /*remove this later*/
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  margin: 0 0 160px;
  width: 650px;
  @media (max-width: 767px) {
    width: 100%;
  }
  @media (max-width: 450px) {
    margin-bottom: 200px;
  }
`;

const Avatar = styled.img`
  width: 92px;
  border: solid 1px #d2d3d4;
  border-radius: 50%;
  /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2); */
  @media (max-width: 767px) {
    width: 72px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
  justify-content: center;
  & > button {
    width: 320px !important;
    background-color: #f2581b !important;
    &:hover {
      background-color: #ff4d07 !important;
    }
  }
  & > button > i {
    transition: transform 0.1s;
  }
  & > button:hover,
  & > button:active {
    & > i {
      transform: translateX(10px);
    }
  }
  @media (max-width: 768px) {
    & > button {
      width: 95% !important;
    }
  }
`;

const Term = styled.a`
  &:link,
  &:visited {
    color: #107591;
    text-decoration: underline;
  }
  color: #107591;
`;

export const SocialUpdateWrap = styled.div`
  display: table;
  width: 201px;
  text-align: center;
  margin: 14px auto;
  & img {
    display: table-cell;
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;
export const WhatsappTxt = styled.div`
  display: table-cell;
  font-family: "Inter-Regular";
  font-size: 11px;
  line-height: 1;
  color: #333;
  text-align: left;
  vertical-align: middle;
`;

const UpgradeCheckBox = styled.div`
  & {
    display: table-cell;
    margin-left: 12px;
  }
  & .custom-checkbox {
    float: left;
    width: 30px;
    height: 16px;
    overflow: hidden;
    border-radius: 20px;
    margin: 0;
  }
  & .custom-checkbox-input {
    display: none;
  }
  & .custom-checkbox-label {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    cursor: pointer;
    background-color: #dddddd;
    transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    margin: 0;
  }
  & .custom-checkbox-label-aux {
    top: 2px;
    left: 3px;
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    cursor: pointer;
    border-radius: 100%;
    transition: left 0.3s;
    -o-transition: left 0.3s;
    background-color: white;
    -moz-transition: left 0.3s;
    -webkit-transition: left 0.3s;
    box-shadow: 0px 1px 5px 0px rgb(56 55 56 / 61%);
    -moz-box-shadow: 0px 1px 5px 0px rgba(56, 55, 56, 0.61);
    -webkit-box-shadow: 0px 1px 5px 0px rgb(56 55 56 / 61%);
  }
  & .custom-checkbox-input:checked + .custom-checkbox-label {
    background-color: #107591;
    border: none;
  }
  &
    .custom-checkbox-input:checked
    + .custom-checkbox-label
    .custom-checkbox-label-aux {
    left: 16px;
  }
`;

export default Landing;
