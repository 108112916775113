import axios from "axios";
import HttpClient from "../../api/httpClient";

const createUser = (data) =>
  HttpClient("/common/create/user", { method: "POST", data });

const tracking = (data) =>
  HttpClient("/common/create/user-journey", { method: "POST", data });

const callMe = (data) =>
  HttpClient("/common/save-contact", { method: "POST", data });

const mailMe = (data) =>
  HttpClient("/common/save-contact", { method: "POST", data });

const getToken = (data) =>
  HttpClient("/common/user/get-token", { method: "GET", queryParams: data });

const loadDiscount = (data) =>
  HttpClient("/common/user/get-company-domain", { method: "POST", data });

const resendEmailApi = (data) =>
  HttpClient("/common/user/resend-email", { method: "POST", data });
const getUser = (data) =>
  HttpClient("/common/user/popuate_email", { method: "POST", data });
const getSourceScript = (data) =>
  axios.get(`https://elephant.in/api/get_soure_script?source_name=${data}`);
export {
  createUser,
  tracking,
  callMe,
  mailMe,
  getToken,
  loadDiscount,
  resendEmailApi,
  getUser,
  getSourceScript,
};
