import { getSourceScript } from "../Landing/serviceApi";

export const calculateSI = (data) => {
  switch (data) {
    case "Rs. 50,000":
      return 50000;
    case "Rs. 1,00,000":
      return 100000;
    case "Rs. 3,00,000":
      return 300000;
    case "Rs. 5,00,000":
      return 500000;
    case "Rs. 10,00,000":
      return 1000000;
    case "Rs. 15,00,000":
      return 1500000;
    case "Rs. 20,00,000":
      return 2000000;
    case "Rs. 25,00,000":
      return 2500000;
    case "Rs. 50,00,000":
      return 5000000;
    case "Rs. 75,00,000":
      return 7500000;
    case "Rs. 1,00,00,000":
      return 10000000;
    default:
      return 100000;
  }
};
export const runScript = async (utm_source) => {
  try {
    const { data } = await getSourceScript(utm_source);
    console.log(data);
    if (data.script_data) {
      const frag = document
        .createRange()
        .createContextualFragment(data.script_data);
      document.body.append(...frag.children);
    }
  } catch (error) {
    console.log(error);
  }
};
export const Data = [
  {
    id: 1,
    company_name: "HDFC ERGO 1",
    company_logo: "assets/image/hdfc_ergo_logo.png",
    pdf_link: "https://www.youtube.com/",
    company_tagline: "e@Secure Insurance",
    amount: "50,583",
    gst_amount: "8,352",
    total_amount: "30,935",
    sum_assured: "40,00,000",
    available_features: [
      { flag: true, name: "Legal Protectionss" },
      { flag: true, name: "Identity Theft Cover" },
      { flag: true, name: "Social Media Cover" },
      { flag: false, name: "Cyber Stalking" },
      { flag: true, name: "IT Theft Loss Cover" },
      { flag: true, name: "Malware Cover" },
      { flag: false, name: "Phishing Cover" },
      { flag: false, name: "E-mail Spoofing Cover" },
      { flag: true, name: "Media Liability Claims" },
      { flag: true, name: "Cyber Extortion Cover" },
      { flag: true, name: "Privacy Breach" },
      { flag: false, name: "Counselling Services" },
      { flag: true, name: "IT Consultant Services" },
    ],
  },
  {
    id: 2,
    company_name: "BAJAJ ALLIANZ",
    company_logo: "assets/image/Bajaj-Logo.jpg",
    pdf_link: "https://www.google.com/",
    company_tagline: "Individual Cyber Safe Insurance",
    amount: "61,583",
    gst_amount: "4,352",
    total_amount: "30,935",
    sum_assured: "40,00,000",
    available_features: [
      { flag: true, name: "Legal Protectionss" },
      { flag: true, name: "Identity Theft Cover" },
      { flag: true, name: "Social Media Cover" },
      { flag: true, name: "Cyber Stalking" },
      { flag: true, name: "IT Theft Loss Cover" },
      { flag: false, name: "Malware Cover" },
      { flag: false, name: "Phishing Cover" },
      { flag: false, name: "E-mail Spoofing Cover" },
      { flag: true, name: "Media Liability Claims" },
      { flag: true, name: "Cyber Extortion Cover" },
      { flag: true, name: "Privacy Breach" },
      { flag: true, name: "Counselling Services" },
      { flag: true, name: "IT Consultant Services" },
    ],
  },
  {
    id: 3,
    company_name: "HDFC ERGO 2",
    company_logo: "assets/image/hdfc_ergo_logo.png",
    pdf_link: "https://www.youtube.com/",
    company_tagline: "e@Secure Insurance",
    amount: "50,583",
    gst_amount: "8,352",
    total_amount: "30,935",
    sum_assured: "40,00,000",
    available_features: [
      { flag: true, name: "Legal Protectionss" },
      { flag: true, name: "Identity Theft Cover" },
      { flag: true, name: "Social Media Cover" },
      { flag: false, name: "Cyber Stalking" },
      { flag: true, name: "IT Theft Loss Cover" },
      { flag: true, name: "Malware Cover" },
      { flag: false, name: "Phishing Cover" },
      { flag: false, name: "E-mail Spoofing Cover" },
      { flag: true, name: "Media Liability Claims" },
      { flag: true, name: "Cyber Extortion Cover" },
      { flag: true, name: "Privacy Breach" },
      { flag: false, name: "Counselling Services" },
      { flag: true, name: "IT Consultant Services" },
    ],
  },
  {
    id: 4,
    company_name: "SBI",
    company_logo: "assets/image/sbi-logo.png",
    pdf_link: "https://www.facebook.com/",
    company_tagline: "SBI Insurance",
    amount: "35,583",
    gst_amount: "4,352",
    total_amount: "15,935",
    sum_assured: "20,00,000",
    available_features: [
      { flag: true, name: "Legal Protectionss" },
      { flag: false, name: "Identity Theft Cover" },
      { flag: true, name: "Social Media Cover" },
      { flag: false, name: "Cyber Stalking" },
      { flag: true, name: "IT Theft Loss Cover" },
      { flag: true, name: "Malware Cover" },
      { flag: false, name: "Phishing Cover" },
      { flag: false, name: "E-mail Spoofing Cover" },
      { flag: true, name: "Media Liability Claims" },
      { flag: false, name: "Cyber Extortion Cover" },
      { flag: true, name: "Privacy Breach" },
      { flag: false, name: "Counselling Services" },
      { flag: true, name: "IT Consultant Services" },
    ],
  },
  {
    id: 5,
    company_name: "Reliance",
    company_logo: "assets/image/reliance-logo.png",
    pdf_link: "https://www.reliance.com/",
    company_tagline: "Reliance Insurance",
    amount: "34,583",
    gst_amount: "5,352",
    total_amount: "16,935",
    sum_assured: "22,00,000",
    available_features: [
      { flag: true, name: "Legal Protectionss" },
      { flag: true, name: "Identity Theft Cover" },
      { flag: true, name: "Social Media Cover" },
      { flag: false, name: "Cyber Stalking" },
      { flag: true, name: "IT Theft Loss Cover" },
      { flag: true, name: "Malware Cover" },
      { flag: false, name: "Phishing Cover" },
      { flag: false, name: "E-mail Spoofing Cover" },
      { flag: true, name: "Media Liability Claims" },
      { flag: false, name: "Cyber Extortion Cover" },
      { flag: true, name: "Privacy Breach" },
      { flag: false, name: "Counselling Services" },
      { flag: true, name: "IT Consultant Services" },
    ],
  },
];
