import React from "react";
import styled from "styled-components";
import { Button } from '../';
import { useHistory, useLocation } from "react-router";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

const Failed = () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const id = query.get("id");
  const { searchQuery } = useSelector((state) => state.greeting);

  const onSubmit = () => {

    history.replace(`/detail-form/${id}?enquiry_id=${enquiry_id}${searchQuery ? '&' + searchQuery : ''}`);
  };

  return (
    <Container>
      <Form>
        <div class="avtar paymentMsg">
          <div class="avtar-img"><img src="/assets/image/laxmiImg.png" alt="fail" /></div>
          <div class="icon"><img src="/assets/image/fail.png" alt="fail" /></div>
          <h2>Your Payment is Unsuccessful!</h2>
          <Button type="button" onClick={onSubmit}>
            <p className="p-0">TRY AGAIN</p>
          </Button>
        </div>
      </Form>
    </Container>
  );
};

const Container = styled.div`
margin:0;
`;

export default Failed;
