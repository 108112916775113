import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./security-details.scss";

function SecurityDetails({ formData, onSubmit }) {
  const [computers, setComputers] = useState(
    formData.computers ? formData.computers : ""
  );
  const [computersError, setComputersError] = useState(false);
  const [network, setNetwork] = useState(Boolean(Number(formData.network)));
  const [antivirus, setAntivirus] = useState(
    Boolean(Number(formData.antivirus))
  );
  const [backup, setBackup] = useState(Boolean(formData.backup));
  const [backupText, setBackupText] = useState(
    formData.backup ? formData.backup.backupText : ""
  );
  const [perils, setPerils] = useState(Boolean(formData.perils));
  const [perilsText, setPerilsText] = useState(
    formData.perils ? formData.perils.perilsText : ""
  );
  const [events, setEvents] = useState(
    formData.perils ? formData.perils.events : []
  );
  const data = { computers, network, antivirus };

  const handleSubmit = () => {
    if (!computers) 
    {
      setComputersError(true);
    } 
    else 
    {
      if (backup) 
      {
        data.backup = {
          value: backup,
          backupText,
        };
      } 
      else data.backup = backup;
      if (perils) 
      {
        data.perils = {
          value: perils,
          events,
          perilsText,
        };
      } 
      else data.perils = perils;
      onSubmit(data);
    }
  };

  const handleChange = (evt) => {
    if (evt.target.checked) setEvents([...events, evt.target.value]);
    else setEvents(events.filter((value) => evt.target.value !== value));
  };

  const handleKeyPress = (evt) => {
    const { value } = evt.target;
    if (value.length === 3 || evt.key === "-" || evt.key === "+")
      evt.preventDefault();
  };

  return (
    <div className="security-details">
      <Form.Group style={{ paddingTop: "18px" }} className="custom-form-group">
        <Form.Label style={{ fontSize: "14px" }}>
          How many computer systems including digital devices do you own, lease
          or operate?
        </Form.Label>
        <Form.Control
          type="number"
          autoComplete={false}
          min={0}
          onKeyPress={handleKeyPress}
          style={{ maxWidth: "182px" }}
          onChange={(evt) => {
            setComputers(evt.target.value);
            setComputersError(false);
          }}
          defaultValue={formData.computers}
        />
        {computersError && (
          <p className="error">No. of digital devices is required</p>
        )}
      </Form.Group>
      <Form.Group className="custom-form-group">
        <Form.Label style={{ fontSize: "14px" }}>
          Do you actively implement the best network security practices
          recommended by your service provider such as strong passwords, two
          factor authentications, regularly changing your passwords?
        </Form.Label>
        <div>
          <CustomRadio
            name="network"
            label="Yes"
            id="network-yes"
            checked={network}
            onClick={() => setNetwork(true)}
          />
          <CustomRadio
            name="network"
            label="No"
            id="network-no"
            checked={!network}
            onClick={() => setNetwork(false)}
          />
        </div>
      </Form.Group>

      <Form.Group className="custom-form-group">
        <Form.Label style={{ fontSize: "14px" }}>
          Is the antivirus software updated as per the recommendations of
          antivirus software service provider?
        </Form.Label>
        <div>
          <CustomRadio
            name="antivirus"
            label="Yes"
            id="antivirus-yes"
            checked={antivirus}
            onClick={() => setAntivirus(true)}
          />
          <CustomRadio
            name="antivirus"
            label="No"
            id="antivirus-no"
            checked={!antivirus}
            onClick={() => setAntivirus(false)}
          />
        </div>
      </Form.Group>

      <Form.Group className="custom-form-group">
        <Form.Label style={{ fontSize: "14px" }}>
          Do you maintain regular backup of data on either cloud/external device
          for your Computer System including all digital devices?
        </Form.Label>
        <div>
          <CustomRadio
            name="backup"
            label="Yes"
            id="backup-yes"
            checked={backup}
            onClick={() => setBackup(true)}
          />
          <CustomRadio
            name="backup"
            label="No"
            id="backup-no"
            checked={!backup}
            onClick={() => setBackup(false)}
          />
        </div>
        {backup && (
          <>
            <Form.Label style={{ fontSize: "14px", marginBottom: "0" }}>
              If Yes, Please provide details
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="2"
              cols="50"
              className="backupTextArea"
              value={backupText}
              onChange={(evt) => setBackupText(evt.target.value)}
            />
          </>
        )}
      </Form.Group>
      <Form.Group className="custom-form-group">
        <Form.Label style={{ fontSize: "14px" }}>
          Have you been a victim of any of the perils covered under the policy
          in the past?
        </Form.Label>
        <div>
          <CustomRadio
            name="perils"
            label="Yes"
            id="perils-yes"
            checked={perils}
            onClick={() => setPerils(true)}
          />
          <CustomRadio
            name="perils"
            label="No"
            id="perils-no"
            checked={!perils}
            onClick={() => setPerils(false)}
          />
        </div>
        {perils && (
          <>
            <Form.Group>
              <Form.Label style={{ fontSize: "14px", marginBottom: "0" }}>
                If Yes, let us know the events that you were a victim of
              </Form.Label>
              <div class="perilsWrap">
                <div class="perilsChk">
                  <input
                    type="checkbox"
                    id="itTheft"
                    name="itTheft"
                    value="1"
                    checked={events.includes("1")}
                    onChange={handleChange}
                  />
                  <label for="itTheft">IT Theft</label>
                </div>
                <div class="perilsChk">
                  <input
                    type="checkbox"
                    id="malwareAttack"
                    name="malwareAttack"
                    value="2"
                    checked={events.includes("2")}
                    onChange={handleChange}
                  />
                  <label for="malwareAttack">Malware attack</label>
                </div>
                <div class="perilsChk">
                  <input
                    type="checkbox"
                    id="phishingSpoofing"
                    name="phishingSpoofing"
                    value="3"
                    checked={events.includes("3")}
                    onChange={handleChange}
                  />
                  <label for="phishingSpoofing">
                    Phishing or E-Mail Spoofing
                  </label>
                </div>
                <div class="perilsChk">
                  <input
                    type="checkbox"
                    id="mediaWrongfulAct"
                    name="mediaWrongfulAct"
                    value="4"
                    checked={events.includes("4")}
                    onChange={handleChange}
                  />
                  <label for="mediaWrongfulAct">Media Wrongful Act</label>
                </div>
                <div class="perilsChk">
                  <input
                    type="checkbox"
                    id="breachorPrivacyBreach"
                    name="breachorPrivacyBreach"
                    value="5"
                    checked={events.includes("5")}
                    onChange={handleChange}
                  />
                  <label for="breachorPrivacyBreach">
                    Data Breach or Privacy Breach
                  </label>
                </div>
              </div>
            </Form.Group>
            <Form.Label style={{ fontSize: "14px", marginBottom: "0" }}>
              Please provide details of the event
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="2"
              cols="50"
              className="backupTextArea"
              value={perilsText}
              onChange={(evt) => setPerilsText(evt.target.value)}
            />
          </>
        )}
      </Form.Group>
      <Form.Group style={{ textAlign: "center" }}>
        <input
          class="btn custom-btn1"
          type="submit"
          value="Proceed to Address & Policy Details"
          onClick={handleSubmit}
        />
      </Form.Group>
    </div>
  );
}

function CustomRadio({ name, label, id, checked, onClick }) {
  return (
    <div className="custom-radio" onClick={onClick}>
      <input type="radio" name={name} id={id} checked={checked} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

export default SecurityDetails;
