import React from "react";
import { Row } from "react-bootstrap";
import "./security-details.scss";

function SecurityDetailsSummary(props) {
  return (
    <div className="security-details-summary">
      <Row>
        <div class="form-group">
          <div>
            <label style={{ fontSize: "14px", paddingRight: "60px" }}>
              How many computer systems including digital devices do you own,
              lease or operate?
            </label>
          </div>
          <div style={{ marginTop: "-11px", fontSize: "13px" }}>
            {props.formData.computers}
          </div>
        </div>
      </Row>
      <Row>
        <div class="form-group">
          <div>
            <label style={{ fontSize: "14px", paddingRight: "60px" }}>
              Do you actively implement the best network security practices
              recommended by your service provider such as strong passwords, two
              factor authentications, regularly changing your passwords?
            </label>
          </div>
          <div style={{ marginTop: "-11px", fontSize: "13px" }}>
            {Boolean(Number(props.formData.network)) ? "Yes" : "No"}
          </div>
        </div>
      </Row>
      <Row>
        <div class="form-group">
          <div>
            <label style={{ fontSize: "14px", paddingRight: "60px" }}>
              Is the antivirus software updated as per the recommendations of
              antivirus software service provider?
            </label>
          </div>
          <div style={{ marginTop: "-11px", fontSize: "13px" }}>
            {Boolean(Number(props.formData.antivirus)) ? "Yes" : "No"}
          </div>
        </div>
      </Row>
      <Row>
        <div class="form-group">
          <div>
            <label style={{ fontSize: "14px", paddingRight: "60px" }}>
              Do you maintain regular backup of data on either cloud/external
              device for your Computer System including all digital devices?
            </label>
          </div>
          <div style={{ marginTop: "-11px", fontSize: "13px" }}>
            {Boolean(props.formData.backup) ? "Yes" : "No"}
          </div>
        </div>
      </Row>
      <Row>
        <div class="form-group">
          <div>
            <label style={{ fontSize: "14px", paddingRight: "60px" }}>
              Have you been a victim of any of the perils covered under the
              policy in the past?
            </label>
          </div>
          <div style={{ marginTop: "-11px", fontSize: "13px" }}>
            {Boolean(props.formData.perils) ? "Yes" : "No"}
          </div>
        </div>
      </Row>
    </div>
  );
}

export default SecurityDetailsSummary;
