import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IconlessCard } from "../../components";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { updateProgress } from "./details.slice";
import "./style.scss";
import { useLocation } from "react-router";

const InfoCard = (props) => {
  const Data1 = props?.Data1;
  // const List = props.list;
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const Lead_Source = query.get("utm_source");
  const Lead_Medium = query.get("utm_medium");
  const Campaign_Name = query.get("utm_campaign");

  //tracking------------------------------
  useEffect(() => {
    if (Data1?.sum_insured) {
      const user_journey_id = enquiry_id;
      dispatch(
        updateProgress({
          has_seen_plan_details: 1,
          sum_insured: [Data1?.sum_insured],
          has_seen_proposal: 1,
          quote_rate_id: props.id,
          user_journey_id,
          Lead_Source,
          Lead_Medium,
          Campaign_Name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data1, dispatch]);
  //--------------------------------------

  return (
    <IconlessCard removeBottomHeader={true}>
      <div style={{ marginTop: "-40px", padding: "15px" }}>
        <Row>
          <HeaderContainer>
            <div className='p-1'>
              <img
                src={Data1?.ic_logo}
                alt='imgLogo'
                style={{ height: "48px" }}
                width='auto'
                height='48px'
              />
            </div>
            <LabelContainer className='p-1'>
              <LabelTag fontSize={"12px"}>{Data1?.ic_name || "N/A"}</LabelTag>
              <LabelTagSmall>{Data1?.ic_tagline || "N/A"}</LabelTagSmall>
            </LabelContainer>
          </HeaderContainer>
        </Row>
        <Row>
          <LabelContainer className='px-1'>
            <label style={{ fontSize: "11px", color: "gray", margin: "0" }}>
              Sum Insured
            </label>
            <label
              style={{ fontSize: "14.5px", fontWeight: "700", margin: "0" }}
            >{`₹ ${
              Data1?.sum_insured
                ? Number(Data1.sum_insured).toLocaleString("en-In")
                : "N/A"
            }`}</label>
            <label
              style={{
                fontSize: "14.5px",
                fontWeight: "700",
                marginTop: "15px",
                padding: "0px",
              }}
            >
              Premium
            </label>
          </LabelContainer>
        </Row>
        <Info>
          <RowDiv>
            <Col className='p-0'>
              <div>
                <label style={{ fontSize: "12px" }}>Plan Premium</label>
              </div>
            </Col>
            <Col className='p-0'>
              <div style={{ float: "right" }}>
                <label style={{ fontSize: "12px" }}>
                  {`₹ ${
                    Data1?.final_premium
                      ? Number(Data1.final_premium).toLocaleString("en-In")
                      : "N/A"
                  }`}
                </label>
              </div>
            </Col>
          </RowDiv>
          <RowDiv>
            <Col className='p-0'>
              <div>
                <label style={{ fontSize: "12px" }}>GST</label>
              </div>
            </Col>
            <Col className='p-0'>
              <div style={{ float: "right" }}>
                <label style={{ fontSize: "12px" }}>{`₹ ${
                  Number(Data1?.tax).toLocaleString("en-In") || "N/A"
                }`}</label>
              </div>
            </Col>
          </RowDiv>
        </Info>
        <LabelPremiumRow className='py-3'>
          <div style={{ display: "flex", width: "100%" }} className='px-1'>
            <LabelPremium>
              <LabelPremiumTag>Total Premium</LabelPremiumTag>
              <label
                className='total-premium'
                style={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  margin: "0",
                  paddingLeft: "5px",
                }}
              >{`₹ ${
                Number(
                  Number(Data1?.final_premium) + Number(Data1?.tax)
                ).toLocaleString("en-In") || "N/A"
              }`}</label>
            </LabelPremium>
          </div>
        </LabelPremiumRow>
        <div className='box-content'>
          <div className='fullwidth card-add-on' style={{ overflow: "auto" }}>
            <ul>
              {Data1?.plan_features?.map((list, index) => (
                <li
                  style={{ color: "black" }}
                  className={
                    list.id !== 19 || Data1?.ic_id === 2 ? "" : "disabled"
                  }
                  key={`list${index}`}
                >
                  {list.feature_name}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='text-center'>
          <a
            href={Data1?.policy_wording || "#!"}
            rel='noopener noreferrer'
            target='_blank'
            download
            className='brochure'
            style={{ marginTop: "8px" }}
          >
            <img
              src={require("../../assets/images/pdf.png")}
              alt='BrocureImage'
            />
            <span>Download Policy Wording</span>
          </a>
        </div>
      </div>
    </IconlessCard>
  );
};

const HeaderContainer = styled.div`
  display: flex;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LabelTagSmall = styled.label`
  font-size: 9px;
  opacity: 0.5;
  font-weight: 700;
  margin: 0;
`;

const LabelTag = styled.label`
  font-size: 12px;
  font-weight: 700;
  margin: 0;
`;

const Info = styled(Col)`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
  margin-left: -10px;
  & .row {
    margin-left: 0;
    margin-right: 0;
  }
`;

const RowDiv = styled(Row)`
  display: flex;
  flex-wrap: wrap;
`;

const LabelPremiumRow = styled(Row)`
  display: flex;
  justify-content: center;
`;

const LabelPremium = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 95%;
  border: 1px dashed #f8cf39;
`;

const LabelPremiumTag = styled.label`
  font-size: 12px;
  padding-left: 5px;
  text-align: left;
  margin: 0;
  @media (max-width: 980px) {
    font-size: 11px;
  }
`;

export default InfoCard;
