import { useSelector, useDispatch } from "react-redux";
import React, { Fragment, useState, useEffect } from "react";
import Popup from "./Popup";
import styled from "styled-components";
import { TextInput, Label, FormGroup, FormWrapper } from "../Form/Form";
import { useForm } from "react-hook-form";
import { callSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers";
import { talkToExpert } from "../../modules/Landing/landing.slice";
import { useLocation } from "react-router";

const CallMe = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(true);
  const { phone, user } = useSelector((state) => state.plans);
  const [phoneRef, setPhoneRef] = useState();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(callSchema),
  });
  const [length, setLength] = useState(0);
  const [msg, setMsg] = useState(false);
  const numOnly = (event) => {
    if (!(event.which > 47 && event.which < 58) || event.which === 13)
      event.preventDefault();
  };
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");

  const attachRef = (ref) => {
    register(ref);
    setPhoneRef(ref);
    if (phoneRef && phone && flag) {
      phoneRef.value = phone;
      setFlag(false);
      getLength();
    }
  };

  const getLength = () => {
    setLength(phoneRef.value.length);
  };

  const handleChange = () => {
    setFlag(false);
    getLength();
  };

  useEffect(() => {
    if (flag === false) {
      setFlag(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmit = (data) => {
    dispatch(
      talkToExpert({
        contact_no: data.phone,
        tracking_id: enquiry_id,
        product_name: "Cyber",
        user_journey_id: enquiry_id,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
      })
    );
    setMsg(true);
    setTimeout(() => {
      onClose(false);
      setMsg(false);
    }, 2000);
  };
  const content2 = (
    <MessageContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#4fcc6e"
        width="48px"
        height="48px"
      >
        <path d="M0 0h24v24H0z" fill="none"></path>
        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
      </svg>
      <CallText>
        One of our experts will get back to you within few minutes
      </CallText>
    </MessageContainer>
  );
  const content1 = (
    <Fragment>
      <Content1>
        <div className="contact__imgbox">
          <ContactImg
            src="/assets/image/laxmiImg.png"
            alt="Lakshmi"
            style={{ border: "solid 1px #d2d3d4", borderRadius: "50%" }}
          ></ContactImg>
        </div>
        <ContactText>
          <p style={{ fontSize: "15px" }}>
            Hi, please confirm your mobile number and we will get back to you
            shortly.
          </p>
        </ContactText>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormWrapper>
            <FormGroup lg>
              <TextInput
                lg
                type="tel"
                id="phone"
                name="phone"
                placeholder=" "
                ref={attachRef}
                onChange={handleChange}
                error={errors?.phone && "danger"}
                maxLength="10"
                onKeyPress={numOnly}
              />
              <Label lg htmlFor="phone">
                Mobile No.
              </Label>
              {errors.phone && <p className="errorG">{errors.phone.message}</p>}
            </FormGroup>
            <FormGroup sm style={{ width: "26%" }}>
              <Button active={errors.phone || length < 10 ? true : false}>
                Call Me
              </Button>
            </FormGroup>
          </FormWrapper>
        </form>
      </Content1>
      <Content2>
        <p>In a hurry? Call us on our toll free no. 1800 266 9693</p>
      </Content2>
    </Fragment>
  );

  return (
    <Fragment>
      <Popup
        height={msg ? "200px" : "340px"}
        width="640px"
        show={show}
        onClose={onClose}
        content={msg ? content2 : content1}
        position="middle"
      ></Popup>
    </Fragment>
  );
};

export default CallMe;

const CallText = styled.div`
  width: 340px;
  margin: 7px auto 37px;
  text-align: center;
  font-family: "basier_squareregular";
  font-size: 16px;
  color: #000000;
`;

const MessageContainer = styled.div`
  padding: 10px;
  & svg {
    margin: 0 auto;
    width: 100%;
  }
`;

const Content1 = styled.div`
  height: 90%;
  padding: 60px 100px;
`;

const Content2 = styled.div`
  & p {
    //color: #000000;
    //font-family: "basier_squaremedium";
    font-size: 14px;
    //font-weight: 500;
  }
  height: 20%;
  padding: 23px 0;
  background-color: #f1f2f6;
  text-align: center;
`;

const ContactImg = styled.img`
  float: left;
  margin-right: 10px;
  height: 70px;
`;

const ContactText = styled.div`
  padding: 1rem;
  font-weight: 400;
  font-family: basier_squareregular;
  font-size: 16px;
  margin-bottom: 20px;
  color: #111;
`;

const Button = styled.button`
  &:disabled {
    background-color: ;
    color: #111;
    border: solid 1px #d2d3d4;
  }
  display: inline-block;
  border-radius: 5px;
  padding: 0px 25px;
  box-sizing: content-box;
  font-size: 17px;
  background-color: ${(props) => (props.active ? "#dfe3e8" : "#f2581b")};
  font-weight: 600;
  border: none;
  color: ${(props) => (props.active ? "#111" : "#fff")};
  height: 60px;
  border: ${(props) => props.active && "solid 1px #d2d3d4"};
`;
