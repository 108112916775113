import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
// import { Button } from "../../components";
import moment from "moment";

const FormComponent = (props) => {
  const Data = props?.formData;
  const Pincode = props?.pinCode;
  const Region = props?.region;
  useEffect(() => {
    props.Review();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {props.ic_id === 2 ? (
        //hdfc
        <div style={{ marginTop: "-30px", padding: "9px 0" }}>
          <Row>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Address line1</label>
                </div>
                <div style={{ marginTop: "-10px", fontSize: "13px" }}>
                  {Data?.address || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Pin-code</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {Data?.pincode || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>State</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {Region?.state || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>City</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {Region?.city || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>PEP Status</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {(Data?.pep ? "Applicable" : "Not Applicable") || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Source Income</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {Data?.source || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Policy Start Date</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {moment(Data?.start_date, "YYYY-MM-DD").format("DD-MM-YYYY") || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Policy End Date</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {moment(Data?.endDate, "DD/MM/YYYY").format("DD-MM-YYYY") ||
                    "N/A"}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{ marginTop: "-30px", padding: "9px 0" }}>
          <Row>
            <Col sm={6} lg={6}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Address line1</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {Data?.address || "N/A"}
                </div>
              </div>
            </Col>
            {!!Data?.address2 && <Col sm={6} lg={6}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Address line2</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {Data?.address2 || "N/A"}
                </div>
              </div>
            </Col>}
            <Col sm={6} lg={6}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Pin-code</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {Pincode || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={6}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>State</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {Region?.state || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={6}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>City</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {Region?.city || "N/A"}
                </div>
              </div>
            </Col>
            {!!Data?.toggle && <>
              <Col sm={12} lg={12}>
                <div><b>Different Mailing Address</b></div>
              </Col>
              <Col sm={6} lg={6}>
                <div class="form-group">
                  <div>
                    <label style={{ fontSize: "14px" }}>Address line1</label>
                  </div>
                  <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                    {Data?.mailaddress1 || "N/A"}
                  </div>
                </div>
              </Col>
              {!!Data?.mailaddress2 && <Col sm={6} lg={6}>
                <div class="form-group">
                  <div>
                    <label style={{ fontSize: "14px" }}>Address line2</label>
                  </div>
                  <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                    {Data?.mailaddress2 || "N/A"}
                  </div>
                </div>
              </Col>}
              <Col sm={6} lg={6}>
                <div class="form-group">
                  <div>
                    <label style={{ fontSize: "14px" }}>Pin-code</label>
                  </div>
                  <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                    {Data?.mailpincode || "N/A"}
                  </div>
                </div>
              </Col>
              <Col sm={6} lg={6}>
                <div class="form-group">
                  <div>
                    <label style={{ fontSize: "14px" }}>State</label>
                  </div>
                  <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                    {Data?.mailstate || "N/A"}
                  </div>
                </div>
              </Col>
              <Col sm={6} lg={6}>
                <div class="form-group">
                  <div>
                    <label style={{ fontSize: "14px" }}>City</label>
                  </div>
                  <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                    {Data?.mailcity || "N/A"}
                  </div>
                </div>
              </Col></>}
            <Col sm={6} lg={6}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Policy Start Date</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {moment(Data?.start_date, "YYYY-MM-DD").format("DD-MM-YYYY") || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={6}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Policy End Date</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {moment(Data?.endDate, "DD/MM/YYYY").format("DD-MM-YYYY") ||
                    "N/A"}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default FormComponent;
