import { combineReducers } from "redux";

// plan slice
import planSlice from "../modules/plans/plans.slice";

//greeting
import greetingSlice from "../modules/Landing/landing.slice";

//details form
import detailsSlice from "../modules/DetailsForm/details.slice";

export default combineReducers({
  plans: planSlice,
  greeting: greetingSlice,
  detailsform: detailsSlice,
});
