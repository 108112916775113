import HttpClient from '../../api/httpClient';

const getAllQuotes = ({sum_insured = 100000, plan_type = 1}) => 
HttpClient(`/common/get/all-plan?sum_insured=${sum_insured}&plan_type=${plan_type}`);

const updateUser = (data) => HttpClient('/common/update/user', { method: "POST", data });

const getAllFeatures = () => HttpClient('/common/get/features');

const tracking = (data) => HttpClient("/common/create/user-journey", { method: "POST", data });

const trackingUpdate =(data)=> HttpClient("/common/update/user-journey",{method:"POST",data});

const sendQuotes =(data)=>HttpClient("/common/send-email",{method:"POST",data});
export const service = {
  getAllQuotes,
  updateUser,       
  getAllFeatures,
  tracking,
  trackingUpdate,
  sendQuotes,
}
