import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

const SignupSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string(),
  age: yup
    .number()
    .required()
    .positive()
    .integer(),
  website: yup.string().url()
});

export const FormValidation = () => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(SignupSchema)
  });
  const [isShow, setIsShow] = React.useState(false);

  const onSubmit = data => {
    alert(JSON.stringify(data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isShow ? (
        <>
          <div>
            <label>First Name</label>
            <input type="text" name="firstName" ref={register} />
            {errors.firstName && <p className={'text-danger'}>{errors.firstName.message}</p>}
          </div>
          <div style={{ marginBottom: 10 }}>
            <label>Last Name</label>
            <input type="text" name="lastName" ref={register} />
            {errors.lastName && <p className={'text-danger'}>{errors.lastName.message}</p>}
          </div>
        </>
      ) : (
          <>
            <div>
              <label>Age</label>
              <input type="text" name="age" ref={register} />
              {errors.age && <p className={'text-danger'}>{errors.age.message}</p>}
            </div>
            <div>
              <label>Website</label>
              <input type="text" name="website" ref={register} />
              {errors.website && <p className={'text-danger'}>{errors.website.message}</p>}
            </div>
          </>
        )}
      <button onClick={() => setIsShow(p => !p)}>toggle</button>
      <input type="submit" />
    </form>
  );
}
