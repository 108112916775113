export const numOnly = (event) => {
  if (!(event.which > 47 && event.which < 58) || event.which === 13)
    event.preventDefault();
};

export const noNum = (event) => {
  event.target.value = event.target.value.replace(/[^A-Za-z]/, "");
};

export const noSpecial = (event) => {
  let key = event.keyCode;
  if ((47 < key && key < 58) || key === 8 || key === 189 || key === 109) {
    event.preventDefault();
  }
};

export const noNumNew = (event) => {
  let key = event.keyCode;
  if (
    (key >= 65 && key <= 90) ||
    key === 8 ||
    key === 9 ||
    key === 20 ||
    key === 16 ||
    key === 13 ||
    key === 37 ||
    key === 39
  ) {
  } else {
    event.preventDefault();
  }
};
