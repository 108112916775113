import React, { useEffect } from 'react';
import { clear_compare } from "../plans.slice"
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import swal from "sweetalert";
import { ConfirmButton } from '../../../components';

export const Compare = ({searchQuery}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { compare_active, compare, sendDetails } = useSelector(state => state.plans);
  let emptybox = [1, 2, 3, 4];
  // eslint-disable-next-line no-unused-vars
  for (let i of compare) {
    emptybox.pop()
  }
  useEffect(() => {
    dispatch(clear_compare())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");


  const comma = (str) => {
    return str.toLocaleString("en-IN");
  };
  const compId = compare.map((policy) => {
    return policy.id;
  })

  const CompareClick = () => {
    if (compare.length <= 1) {
      swal(
        "",
        "Select Atleast 2 Insurance to Compare",
        "info"
      );
    }
    else {
      history.push({ pathname: `/compare/${comma(compId)}/${sendDetails?.sum_assured || 100000}/${sendDetails?.plan_type}`, search: `enquiry_id=${enquiry_id}${searchQuery ? '&' + searchQuery : ''}` })
    }
  }


  return (
    <div className="row bottom m-t-20" >
      <div className={`cover-popup cover-popup2 ${compare_active ? 'active' : ''}`}>
        <div className="compare-panel plan-card-add">
          <div className="row">
            {compare.map((policy, index) => (
              <div key={`policy-${index}`} className="col col-empty col-empty-1">
                <div className="empty-card plan-card">
                  <img src={policy.ic_logo} alt="" /> <span>{policy.ic_name}</span>
                </div>
              </div>
            ))}
            {emptybox.map((elem) => (
              <div key={`empty-${elem}`} className="col col-empty col-empty-1">
                <div className="empty-card">
                  <div></div>
                </div>
              </div>
            ))}
            <div className="col">
              <div className="confirmBtn">
                <ConfirmButton style={{ height: "40px" }} onClick={CompareClick}>Compare</ConfirmButton>
                {/* {compareFun(compare)} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
