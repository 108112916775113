import React from 'react';
import './advertisement.css'

export const TopMobile = () => (
  <div class="cmnTopWrap">
    <div class="cmnBlue">
      <span>Rs.1 lakh</span>
      <span>cyber cover</span>
      <span>at ₹55/month* </span>

      <span class="blueSubFirst">Get insured from</span>
      <span class="blueSub">the comfort of</span>
      <span class="blueSub">your home</span>
    </div>
    <div class="cmnYellow">
      <span>Cover for
      unauthorized
      online transactions,
      email spoofing,
      phishing and more</span>
    </div>
    <div class="cmnSkyBlue">
      <span>New age must have protection cover!</span>
    </div>
    <div class="cmnTitle">Compare & Buy <span>Cyber insurance</span></div>
    <div class="cmnImgWrap">
      <img class="cmnFamilyImg" src="/assets/image/cyber.png" alt="cyber" />
    </div>
  </div>
)

export const BottomMobile = () => (
  <>
    <div class="cmnBottomWrap">
      <div class="ribbon-banner">Why Elephant Insurance?</div>
      <div class="cmnBottomTitle">Give yourself superpowers:</div>
      <ul>
        <li>Powered by IRDAI Certified Insurance Broker i.e. Alliance Insurance Brokers Pvt. Ltd.</li>
        <li>AI-based Insurance Recommendation</li>
        <li>Handpicked Insurers</li>
        <li>100% Assistance from Experts</li>
        <li>Unmatched Prices</li>
        <li>Exclusive Digital Relationship Manager</li>
        <li>Best-in-Class Claims Service</li>
        <li>Lifetime Personalized Account</li>
        <li>Renewal Management Program</li>
      </ul>
    </div>
    <div class="cmnTerms">*All discounts provided by insurers as per IRDAI approved insurance plans. Standard T&C apply.</div>
  </>
)