/* eslint-disable eqeqeq */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconlessCard, Button as Btn, Footer, Loader } from "../../components";
import InfoCard from "./infoCard";
import FormComponent from "./FormFile";
import FormAddress from "./FormFile-address";
import SecurityDetails from "./FormFile-SecurityDetails";
import SecurityDetailsSummary from "./SecurityDetailsSummary";
import Summary from "./Summary";
import SummaryAddress from "./summary-address";
import { Row, Col, Collapse, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Data } from "./data";
import { validateForm, validateAddress } from "./formValidation";
import styled from "styled-components";
import moment from "moment";
import swal from "sweetalert";
// import {
//   loadAllQuotes,
//  updateUser,
//  trackProgress,
//  clear_compare,
//  setSumInsureds,
//  setPlanTypes
// } from "../plans/plans.slice";
import { setToken } from "../Landing/landing.slice";
import {
  planData,
  savePlanData,
  getList,
  updateProgress,
  clearAlertMessage,
  savePersonalDetails,
  getPers,
  getAll,
  saveSecurityDetails,
  getSecurityDetails,
  clearLoading,
  updateJourney,
  planDetails,
} from "./details.slice";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
// import { getSecurity } from "./serviceApi";

const DetailsForm = (props) => {
  //states,hooks,selectors
  const [check, setCheck] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const details = useSelector((state) => state.detailsform);
  const { user } = useSelector((state) => state.plans);
  const { searchQuery } = useSelector((state) => state.greeting);

  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const Lead_Source = query.get("utm_source");
  const Lead_Medium = query.get("utm_medium");
  const Campaign_Name = query.get("utm_campaign");
  useEffect(() => {
    dispatch(getPers(enquiry_id));
    dispatch(getAll(enquiry_id));
    dispatch(getSecurityDetails(enquiry_id));
    dispatch(setToken({ user_journey_id: enquiry_id }));

    return () => {
      dispatch(clearLoading());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { personalResponse } = useSelector((state) => state.detailsform);
  const history = useHistory();
  const { watch, register } = useForm();
  const [edit, setEdit] = useState("edit");
  const [edit2, setEdit2] = useState("edit");
  const [edit3, setEdit3] = useState("edit");
  const [validate, setValidate] = useState({});
  const [addValidate, setAddValidate] = useState({});
  const [formData, setFormData] = useState({});
  const [formDataAdd, setFormDataAdd] = useState({});
  const [formSubmit, setFormSumbit] = useState(false);
  const [formDataSec, setFormDataSec] = useState({
    network: true,
    antivirus: true,
    backup: true,
    perils: false,
  });
  // const { user, success } = useSelector((state) => state.plans);
  useEffect(() => {
    if (!_.isEmpty(details.security)) {
      const data = details?.security?.data?.data;
      if (data) {
        setFormDataSec(data);
        setEdit3("preview");
      }
    }
  }, [details.security]);
  useEffect(() => {
    if (!_.isEmpty(details.personal)) {
      const data = details?.personal?.data?.data[0];
      setFormData({
        firstname: data?.fname,
        lastname: data?.lname,
        email: data?.email,
        mobile_no: String(data?.mobile_no),
        title: data?.title,
        gender: data?.gender === "male" || data?.gender === "M" ? "M" : "F",
        dob: data?.dob,
      });
      if (data) {
        setEdit("preview");
      }
    }
  }, [details.personal]);
  useEffect(() => {
    if (details.allDetails?.data) {
      const data = details?.allDetails?.data;
      if (Array.isArray(data.data)) {
        const data2 = data.data[0];
        if (data2?.proposal_data) {
          const user_proposal = data2.proposal_data[0];
          if (user_proposal) {
            setFormDataAdd({
              address: user_proposal.address,
              address2: user_proposal.address2,
              pep: user_proposal.pep_status,
              pincode: user_proposal.pincode,
              source: user_proposal.source,
              start_date: user_proposal.start_date,
              endDate: moment(user_proposal.end_date, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              ),
              city: user_proposal.city,
              state: user_proposal.state,
              toggle: user_proposal.is_mailing_add_diff === "1",
              mailaddress1: user_proposal.mailaddress1,
              mailaddress2: user_proposal.mailaddress2,
              mailpincode:
                user_proposal.mailpincode !== 0
                  ? user_proposal.mailpincode
                  : "",
              mailcity: user_proposal.mailcity,
              mailstate: user_proposal.mailstate,
            });
            getPincode(user_proposal.pincode);
          }
        }
      }
    }
  }, [details.allDetails]);

  const [accept, setAccept] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [endDate, setEndDate] = useState(null);
  const response = useSelector((state) => state.detailsform);
  const confirmation = watch(["accept"]);
  const { savePlanresponse = {}, alert, loading } = response;
  const { id } = props?.match?.params;

  //get query params-------------------
  useEffect(() => {
    if (id) {
      let data = { plan_id: id };
      dispatch(planData(data));
    }
    return () => {
      dispatch(planDetails({}));
    };
  }, [id, dispatch]);

  // -----------------------------------
  useEffect(() => {
    if (
      (edit === "edit" || edit2 === "edit") &&
      response.response?.data?.data[0]?.ic_id === 2
    ) {
      setAccept(false);
    } else if (
      edit === "preview" &&
      edit2 === "preview" &&
      response.response?.data?.data[0]?.ic_id === 2
    ) {
      setAccept(true);
    }

    if (
      (edit === "edit" || edit2 === "edit" || edit3 === "edit") &&
      response.response?.data?.data[0]?.ic_id === 1
    ) {
      setAccept(false);
    } else if (
      edit === "preview" &&
      edit2 === "preview" &&
      edit3 === "preview" &&
      response.response?.data?.data[0]?.ic_id === 1
    ) {
      setAccept(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, edit2, edit3]);
  useEffect(() => {
    if (alert) {
      swal("Error", alert, "info").then(() =>
        history.push(
          `/error?enquiry_id=${enquiry_id}&id=${id}${
            searchQuery ? "&" + searchQuery : ""
          }`
        )
      );
    }

    return () => {
      dispatch(clearAlertMessage());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, history, dispatch]);
  useEffect(() => {
    if (savePlanresponse && savePlanresponse?.data?.status === false && check) {
      swal("Oops", savePlanresponse?.data?.message, "error");
      setEdit2("edit");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePlanresponse]);

  const alertConfirmation = () => {
    if (!confirmation?.accept) {
      // swal("Please acknowledge the declaration clause to proceed", "", "info");
    } else {
      dispatch(updateJourney({ user_journey_id: enquiry_id }));
    }
  };

  const HDFC = () => {
    const hdfcData = savePlanresponse?.data?.data;

    return (
      <form id="hdfc-gateway" action={hdfcData?.url} method="post">
        <input
          type="hidden"
          name="CustomerId"
          value={hdfcData?.CustomerId}
        ></input>
        <input
          type="hidden"
          name="TxnAmount"
          value={hdfcData?.TxnAmount}
        ></input>
        <input
          type="hidden"
          name="AdditionalInfo1"
          value={hdfcData?.AdditionalInfo1}
        ></input>
        <input
          type="hidden"
          name="AdditionalInfo2"
          value={hdfcData?.AdditionalInfo2}
        ></input>
        <input
          type="hidden"
          name="AdditionalInfo3"
          value={hdfcData?.AdditionalInfo3}
        ></input>
        <input
          type="hidden"
          name="hdnPayMode"
          value={hdfcData?.hdnPayMode}
        ></input>
        <input type="hidden" name="UserName" value={hdfcData?.UserName}></input>
        <input
          type="hidden"
          name="UserMailId"
          value={hdfcData?.UserMailId}
        ></input>
        <input
          type="hidden"
          name="return_url"
          value={"https://www.google.com/"}
        ></input>
        <input
          type="hidden"
          name="ProductCd"
          value={hdfcData?.ProductCd}
        ></input>
        <input
          type="hidden"
          name="ProducerCd"
          value={hdfcData?.ProducerCd}
        ></input>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Btn
            type={confirmation.accept ? "submit" : "button"}
            onClick={alertConfirmation}
            style={{
              fontWeight: "500",
              fontSize: "15px",
              height: "40px",
              paddingTop: "7px",
              borderRadius: "5%",
            }}
          >
            Proceed to Payment
          </Btn>
        </Row>
      </form>
    );
  };

  const BAJAJ = () => {
    const bajajData = savePlanresponse?.data?.data;
    return (
      <form action={bajajData?.payment_url} method="post">
        <input type="hidden" name="amount" value={bajajData?.amount}></input>
        <input type="hidden" name="api_key" value={bajajData?.api_key}></input>
        <input type="hidden" name="city" value={bajajData?.city}></input>
        <input type="hidden" name="country" value={bajajData?.country}></input>
        <input type="hidden" name="state" value={bajajData?.state}></input>
        <input
          type="hidden"
          name="currency"
          value={bajajData?.currency}
        ></input>
        <input
          type="hidden"
          name="description"
          value={bajajData?.description}
        ></input>
        <input type="hidden" name="email" value={bajajData?.email}></input>
        <input type="hidden" name="mode" value={bajajData?.mode}></input>
        <input type="hidden" name="name" value={bajajData?.name}></input>
        <input
          type="hidden"
          name="order_id"
          value={bajajData?.order_id}
        ></input>
        <input type="hidden" name="phone" value={bajajData?.phone}></input>
        <input
          type="hidden"
          name="return_url"
          value={bajajData?.return_url}
        ></input>
        <input
          type="hidden"
          name="zip_code"
          value={bajajData?.zip_code}
        ></input>
        <input
          type="hidden"
          name="split_enforce_strict"
          value={bajajData?.split_enforce_strict}
        ></input>
        <input type="hidden" name="hash" value={bajajData?.hash}></input>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Btn
            type={confirmation.accept ? "submit" : "button"}
            onClick={alertConfirmation}
            style={{
              fontWeight: "500",
              fontSize: "15px",
              height: "40px",
              paddingTop: "7px",
              borderRadius: "5%",
            }}
          >
            Proceed to Payment
          </Btn>
          <div className="mt-5"></div>
        </Row>
      </form>
    );
  };
  useEffect(() => {
    if (personalResponse && !personalResponse?.data?.status) {
      swal("Oops", personalResponse?.data?.message?.email[0]);
      setEdit("edit");
    }
  }, [personalResponse]);

  //onFormSubmit--------------------------------------------------------
  const onSubmit = (data) => {
    const validation = validateForm(
      data,
      response.response.data.data[0].ic_id == "1" ? "bajaj" : "hdfc"
    );

    if (validation.formIsValid) {
      setFormData(data);
      setValidate({});
      const id = enquiry_id;
      dispatch(
        savePersonalDetails({
          dob: data.dob,
          fname: data.firstname,
          lname: data.lastname,
          email: data.email,
          gender: data.gender === "M" ? "male" : "female",
          mobile_no: data.mobile_no,
          title: data.title,
          user_journey_id: id,
          ic_id: response.response.data.data[0].ic_id,
        })
      );
      dispatch(
        updateProgress({
          user_journey_id: id,
          has_submitted_personal_details: 1,
          Lead_Source,
          Lead_Medium,
          Campaign_Name,
        })
      );
      setEdit("preview");
    } else {
      setValidate(validation);
    }
  };

  const onSubmit2 = (data) => {
    const validation = validateAddress(data);
    const id = enquiry_id;
    if (validation.formIsValid) {
      setFormDataAdd(data);
      setAddValidate({});
      setEdit2("preview");
      dispatch(
        updateProgress({
          has_submitted_security: 1,
          user_journey_id: id,
          Lead_Source,
          Lead_Medium,
          Campaign_Name,
        })
      );
      // Review()
    } else {
      setAddValidate(validation);
    }
  };

  const onSubmit3 = (data) => {
    setFormDataSec(data);
    setEdit3("preview");
    dispatch(
      saveSecurityDetails({
        ...data,
        user_journey_id: enquiry_id,
      })
    );
  };

  //---------------------------------------------------------------------

  //Review and Accept policy---------------------------------------------

  const Review = useCallback(() => {
    let formdata = Object.assign(formData, formDataAdd, formDataSec);
    if (!_.isEmpty(response?.regionresponse?.data) && endDate) {
      formdata = Object.assign(
        {
          end_date: moment(endDate, "DD/MM/YYYY").format("DD-MM-YYYY"),
          quote_rate_id: id,
          pincode: pinCode,
          city: response?.regionresponse?.data?.city,
          state: response?.regionresponse?.data?.state,
        },
        formdata
      );
      const journey_id = enquiry_id;
      dispatch(savePlanData({ ...formdata, user_journey_id: journey_id })).then(
        () => {
          setFormSumbit(true);
        }
      );
      let data = {
        user_journey_id: journey_id,
        proposal_submitted: 1,
      };
      dispatch(
        updateProgress({
          ...data,
          Lead_Source,
          Lead_Medium,
          Campaign_Name,
        })
      );
      setAccept(true);
      setCheck(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    endDate,
    id,
    formData,
    formDataAdd,
    pinCode,
    response.regionresponse.data,
  ]);

  //  to store in redux store before proceeding to payment
  useEffect(() => {
    if (formSubmit) {
      dispatch(getPers(enquiry_id));
      dispatch(getAll(enquiry_id));
      dispatch(getSecurityDetails(enquiry_id));
      dispatch(setToken({ user_journey_id: enquiry_id }));
    }
    return () => {
      dispatch(clearLoading());
    };
  }, [formSubmit]);

  useEffect(() => {
    if (
      !_.isEmpty(formDataAdd) &&
      !_.isEmpty(formData) &&
      !(edit === "edit" || edit2 === "edit" || edit3 === "edit")
    ) {
      Review();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDataAdd, formData, Review]);

  //card title--------------------------------------------------------

  /*Title-personal-details*/
  const title = (
    <div style={{ display: "flex", width: "100%", marginTop: "4px" }}>
      <text style={{ width: "90%", fontWeight: "700" }}>Personal Details</text>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          class="btn btn-link"
          onClick={() => {
            setEdit("edit");
            setEdit2("edit");
            setEdit3("edit");
          }}
        >
          <text
            style={{
              fontSize: "12px",
              fontStyle: "italic",
              fontWeight: "normal",
            }}
          >
            EDIT
          </text>
        </button>
      </div>
    </div>
  );

  /*Title-personal-details*/
  const titleAddress = (
    <div
      style={{
        display: "flex",
        width: "100%",
        marginTop: "4px",
        textAlign: "left",
      }}
    >
      <text style={{ width: "90%", fontWeight: "700", textAlign: "left" }}>
        Address & Policy Details
      </text>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          className="btn btn-link"
          varient="primary"
          onClick={() => {
            setEdit2("edit");
          }}
        >
          <text
            style={{
              fontSize: "12px",
              fontStyle: "italic",
              fontWeight: "normal",
            }}
          >
            EDIT
          </text>
        </button>
      </div>
    </div>
  );

  const securityDetails = (
    <div
      style={{
        display: "flex",
        width: "100%",
        marginTop: "4px",
        textAlign: "left",
      }}
    >
      <text style={{ width: "90%", fontWeight: "700", textAlign: "left" }}>
        Security Details
      </text>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          className="btn btn-link"
          varient="primary"
          onClick={() => {
            setEdit3("edit");
            setEdit2("edit");
          }}
        >
          <text
            style={{
              fontSize: "12px",
              fontStyle: "italic",
              fontWeight: "normal",
            }}
          >
            EDIT
          </text>
        </button>
      </div>
    </div>
  );

  //getPincode & end date------------------
  const getPincode = (pincode) => {
    setPinCode(pincode);
  };

  const getEndDate = (enddate) => {
    setEndDate(enddate);
  };
  //---------------------------

  //get Master Feature List ----------------
  useEffect(() => {
    dispatch(getList());
  }, [dispatch]);
  //----------------------------------------
  // const q_id = enquiry_id;
  return response?.response?.data?.data?.length ? (
    <>
      <div>
        <div>&nbsp;</div>
        <Row className="test">
          <Col xs={12} sm={12} md={4} lg={4} xl={3}>
            <BackButton
              onClick={() =>
                history.push(
                  `/plans?enquiry_id=${enquiry_id}${
                    searchQuery ? "&" + searchQuery : ""
                  }`
                )
              }
              className="btn back-btn"
            >
              <strong>
                <img
                  src={require("../../assets/images/arrow_back.png")}
                  alt="arrow"
                  style={{ transform: "scale(.9)" }}
                />
                <b style={{ fontWeight: "700" }}>&nbsp; Back</b>
              </strong>
            </BackButton>
            <DivContainer>
              <InfoCard
                Data={Data}
                id={id}
                Data1={response?.response?.data?.data[0]}
                list={response?.listresponse?.data?.data}
              />
            </DivContainer>
          </Col>
          <ColDiv
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={9}
            style={{ marginBottom: "10px", width: "60%" }}
          >
            <HeaderTag>
              Almost done! Please help with a few more details and get your
              policy instantly.
            </HeaderTag>
            <DetailCard>
              <PolicyCard>
                {edit === "edit" ? (
                  <IconlessCard
                    removeBottomHeader={true}
                    title={
                      <>
                        <TextDiv>
                          <p>
                            Almost done! Please help with a few more details and
                            get your policy instantly.
                          </p>
                        </TextDiv>
                        <div>
                          <p
                            style={{
                              marginRight: "20px",
                              fontWeight: "700",
                              letterSpacing: "0px",
                            }}
                          >
                            Personal Details
                          </p>
                        </div>
                      </>
                    }
                    color={"#004b83"}
                    image={"/assets/image/laxmiImg.png"}
                    imageStyle={{
                      position: "relative",
                      top: "1px",
                      left: "22px",
                    }}
                  >
                    <FormComponent
                      user={user}
                      onSubmit={onSubmit}
                      validate={validate}
                      formData={formData}
                      id={id}
                      ic_id={response?.response?.data?.data[0].ic_id}
                    />
                  </IconlessCard>
                ) : (
                  <IconlessCard
                    removeBottomHeader={true}
                    title={title}
                    color={"#004b83"}
                    image={"/assets/image/laxmiImg.png"}
                    imageStyle={{
                      position: "relative",
                      top: "1px",
                      left: "22px",
                    }}
                    style={{ marginRight: "20px", width: "107%" }}
                    marginTop="30px"
                  >
                    <Summary
                      ic_id={response?.response?.data?.data[0].ic_id}
                      formData={formData}
                    />
                  </IconlessCard>
                )}
              </PolicyCard>
              {response?.response?.data?.data[0].ic_id == "1" && (
                <AddressCard>
                  {edit3 === "edit" ? (
                    <IconlessCard
                      style={{
                        textAlign: "left",
                        marginRight: "30px",
                        border: "1px solid",
                      }}
                      removeBottomHeader={true}
                      title={"Security Details"}
                      color={"#004b83"}
                      marginTop="0px"
                      padding="13px"
                    >
                      <>
                        <Collapse
                          in={edit === "preview"}
                          style={{ transition: "all 0.5s ease" }}
                        >
                          <div style={{ margin: "0", padding: "0" }}>
                            <Row style={{ paddingLeft: "16px" }}>
                              <SecurityDetails
                                onSubmit={onSubmit3}
                                formData={formDataSec}
                              />
                            </Row>
                          </div>
                        </Collapse>
                      </>
                    </IconlessCard>
                  ) : (
                    <IconlessCard
                      removeBottomHeader={true}
                      title={securityDetails}
                      color={"#004b83"}
                      marginTop="0"
                    >
                      <SecurityDetailsSummary formData={formDataSec} />
                    </IconlessCard>
                  )}
                </AddressCard>
              )}
              <AddressCard>
                {edit2 === "edit" ? (
                  <IconlessCard
                    style={{
                      textAlign: "left",
                      marginRight: "30px",
                      border: "1px solid",
                    }}
                    removeBottomHeader={true}
                    title={"Address & Policy Details"}
                    color={"#004b83"}
                    marginTop="0px"
                    fontweight="700"
                    padding="13px 26px"
                    paddingLeft="0"
                  >
                    <>
                      <Collapse
                        in={
                          response?.response?.data?.data[0].ic_id == "1"
                            ? edit3 === "preview"
                            : edit === "preview"
                        }
                        style={{ transition: "all 0.5s ease" }}
                      >
                        <div style={{ margin: "0", padding: "0" }}>
                          <Row>
                            <FormAddress
                              onSubmit={onSubmit2}
                              formData={formDataAdd}
                              getPincode={getPincode}
                              pinCode={pinCode}
                              getEndDate={getEndDate}
                              endDate={endDate}
                              validate={addValidate}
                              ic_id={response?.response?.data?.data[0].ic_id}
                              Review={Review}
                              show={accept}
                              region={response?.regionresponse?.data}
                            />
                          </Row>
                        </div>
                      </Collapse>
                    </>
                  </IconlessCard>
                ) : (
                  <IconlessCard
                    removeBottomHeader={true}
                    title={titleAddress}
                    color={"#004b83"}
                    marginTop="20px"
                  >
                    <SummaryAddress
                      formData={formDataAdd}
                      Review={Review}
                      show={accept}
                      pinCode={pinCode}
                      region={response?.regionresponse?.data}
                      endDate={endDate}
                      ic_id={response?.response?.data?.data[0].ic_id}
                    />
                  </IconlessCard>
                )}
              </AddressCard>
            </DetailCard>
            <Collapse in={accept} style={{ transition: "all 0.5s ease" }}>
              <div style={{ padding: "10px" }}>
                <Col sm={12}>
                  <div style={{ display: "flex", padding: "10px" }}>
                    <div className="p-1">
                      <label className="checkbox-container">
                        <input
                          className="bajajCheck"
                          defaultChecked
                          name="accept"
                          type="checkbox"
                          ref={register}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="p-1">
                      <p style={{ fontSize: "11px" }}>
                        I also confirm having read and understood the
                        <Term
                          target="_blank"
                          href="http://www.elephant.in/terms_condition"
                        >
                          Terms & Conditions
                        </Term>
                        . I confirm all the details shared are correct and
                        accurate to the best of my knowledge and accept that if
                        it is found to be false, it may impact claims, and
                        insurance company reserves the rights to cancel the
                        policy and make it null and void in the event of any
                        untrue or incorrect statement. I also agree any changes
                        to the details post payment might require additional
                        payment. Alliance Insurance Brokers Pvt. Ltd. (including
                        its representatives) shall not be held liable for any
                        changes due to incorrect information.
                      </p>
                      {!confirmation?.accept && (
                        <p className="error" style={{ fontSize: "11px" }}>
                          Please accept terms and conditions
                        </p>
                      )}
                    </div>
                  </div>
                </Col>
                {!!(
                  savePlanresponse?.data?.status &&
                  response?.response?.data?.data.length &&
                  response?.response?.data?.data[0].ic_id === 1
                ) && BAJAJ()}
                {!!(
                  savePlanresponse?.data?.status &&
                  response?.response?.data?.data.length &&
                  response?.response?.data?.data[0].ic_id === 2
                ) && HDFC()}
              </div>
            </Collapse>
          </ColDiv>
        </Row>
        {loading && (
          <Spinner
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
            animation="border"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
      </div>
      <Footer showCrafted />
    </>
  ) : (
    <Loader />
  );
};

//style
const ColDiv = styled(Col)`
  margin-left: -20px;
  @media (max-width: 992px) {
    margin-left: 0;
    margin-top: 0px;
  }
  @media (max-width: 766px) {
    /*image removed at 767px*/
    margin-top: 0;
  }
`;

const DivContainer = styled.div`
  width: 93%;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

const HeaderTag = styled.h3`
  /*header removed at 993px and is replaced by text inside card at 993px*/
  display: flex;
  /* justify-content: center; */
  padding-left: 105px;
  padding-right: 15px;
  position: relative;
  top: 25px;
  margin-top: -30px;
  font-size: 22px;
  font-weight: 600;
  @media (max-width: 993px) {
    display: none;
    margin-top: 0;
  }
`;

const DetailCard = styled.div`
  margin: 1.5rem;
  padding: 0;
  @media (max-width: 993px) {
    margin: 0;
  }
`;

const PolicyCard = styled.div`
  position: relative;
  top: 0.6rem;
`;

const AddressCard = styled.div`
  display: block;
`;

const BackButton = styled.button`
  float: left;
  margin-top: -20px;
  margin-left: 0px;
`;

const TextDiv = styled.div`
  display: none;
  @media (max-width: 993px) {
    display: block;
    color: black;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
  }
`;

const Term = styled.a`
  &:link,
  &:visited {
    color: #107591;
  }
  color: #107591;
  font-size: 11px;
  padding-left: 3px;
`;

export default DetailsForm;
