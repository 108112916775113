import React, { useState } from "react";
import Popup from "./Popup";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { sendEmail } from "../../modules/plans/plans.slice";
import { useLocation } from "react-router";
import { _capitalizeText } from "../../utils";

const SendQuotes = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [msg, setMsg] = useState(false);
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const plans = useSelector((state) => state.plans);
  const data = {
    email: plans.user.email,
    mobile_no: plans.user.mobile_no,
    name: `${plans.user.firstname} ${plans.user.lastname}`,
    sum_insured: plans.sendDetails.sum_assured,
    plan_type: plans.sendDetails.plan_type,
    user_journey_id: enquiry_id,
  };
  // const content = (
  //   <>
  //     <MainWrapper>
  //       <LaxmiWrapper>
  //         <Laxmi src="/assets/image/laxmiImg.png" alt="Laxmi" />
  //       </LaxmiWrapper>
  //       <Wrapper>
  //         <Text>We will send you this quote via SMS on <strong>{plans.user.mobile_no}</strong> and also email on <strong>{plans.user.email}</strong></Text>
  //         <div>
  //           <ConfirmButton onClick={() => { dispatch(sendEmail(data)); onClose(false); }}>Send</ConfirmButton>
  //         </div>
  //       </Wrapper>
  //     </MainWrapper>
  //     <Footer>
  //       <p><span>*</span>Please note that the premium may vary in future.</p>
  //     </Footer>
  //   </>
  // );

  const onSubmit = () => {
    setMsg(true);
    dispatch(sendEmail(data));
    setTimeout(() => {
      onClose(false);
      setMsg(false);
    }, 2500);
  };

  const content2 = (
    <MessageContainer>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        fill='#4fcc6e'
        width='48px'
        height='48px'
      >
        <path d='M0 0h24v24H0z' fill='none'></path>
        <path d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'></path>
      </svg>
      <FlexDiv>
        <LaxmiWrapper>
          <Laxmi src='/assets/image/laxmiImg.png' alt='Laxmi' />
        </LaxmiWrapper>
        <Wrapper>
          <Text>
            Thank you! Your quotes has been sent via SMS on{" "}
            <strong>{plans.user.mobile_no}</strong> and also email to{" "}
            <strong>{plans.user.email}</strong>
          </Text>
        </Wrapper>
      </FlexDiv>
    </MessageContainer>
  );

  const content = (
    <>
      <MainWrapper>
        <LaxmiWrapper>
          <Laxmi src='/assets/image/laxmiImg.png' alt='Laxmi' />
        </LaxmiWrapper>
        <Wrapper>
          <Text>
            Hi <strong>{_capitalizeText(plans.user.firstname || "")}</strong>,
            we will send you this quote via SMS on{" "}
            <strong>{plans.user.mobile_no}</strong> and also email to{" "}
            <strong>{plans.user.email}</strong>
          </Text>
        </Wrapper>
        <div>
          <ConfirmButton onClick={onSubmit}>SEND</ConfirmButton>
        </div>
      </MainWrapper>
      <Content2>
        <p>
          <span>*</span>Please note that the premium may vary in future.
        </p>
      </Content2>
    </>
  );
  return (
    <div>
      <Popup
        height={msg ? "240px" : "auto"}
        width='640px'
        show={show}
        onClose={onClose}
        content={msg ? content2 : content}
        position='top'
      ></Popup>
    </div>
  );
};

export default SendQuotes;

const LaxmiWrapper = styled.div`
  float: left;
  margin-right: 28px;
  @media (max-width: 500px) {
    margin-right: 0;
  }
`;

const Laxmi = styled.img`
  height: 70px;
  border-radius: 50%;
  /* box-shadow: 0px 4px 13px rgba(41,41,41,0.35); */
  border: solid 1px #e3e4e8;
`;

const MainWrapper = styled.div`
  margin: 60px 0 75px;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 500px) {
    margin: 50px 0;
    padding: 0 2px;
  }
`;

const Wrapper = styled.div`
  max-width: 406px;
  text-align: left;
  align-self: center;
  & > div {
    text-align: center;
    margin-top: 10px;
  }
  @media (max-width: 500px) {
    margin-top: 20px;
  }
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: #333;
  /* font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  font-family: "Inter-Regular";
  & strong {
    color: #000000;
    font-weight: 700;
  }
`;

const ConfirmButton = styled.button`
  background-color: #f2581b;
  display: block;
  margin: 33px 30px 0px;
  /* font-size: 15px; */
  color: #fff;
  padding: 17px 40px;
  font-family: "Inter-SemiBold";
  font-weight: bold;
  border-radius: 3px;
  border: none;
  &:focus {
    outline: none;
  }
  @media (max-width: 500px) {
    margin: auto;
  }
`;

const Content2 = styled.div`
  text-align: center;
  color: #333;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 400;
  background-color: #f1f2f6;
  border-top: 1px solid #e3e4e8;
  height: 74px;
  line-height: 20px;
  padding: 25px 0;
`;

const MessageContainer = styled.div`
  padding: 10px;
  & svg {
    margin: 0 auto;
    width: 100%;
  }
`;

const FlexDiv = styled.div`
  padding-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const LaxmiWrapper = styled.div`
// float:left;
// margin-right:28px;
// `;

// const Laxmi = styled.img`
//   height:80px;
//   border-radius:50%;
//   box-shadow: 0px 4px 13px rgba(41,41,41,0.4);
// `;

// const MainWrapper = styled.div`
// margin-top:50px;
// padding:0px 20px;
// `;

// const Wrapper = styled.div`
//   &>div{
//     text-align:center;
//     margin-top:10px;
//   }
// `;

// const Text = styled.p`
// font-size:13px;
// font-weight:600;
// line-height:22px;
// color:#666666;
// & strong{
//   color:#000000;
//   font-weight:700;
// }
// `

// const ConfirmButton = styled.button`
// background-color:#ffc000;
// display:block;
// margin:25px auto;
// font-size: 15px;
// color: #000;
// padding:12px 40px;
// font-weight: bold;
// border-radius: 5px;
// border:none;
// `

// const Footer = styled.div`
// & p{
//   text-align:center;
//   font-size:11px;
// font-weight:600;
// line-height:22px;
// margin-top:50px;
// color:#666666;
// & span{
//   color:red;
// }
// }
// `
