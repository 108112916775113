import styled from 'styled-components';

export const ConfirmButton = styled.button`
  background:#ffc000 ;
  color: #000;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px;
  display: inline-block;
  width: 150px;
  font-size: 13px;
  text-align: center;
  height: 55px;
  border: none;
  
  @-moz-document url-prefix() {
    padding-top: 9px;
  }
  `
  
export default ConfirmButton;
