import moment from "moment";
import swal from "sweetalert";

export const validateForm = (data, ic) => {
  let errors = {};
  let formIsValid = true;

  //Title
  if (!data.title) {
    formIsValid = false;
    errors.title = "Please select a valid Title";
  }

  // First Name
  if (!data.firstname) {
    formIsValid = false;
    errors.firstname = "First name is required";
  }
  if (typeof data.firstname !== "undefined") {
    if (!data.firstname.match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      errors.firstname = "Please enter a valid first name";
    }
  }

  // Last Name
  if (!data.lastname) {
    formIsValid = false;
    errors.lastname = "Last name is required";
  }

  if (typeof data.lastname !== "undefined") {
    if (!data.lastname.match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      errors.lastname = "Please enter a valid last name";
    }
  }

  // Email
  if (!data.email) {
    formIsValid = false;
    errors.email = "Please enter your email address";
  } else if (typeof data.email !== "undefined") {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(data.email)) {
      formIsValid = false;
      errors.email = "Please enter a valid email address";
    }
  }

  // Mobile Number
  if (!data.mobile_no) {
    formIsValid = false;
    errors.mobile_no = "Please enter your contact number";
  } else if (typeof data.mobile_no !== "undefined") {
    if (!data.mobile_no.match(/^[0-9]{10}$/)) {
      formIsValid = false;
      errors.mobile_no = "Please enter mobile no";
    }

    if (typeof data.mobile_no !== "undefined") {
      if (!String(data.mobile_no)?.match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors.mobile_no = "Please enter a valid 10 digit contact number";
      }
      if (
        data.mobile_no === "9999999999" ||
        data.mobile_no === "8888888888" ||
        data.mobile_no === "7777777777" ||
        data.mobile_no === "6666666666"
      ) {
        formIsValid = false;
        errors.mobile_no = "Mobile No. should not be all same digits";
      }
    }

    //Gender
    if (ic === "hdfc") {
      if (!data.gender) {
        formIsValid = false;
        errors.gender = "Please Select Gender";
      }
    }

    //DOB
    if (
      data.dob === moment().format("DD-MM-YYYY") ||
      data.dob === "Invalid date"
    ) {
      formIsValid = false;
      errors.dob = "Please enter your Date of Birth";
    }
  }

  //Gender
  if (ic === "hdfc") {
    if (!data.gender) {
      formIsValid = false;
      errors.gender = "Please select your gender";
    } else {
      if (
        data.gender === "M" &&
        data.title !== "Mr" &&
        (data.title === "Mrs" || data.title === "Ms")
      ) {
        formIsValid = false;
        errors.gender = "Gender and Title do not match";
      }
      if (data.gender === "F" && data.title === "Mr" && data.title !== "Mrs") {
        formIsValid = false;
        errors.gender = "Gender and Title do not match";
      }
    }
  }

  //DOB
  if (
    data.dob === moment().format("DD-MM-YYYY") ||
    data.dob === "Invalid date"
  ) {
    formIsValid = false;
    errors.dob = "Please enter your Date of Birth";
  }

  return {
    errors,
    formIsValid,
  };
};

export const validateAddress = (data) => {
  let errors = {};
  let formIsValid = true;

  //Address
  if ("address" in data) {
    if (!data.address) {
      formIsValid = false;
      errors.address = "Please enter your address";
    }
  }
  if ("mailaddress1" in data) {
    if (!data.mailaddress1) {
      formIsValid = false;
      errors.mailaddress1 = "Please enter your address";
    }
  }

  //Pincode
  if (data.pincode === "undefined" || !data.pincode) {
    formIsValid = false;
    errors.pincode = "Please enter your pincode";
    errors.city = "Please enter your city";
    errors.state = "Please select your state";
  } else if (data?.pincode) {
    if (!data?.pincode?.match(/^[1-9]{1}[0-9]{5}$/)) {
      formIsValid = false;
      errors.pincode = "Please enter a valid pincode";
      errors.city = "Please enter your city";
      errors.state = "Please select your state";
    }
    if (!data?.city) {
      formIsValid = false;
      errors.city = "No city found for above pincode";
      errors.state = "No state found for above pincode";
    }
  }

  //Pincode2
  if (
    "mailpincode" in data &&
    (data.mailpincode === "undefined" || !data.mailpincode)
  ) {
    formIsValid = false;
    errors.mailpincode = "Please enter your pincode";
    errors.mailcity = "Please enter your city";
    errors.mailstate = "Please select your state";
  } else if (data?.mailpincode) {
    if (!data?.mailpincode?.match(/^[1-9]{1}[0-9]{5}$/)) {
      formIsValid = false;
      errors.mailpincode = "Please enter a valid pincode";
      errors.mailcity = "Please enter your city";
      errors.mailstate = "Please select your state";
    }
    if (!data?.mailcity) {
      formIsValid = false;
      errors.mailcity = "No city found for above pincode";
      errors.mailstate = "No state found for above pincode";
    }
  }

  //source of Income
  if (data.source === "Select") {
    formIsValid = false;
    errors.source = "Please select your income source";
  }

  if (!data.start_date || data.start_date === "Invalid date") {
    formIsValid = false;
    errors.start_date = "Please select your policy start date";
  } else if (
    moment(data.start_date, "YYYY-MM-DD").diff(moment(), "days") > 90
  ) {
    formIsValid = false;
    swal("Oops", "Policy Start Date should not be more than 90days", "error");
    errors.start_date = "Policy Start Date should not be more than 90days";
  } else if (
    moment(data.start_date, "DYYYY-MM-DD").diff(moment(), "days") < 0
  ) {
    formIsValid = false;
    errors.start_date = "Policy Start Date should not be from past";
  }

  return {
    errors,
    formIsValid,
  };
};
