/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "styled-components/macro";
// import { ConfirmButton} from "..";
import { Modal } from "react-bootstrap";
// import { ConfirmButton } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { resendEmail as setResendEmail, ResendEmail } from "./landing.slice";
import { useTimer } from "react-timer-hook";
import { CloseBtn } from "../../components/cards/style";

export const EmailModal = ({ show, onHide, emailWatch, expiryTimestamp }) => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState();
  const { resendEmail } = useSelector((state) => state.greeting);
  const { seconds, minutes, isRunning, start, restart } = useTimer({
    expiryTimestamp,
    autoStart: false,
  });
  useEffect(() => {
    if (resendEmail) {
      setShowPopup(true);

      setTimeout(() => {
        setShowPopup(false);
        dispatch(setResendEmail(false));
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendEmail]);
  useEffect(() => {
    if (show) {
      start();
    }
  }, [show]);
  const onResend = () => {
    if (!isRunning)
      dispatch(ResendEmail({ email: emailWatch })).then((data) => {
        const time = new Date();
        time.setSeconds(time.getSeconds() + data?.time);
        restart(time);
      });
  };
  function pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }
  const ref = useRef();
  const [breath, setBreath] = useState(false);

  useEffect(() => {
    let x = document.querySelector(".modal");

    // x.addEventListener("click", (e) => {
    //   e.preventDefault();
    //   setBreath(true);
    //   e.stopPropagation();
    // });
  }, []);
  useEffect(() => {
    if (breath) {
      setTimeout(() => {
        setBreath(false);
      }, 500);
    }
  }, [breath]);
  return (
    <div
      class={`modal fade in ${breath ? "breathe" : ""}`}
      id='emailConfirModal'
      style={{
        display: "block",
        opacity: "1",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        overflowY: "scroll",
      }}
      size='sm'
      dialogClassName={`${breath ? "breathe" : ""}`}
      data-bs-backdrop='static'
      aria-hidden='true'
      onClick={(e) => {
        setBreath(true);
        e.stopPropagation();
      }}
    >
      <div
        class='modal-dialog'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className='modal-content'>
          <div className='modal-body'>
            <button
              css={`
                position: absolute;
                top: 16px;
                right: 15px;
                width: 26px;
                background: none;
                border: none;
                font-size: 20px;
                line-height: 1;
                padding: 0;
                border-radius: 50%;
              `}
              onClick={(e) => {
                console.log("clicked");
                onHide();
              }}
            >
              <svg
                data-bs-dismiss='modal'
                aria-label='Close'
                version='1.1'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
              >
                {" "}
                <path
                  d='M12,2c-5.53,0 -10,4.47 -10,10c0,5.53 4.47,10 10,10c5.53,0 10,-4.47 10,-10c0,-5.53 -4.47,-10 -10,-10Zm5,13.59l-1.41,1.41l-3.59,-3.59l-3.59,3.59l-1.41,-1.41l3.59,-3.59l-3.59,-3.59l1.41,-1.41l3.59,3.59l3.59,-3.59l1.41,1.41l-3.59,3.59l3.59,3.59Z'
                  fill='#000'
                ></path>{" "}
                <path fill='none' d='M0,0h24v24h-24Z'></path>{" "}
              </svg>
            </button>

            <EmailConfBodyWrap onClick={(e) => {}}>
              <svg
                className='emailConfSVG'
                data-name='Layer 1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 500 500'
              >
                <title>EmailConfirmation</title>
                <polygon
                  className='emailC-cls-1'
                  points='248.78 18.41 363.44 103.37 478.29 188.33 248.78 188.33 19.46 188.33 134.12 103.37 248.78 18.41'
                />
                <polygon
                  className='emailC-cls-2'
                  points='378.48 188.33 248.78 188.33 134.5 188.33 134.5 122.92 378.1 122.92 378.48 188.33'
                />
                <path
                  className='emailC-cls-1'
                  d='M25.1,187H472.65a14,14,0,0,1,13.91,14.09V466.9A14,14,0,0,1,472.65,481H25.1A14.2,14.2,0,0,1,11,466.9V201.11A14.2,14.2,0,0,1,25.1,187Z'
                />
                <path
                  className='emailC-cls-3'
                  d='M473.22,481H24.35a12.26,12.26,0,0,1-3.76-.75l113.53-85.9,114.66-86.66,114.66,86.66,113.72,85.9a14.82,14.82,0,0,1-3.94.75Z'
                />
                <path
                  className='emailC-cls-2'
                  d='M25.1,187H472.65a18.58,18.58,0,0,1,4.51.75L363.44,272l-114.66,85L134.12,272,20.59,187.77A16,16,0,0,1,25.1,187Z'
                />
                <polygon
                  className='emailC-cls-4'
                  points='134.5 187.02 248.78 187.02 378.48 187.02 378.85 260.7 363.44 271.98 279.04 334.57 256.3 318.6 230.17 337.02 134.5 257.69 134.5 187.02'
                />
                <path
                  className='emailC-cls-5'
                  d='M477.16,480.25a12.68,12.68,0,0,1-4.51.75H25.1a11.43,11.43,0,0,1-4.51-.75L134.12,400l114.66-81,114.66,81Z'
                />
                <polygon
                  className='emailC-cls-6'
                  points='126.98 131.94 370.58 131.94 371.34 266.15 275.1 337.58 248.78 318.97 222.65 337.58 126.98 266.71 126.98 131.94'
                />
                <path
                  className='emailC-cls-7'
                  d='M143,199.8H355.36v5.26H143V199.8ZM319.27,304.87l-7,5.08H185.43l-7-5.08ZM143,234.76H355.36V240H143v-5.26Zm0,35.15H355.36V275H143v-5.08Zm33.84-105.07H355.36v5.26H176.79Z'
                />
              </svg>
              <div className='emailConfTitle'>Email Confirmation</div>
              <div className='emailConfTxt'>
                We have sent an email to <span>{emailWatch}</span> to verify
                your email address. Click on the link provided in the email to
                complete the sign-up process and enjoy exclusive discounts and
                offers with us.
              </div>
              <div className='emailConfQue'>Didn’t receive email?</div>
              {isRunning && (
                <div class='resendTimer'>
                  <span class='unit'>Min</span>{" "}
                  <span id='timerCountDown'>
                    {pad(minutes)}:{pad(seconds)}
                  </span>{" "}
                  <span class='unit'>Sec</span>
                </div>
              )}
              <a
                className={`emailConfBtn ${
                  isRunning ? "emailConfBtn-disabled" : ""
                }`}
                onClick={onResend}
              >
                RESEND EMAIL
              </a>
              <div
                className={"emailSentMsg " + (showPopup ? "fadeIn" : "fadeOut")}
              >
                Email has been sent again
              </div>
              <div className='emailConfStep'>
                Check your junk/spam folder as sometimes the email may land up
                there.
              </div>
              <div className='emailConfDisc'>
                <div>Need Help?</div>
                <div>
                  Call us on toll free no.{" "}
                  <a href='tel:18002669693'>1800 266 9693</a> or write to us at{" "}
                  <a href='mailto:support@elephant.in'>support@elephant.in</a>{" "}
                  and we would be happy to assist you.
                </div>
              </div>
            </EmailConfBodyWrap>
          </div>
        </div>
      </div>
    </div>
  );
};

// PropTypes
EmailModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

// DefaultTypes
EmailModal.defaultProps = {
  show: false,
  onHide: () => {},
};

// const CloseBtn = styled(ConfirmButton)`
//   /* width: 30px;
//   height: 30px;
//   color: grey;
//   opacity: 1;
//   padding: 0;
//   font-weight: bold;
//   font-size: 25px;
//   text-shadow: none; */
//   background-color: white;
//   position: absolute;
//   top: 0;
//   right: 0;
//   padding: 17px 17px;
//   border: none;
//   width: auto;
//   /* zoom: 0.8; */
//  svg {
//   width: 24px;
//   height: 24px;
//   cursor: pointer;
//   z-index: 1;
// }
// @media only screen and (min-width: 768px) and (max-width: 1230px) {
//   padding: 0 12px 0 0;
//   svg {
//   width: 18px;
//   height: 18px;
//   }
// }

// `;

const EmailConfBodyWrap = styled.div`
  padding: 36px 0 18px;
  text-align: center;
  .emailConfSVG {
    width: 84px;
    height: 84px;
    margin-bottom: 40px;
    @media screen and (max-width: 700px) {
      margin-bottom: 30px;
    }
  }
  .emailConfTitle {
    font-family: "WorkSans-Bold";
    font-size: 24px;
    line-height: 13px;
    color: #152981;
    margin-bottom: 10px;
  }
  .emailConfTxt {
    width: 550px;
    margin: 0 auto 56px;
    font-family: "Inter-Regular";
    font-size: 14px;
    line-height: 25px;
    color: #080808;
    @media screen and (max-width: 700px) {
      margin: 0 auto 30px;
    }
  }
  .emailConfTxt span {
    font-family: "Inter-Bold";
    color: #152981;
  }
  .emailConfQue {
    font-family: "Inter-Regular";
    font-size: 14px;
    line-height: 25px;
    color: #080808;
    margin-bottom: 10px;
  }
  .emailConfBtn {
    display: inline-block;
    height: 36px;
    padding: 4px 25px;
    font-family: "Inter-Bold";
    font-size: 14px;
    line-height: 25px;
    color: #152981;
    margin: 0 auto 30px;
    border: solid 1px #152981;
    border-radius: 24px;
    text-decoration: none;
  }
  .resendTimer {
    font-family: "Inter-SemiBold";
    font-size: 16px;
    line-height: 25px;
    color: #152981;
  }
  .emailConfBtn-disabled {
    opacity: 0.3;
  }
  .resendTimer .unit {
    font-family: "Inter-Regular";
    font-size: 12px;
  }
  .emailConfBtn:hover,
  .emailConfBtn:focus {
    color: #152981;
    text-decoration: none;
    cursor: pointer;
  }
  .emailSentMsg {
    font-family: "Inter-Regular";
    font-size: 14px;
    line-height: 25px;
    color: #152981;
    margin-bottom: 0;
    margin-top: -26px;
    width: 100%;
  }
  .emailConfStep {
    font-family: "Inter-Regular";
    font-size: 11px;
    line-height: 25px;
    color: #222;
    padding-bottom: 5px;
    border-bottom: solid 1px #bcbcbc;
    margin-bottom: 15px;
  }
  .emailConfDisc {
    font-family: "Inter-Regular";
    font-size: 11px;
    line-height: 20px;
    color: #707070;
  }
  .emailConfDisc div:first-child {
    font-family: "Inter-SemiBold2";
    font-size: 13px;
    line-height: 20px;
    color: #707070;
    margin-bottom: 2px;
  }
  .emailConfDisc a,
  .emailConfDisc a:hover {
    font-family: "Inter-SemiBold2";
    color: #707070;
    font-size: 11px;
    text-decoration: none;
  }
  @media only screen and (max-width: 767px) {
    .emailConfTxt {
      width: 100%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1230px) {
    .emailConfTxt {
      margin: 0 auto 45px;
      line-height: 22px;
    }
  }
  .emailC-cls-1 {
    fill: #92a2ab;
  }
  .emailC-cls-1,
  .emailC-cls-2,
  .emailC-cls-3,
  .emailC-cls-4,
  .emailC-cls-5,
  .emailC-cls-6,
  .emailC-cls-7 {
    fill-rule: evenodd;
  }
  .emailC-cls-2 {
    fill: #6b777e;
  }
  .emailC-cls-3 {
    fill: #81929b;
  }
  .emailC-cls-4 {
    fill: #40484c;
  }
  .emailC-cls-5 {
    fill: #a4b3bc;
  }
  .emailC-cls-6 {
    fill: #fbc800;
  }
  .emailC-cls-7 {
    fill: #c59e00;
  }

  .fadeOut {
    opacity: 0;
    transition: opacity 0.5s;
  }
  .fadeIn {
    opacity: 1;
    transition: opacity 0.5s 0.5s;
  }
`;
