import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { ConfirmButton } from "../";
import { Modal } from "react-bootstrap";
// import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

export const PlanModal = ({ data, show, onHide, isProceed }) => {
  const history = useHistory();
  const {
    ic_name,
    ic_logo,
    policy_wording,
    ic_tagline,
    gst_amount,
    plan_features,
    final_premium,
    sum_insured,
    id,
    ic_id
  } = data;
  const comma = (string) => {
    if (string) return string.toLocaleString("en-IN");
  };
  // const { register, handleSubmit, errors } = useForm();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const { searchQuery } = useSelector((state) => state.greeting);

  const onSubmit = (data) => {
    history.push(`/detail-form/${id}?enquiry_id=${enquiry_id}${searchQuery ? '&' + searchQuery : ''}`);
  };
  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header className="border-0 pb-0">
        <div className="review-details w-100">
          <CloseBtn type="button" onClick={onHide} className="float-right">
            &times;
          </CloseBtn>
          <div>
            <img src="/assets/image/laxmiImg.png" alt="" />
          </div>
          <h2>Great Choice!</h2>
          <h1>
            Take a <span style={{ fontWeight: "bold" }}>minute</span> to review
            details
          </h1>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0 modelPadding">
        {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
        <div className="premium-left">
          <div className="insurance">
            <img src={ic_logo} alt="" />
            <p>
              {ic_name}
              <small>{ic_tagline}</small>
            </p>
          </div>
          <div className="premium">
            <ul className="text-center">
              <li>
                Sum Assured : <b>₹</b>
                <span style={{ fontWeight: "700", fontSize: "14px" }}>
                  {comma(sum_insured)}
                </span>{" "}
              </li>
            </ul>
            <ul
              style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
              className="text-center"
            >
              <li>
                Premium : <b>₹</b>
                <span style={{ fontWeight: "700", fontSize: "14px" }}>
                  {comma(final_premium)}
                </span>{" "}
              </li>
              <li>+</li>
              <li>
                GST(18%) : <b>₹</b>
                <span style={{ fontWeight: "700", fontSize: "14px" }}>
                  {comma(gst_amount)}
                </span>{" "}
              </li>
            </ul>
          </div>
          <div className="fullwidth total">
            <div className="premium-amt">
              <p>Total Premium</p>
            </div>
            <div className="amt">
              <b style={{ fontSize: "20px", fontWeight: "normal" }}>₹</b>
              <span style={{ fontWeight: "700" }}>
                {comma(final_premium + gst_amount)}
              </span>
            </div>
          </div>
          <div className="fullwidth vehicle">
            <div className="details">
              <p>What’s Covered?</p>
            </div>
            <div className="vehicle-inner fullwidth add-on">
              <ul style={{ display: "flex", flexWrap: "wrap" }}>
                {plan_features?.map((list, index) => (
                  <li
                    className={(list.id !== 19 || ic_id === 2) ? '' : 'disabled'}
                    key={`list${index}`}
                  >
                    {list.feature_name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="declare" style={{ clear: "both" }}>
            <p>
              For more details on policy terms, conditions, exclusions,
                limitations, please refer to Policy Wording{" "}
              <a
                target="_blank"
                href={policy_wording}
                rel="noopener noreferrer"
              >
                click here
                </a>
            </p>
            {/* <p>
                <input
                  type="checkbox"
                  defaultChecked
                  id="dec"
                  name="confirm"
                  aria-invalid={errors.firstName ? "true" : "false"}
                  ref={register({ required: true })}
                />
                <span
                  style={{ fontWeight: "normal" }}
                  className={errors.confirm ? "text-danger" : ""}
                >
                  &nbsp;Disclaimer: I confirm all the details are correct and
                  accurate. I’m aware the insurer reserves its right to
                  repudiate a claim if the declaration made by me is found to be
                  incorrect.
                </span>
              </p> */}
          </div>
          <div className="confirmBtn initiate">
            {isProceed ? (
              <ConfirmButton style={{ height: "40px" }} type="button" onClick={onSubmit}>
                Confirm and Pay
              </ConfirmButton>
            ) : (
              <ConfirmButton style={{ height: "60px" }} type="button" onClick={onSubmit}>
                Confirm and Proceed
              </ConfirmButton>
            )}
          </div>
        </div>
        {/* </Form> */}
      </Modal.Body>
    </Modal>
  );
};

// PropTypes
PlanModal.propTypes = {
  data: PropTypes.shape({
    ic_name: PropTypes.string,
    ic_logo: PropTypes.string,
    policy_wording: PropTypes.string,
    ic_tagline: PropTypes.string,
    gst_amount: PropTypes.number,
    plan_features: PropTypes.array,
    final_premium: PropTypes.number,
    sum_insured: PropTypes.number,
    id: PropTypes.number,
    master_plan: PropTypes.string,
    master_plan_id: PropTypes.number,
    ic_id: PropTypes.number,
  }),
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

// DefaultTypes
PlanModal.defaultProps = {
  data: {
    ic_name: "Database Issue",
    ic_logo: "assets/image/avtar.png",
    policy_wording: "https://www.google.com/",
    ic_tagline: "Sorry for convinence",
    gst_amount: 0,
    plan_features: [],
    final_premium: 0,
    sum_insured: 0,
    index: 0,
    id: 0,
    master_plan: "-",
    master_plan_id: 0,
    ic_id: 0,
  },
  show: false,
  onHide: () => { },
};

const CloseBtn = styled(ConfirmButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  border-radius: 100%;
  background-color: #18478b;
  color: #fff;
  opacity: 1;
  padding: 0;
  font-weight: normal;
  font-size: 24px;
  text-shadow: none;
`;
