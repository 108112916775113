
export const useQuery = search => {
    return new URLSearchParams(search);
};

export const getFirstError = errors => {
    const keys = Object.keys(errors);
    const error = keys && keys.length > 0 ? errors[keys[0]] : '';
    return error && error.length > 0 ? error[0] : '';
};

export const processData = (data) => {
    if (!data) return data;
    const dataStr = JSON.stringify(data);
    dataStr.replace(/true/g, 1);
    dataStr.replace(/false/g, 0);
    return JSON.parse(dataStr);
};

export const checkBool = bool => {
    return typeof bool === 'boolean' ||
        (typeof bool === 'object' &&
            bool !== null &&
            typeof bool.valueOf() === 'boolean');
};

export const downloadFile = (url, options) => {
    const link = document.createElement('a');
    if (options)
        link.setAttribute('href', `options${encodeURIComponent(url)}`);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const validateForm = (data) => {
    let errors = {};
    let formIsValid = true;

    // First Name
    if (!data.firstname) {
        formIsValid = false;
        errors.firstname = "*Please enter firstname";
    }

    if (typeof data.firstname !== "undefined") {
        if (!data.firstname.match(/^[a-zA-Z ]*$/)) {
            formIsValid = false;
            errors.firstname = "*Please enter alphabet characters only";
        }
    }

    // Last Name
    if (!data.lastname) {
        formIsValid = false;
        errors.lastname = "*Please enter lastname";
    }

    if (typeof data.lastname !== "undefined") {
        if (!data.lastname.match(/^[a-zA-Z ]*$/)) {
            formIsValid = false;
            errors.lastname = "*Please enter alphabet characters only";
        }
    }

    // Email
    if (!data.email) {
        formIsValid = false;
        errors.email = "*Please enter email-ID";
    }

    if (typeof data.email !== "undefined") {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(data.email)) {
            formIsValid = false;
            errors.email = "*Please enter valid email-ID";
        }
    }

    // Mobile Number
    if (!data.mobile_no) {
        formIsValid = false;
        errors.mobile_no = "*Please enter mobile no";
    }

    if (typeof data.mobile_no !== "undefined") {
        if (!data.mobile_no.match(/^[0-9]{10}$/)) {
            formIsValid = false;
            errors.mobile_no = "*Please enter valid mobile no";
        }
    }


    return {
        errors,
        formIsValid
    }
}

export const _capitalizeText = (string) =>
    string.replace(/(^|\s)\S/g, l => l.toUpperCase())

export const lowerCase = (e) =>
(e.target.value =
    e.target.value.length > 0
        ? ("" + e.target.value).toLowerCase()
        : e.target.value);

// export const getDomain = (email) => email.substring(email.lastIndexOf("@") + 1);
