import React from "react";
import "./footer.scss";

export const Footer = ({ showCrafted }) => {
  return (
    <footer className="container bottom" style={{ marginTop:"20px"}}>
      <div className="row">
        <div className="col-md-12" style={{marginTop:"15px" ,color:"#111",fontWeight:400, fontSize:!showCrafted&&"11px"}}>
          <span>CIN No. U67200MH2003PTC141621</span>
          <span>{showCrafted?"License Code No. CB219/03" :"License Code No. 217"}</span>
          <span>Composite Broker</span>
          {/* <div style={{display:showCrafted?"inline-block":"none"}} className="footer__right">
          {showCrafted && (
            <span className="footer-span">
              Crafted By
              <img
                src={require("../../assets/images/pbc-logo.png")}
                alt="imgLogo"
                width="20"
                height="20"
              />
            </span>
          )}
        </div> */}
        </div>
      </div>
    </footer>
  );
};
