import { createSlice } from "@reduxjs/toolkit";
import {
  plan,
  savePlan,
  region,
  list,
  tracking,
  savePersonal,
  trackingUpdate,
  getPersonal,
  getAllDetails,
  saveSecurity,
  getSecurity,
  journeyUpdate
} from "./serviceApi";
import { load_user } from "../plans/plans.slice";

export const detailsform = createSlice({
  name: "detailsform",
  initialState: {
    response: {},
    savePlanresponse: {},
    regionresponse: {},
    regionresponse2: {},
    listresponse: {},
    trackingresponse: {},
    alert: null,
    personal: {},
    loading: null,
    security: {},
    personalResponse: null,
  },

  //reducers
  reducers: {
    planDetails: (state, action) => {
      state.response = action.payload;
    },
    savePlanDetails: (state, action) => {
      state.savePlanresponse = action.payload;
      state.loading = null;
    },
    regionDetails: (state, action) => {
      state.regionresponse = action.payload;
    },
    regionDetails2: (state, action) => {
      state.regionresponse2 = action.payload;
    },
    clearRegionDetails: (state) => {
      state.regionresponse = {};
    },
    clearRegionDetails2: (state) => {
      state.regionresponse2 = {};
    },
    listDetails: (state, action) => {
      state.listresponse = action.payload;
    },
    trackLog: (state, action) => {
      state.trackingresponse = action.payload;
    },
    alertMessage: (state, action) => {
      state.alert = action.payload;
    },
    clearAlertMessage: (state, action) => {
      state.alert = null;
    },
    loading: (state, action) => {
      state.loading = true;
    },
    clearLoading: (state, action) => {
      state.loading = null;
    },
    personalDetails: (state, { payload }) => {
      state.personal = payload;
    },
    allDetails: (state, { payload }) => {
      state.allDetails = payload;
    },
    personalResponse: (state, { payload }) => {
      state.personalResponse = payload;
    },
    securityDetails: (state, { payload }) => {
      state.security = payload;
    },
  },
});

export const {
  planDetails,
  personalDetails,
  alertMessage,
  clearAlertMessage,
  savePlanDetails,
  regionDetails,
  regionDetails2,
  clearRegionDetails,
  clearRegionDetails2,
  listDetails,
  trackLog,
  loading,
  clearLoading,
  allDetails,
  personalResponse,
  securityDetails,
} = detailsform.actions;

//action Creators
export const planData = (data) => {
  return async (dispatch) => {
    try {
      const response = await plan(data);
      if (response?.data) {
        dispatch(planDetails(response));
      }
      else {
        dispatch(alertMessage(response?.message || "Something went wrong"));
      }
    }
    catch (err) {
      dispatch(alertMessage("Something went wrong"));
    }
  };
};

export const savePlanData = (data) => {
  return async (dispatch) => {
    try {
      dispatch(loading());
      const savePlanresponse = await savePlan(data);
      if (savePlanresponse?.data) {
        dispatch(savePlanDetails(savePlanresponse));
      }
      else if (!savePlanresponse?.status) {
        dispatch(
          alertMessage(savePlanresponse?.message || "Something went wrong")
        );
      }
    }
    catch (err) {
      dispatch(alertMessage("Something went wrong"));
    }
  };
};

export const getRegion = (data) => {
  return async (dispatch) => {
    try {
      const regionresponse = await region(data);
      if (regionresponse?.data) {
        dispatch(regionDetails(regionresponse));
      }
      else {
        dispatch(
          alertMessage(regionresponse?.message || "Something went wrong")
        );
      }
    }
    catch (err) {
      dispatch(alertMessage("Something went wrong"));
    }
  };
};

export const getRegion2 = (data) => {
  return async (dispatch) => {
    try {
      const regionresponse = await region(data);
      if (regionresponse?.data) {
        dispatch(regionDetails2(regionresponse));
      }
      else {
        dispatch(
          alertMessage(regionresponse?.message || "Something went wrong")
        );
      }
    }
    catch (err) {
      dispatch(alertMessage("Something went wrong"));
    }
  };
};

export const getList = () => {
  return async (dispatch) => {
    try {
      const listresponse = await list();
      if (listresponse?.data) {
        dispatch(listDetails(listresponse));
      }
      else {
        dispatch(alertMessage(listresponse?.message || "Something went wrong"));
      }
    }
    catch (err) {
      dispatch(alertMessage("Something went wrong"));
    }
  };
};

export const trackProgress = (data) => {
  return async (dispatch) => {
    try {
      const trackingresponse = await tracking(data);
      if (trackingresponse?.data) {
        dispatch(trackLog(trackingresponse));
      }
      else {
        console.error("Tracking failed");
      }
    }
    catch (err) {
      console.error("Tracking failed");
    }
  };
};

export const savePersonalDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await savePersonal(data);
      dispatch(personalResponse(response));

      dispatch(
        load_user({
          firstname: data.fname,
          lastname: data.lname,
          mobile_no: data.mobile_no,
          email: data.email,
        })
      );
    }
    catch (error) {
      console.error(error);
    }
  };
};

export const saveSecurityDetails = (data) => {
  return async (dispatch) => {
    try {
      await saveSecurity(data);
    }
    catch (error) {
      console.error(error);
    }
  };
};

export const updateProgress = (data) => {
  return async (dispatch) => {
    try {
      const trackingresponse = await trackingUpdate(data);
      if (trackingresponse?.data) {
        dispatch(trackLog(trackingresponse));
      }
      else {
        console.error("Tracking failed");
      }
    }
    catch (err) {
      console.error("Tracking failed");
    }
  };
};

export const getSecurityDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await getSecurity(data);
      if (response?.data) dispatch(securityDetails(response));
    }
    catch (err) {
      console.error("Failed to get Details");
    }
  };
};

export const getPers = (data) => {
  return async (dispatch) => {
    try {
      const response = await getPersonal(data);
      if (response?.data) dispatch(personalDetails(response));
    }
    catch (err) {
      console.error("Failed to get Details");
    }
  };
};
export const getAll = (data) => {
  return async (dispatch) => {
    try {
      const response = await getAllDetails(data);
      if (response?.data) dispatch(allDetails(response));
    }
    catch (err) {
      console.error("Failed to get Details");
    }
  };
};

// journey update
export const updateJourney = (data) => {
  return async (dispatch) => {
    try {
      journeyUpdate(data);
    }
    catch (err) {
      console.error("Failed to get Details", err);
    }
  };
};

//reducer export
export default detailsform.reducer;
