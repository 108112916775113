import { useSelector, useDispatch } from "react-redux";
import React, { Fragment, useState, useEffect } from "react";
import Popup from "./Popup";
import styled from "styled-components";
import { TextInput, Label, FormGroup } from "../Form/Form";
import { useForm } from "react-hook-form";
import { mailSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers";
// import { mailToExpert } from "../../modules/Landing/landing.slice";
import { sendEmail, updateProgress } from "../../modules/plans/plans.slice";
// import { setToken } from '../../modules/Landing/landing.slice'
// import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router";

const CallMe = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(true);
  const { email, compare } = useSelector((state) => state.plans);
  // const { compare } = useSelector(state => state.plans)
  const [emailRef, setemailRef] = useState();
  const plans = useSelector(state => state.plans);
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(mailSchema),
  });
  const [length, setLength] = useState(0);
  const [msg, setMsg] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const Lead_Source = query.get("utm_source");
  const Lead_Medium = query.get("utm_medium");
  const Campaign_Name = query.get("utm_campaign");


  // const numOnly = (event) => {
  //   if (!(event.which > 47 && event.which < 58) || event.which == 13)
  //     event.preventDefault();
  // };

  const CompareClick = () => {
    // if (compare.length <= 1) 
    // {
    //   swal(
    //     "",
    //     "Select Atleast 2 Insurance to Compare",
    //     "info"
    //   );
    // } 
    // else 
    // {
    dispatch(updateProgress({
      has_seen_compare: 1,
      user_journey_id: enquiry_id,
      compare: compare,
      Lead_Source,
      Lead_Medium,
      Campaign_Name
    }));
    // }
  }

  const data = {
    email: plans.user.email,
    mobile_no: plans.user.mobile_no,
    name: `${plans.user.firstname} ${plans.user.lastname}`,
    // sum_insured : plans.sendDetails.sum_assured,
    // plan_type : plans.sendDetails.plan_type,
    fName: plans.user.firstname,
    plan: compare,
    user_journey_id: enquiry_id,
  }

  const attachRef = (ref) => {
    register(ref);
    setemailRef(ref);
    if (emailRef && email && flag) {
      emailRef.value = email;
      setFlag(false);
      getLength();
    }
  };

  const getLength = () => {
    setLength(emailRef.value.length);
  };

  const handleChange = () => {
    setFlag(false);
    getLength();
  };

  useEffect(() => {
    if (flag === false) {
      setFlag(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmit = (data) => {
    dispatch(
      sendEmail({
        // mobile_no: data.email,
        // tracking_id: id,
        // product_name: "Cyber",
        // user_journey_id: id,
        // firstname: user.firstname,
        // lastname: user.lastname,
        // email: user.email,
        // name: `${user.firstname} ${user.lastname}`,
        email: data.email,
        mobile_no: plans.user.mobile_no,
        name: `${plans.user.firstname} ${plans.user.lastname}`,
        sum_insured: plans.sendDetails.sum_assured,
        plan_type: plans.sendDetails.plan_type,
        user_journey_id: enquiry_id,
        plan: compare,
        url: window.location.href
      })
    );
    setMsg(true);
    setTimeout(() => {
      onClose(false);
      setMsg(false);
    }, 2000);
  };
  const content2 = (
    <MessageContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#4fcc6e"
        width="48px"
        height="48px"
      >
        <path d="M0 0h24v24H0z" fill="none"></path>
        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
      </svg>
      <CallText>
        Your comparison have been successfully sent to your email id.
      </CallText>
    </MessageContainer>
  );
  const content1 = (
    <Fragment>
      <Content1>
        <div className="contact__imgbox">
          <ContactImg
            src="/assets/image/laxmiImg.png"
            alt="Lakshmi"
          >
          </ContactImg>
        </div>
        <ContactText>
          Hi {data.fName}, comparison of life insurance plans will be shared with you shortly.
        </ContactText>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormWrapper>
            <FormGroup lg>
              <TextInput
                lg
                type="email"
                id="email"
                name="email"
                placeholder=" "
                ref={attachRef}
                onChange={handleChange}
                error={errors?.email && "danger"}
              />
              <Label lg htmlFor="email">
                Email ID
              </Label>
              {errors.email && <p className="errorG">{errors.email.message}</p>}
            </FormGroup>
            <FormGroup sm style={{ width: "26%" }}>
              <Button active={errors.email || length < 10 ? true : false}
                // onClick={()=>{dispatch(sendEmail(data)); onClose(false);}}
                onClick={CompareClick}
              >
                SEND
              </Button>
            </FormGroup>
          </FormWrapper>
        </form>
      </Content1>
      <Content2>
        <p>*Please note that the premium may vary in future.</p>
      </Content2>
    </Fragment>
  );
  return (
    <Fragment>
      <Popup
        height={msg ? "200px" : "340px"}
        width="640px"
        show={show}
        onClose={onClose}
        content={msg ? content2 : content1}
        position="middle"
      >
      </Popup>
    </Fragment>
  );
};

export default CallMe;

const CallText = styled.div`
  width: 340px;
  margin: 7px auto 37px;
  text-align: center;
  font-family: "basier_squareregular";
  font-size: 16px;
  color: #000000;
`;

const MessageContainer = styled.div`
  padding: 10px;
  & svg {
    margin: 0 auto;
    width: 100%;
  }
`;

// const ConfirmButton = styled.button`
// background-color:#ffc000;
// display:block;
// margin:25px auto;
// font-size: 15px;
// color: #000;
// padding:12px 40px;
// font-weight: bold;
// border-radius: 5px;
// border:none;
// `;

const Content1 = styled.div`
  height: 90%;
  padding: 60px 80px;
  @media (max-width: 768px) {
    padding: 50px 8px;
  }
`;

const Content2 = styled.div`
  & p {
    // color: #000000;
    // font-family: "basier_squaremedium";
    font-size: 14px;
    // font-weight: 500;
  }
  height: 20%;
  padding: 23px 0;
  background-color: #f1f2f6;
  text-align: center;
`;

const ContactImg = styled.img`
  float: left;
  margin-right: 10px;
  height: 70px;
  border: solid 1px #d2d3d4;
  border-radius: 50%
`;

const ContactText = styled.div`
  padding: 1rem;
  font-weight: 400;
  font-family: basier_squareregular;
  font-size: 15px;
  margin-bottom: 20px;
  color: #111;
  text-align: justify;
`;

const Button = styled.button`
  &:disabled {
    background-color: ;
    color: #111;
    border: solid 1px #d2d3d4;
  }
  display: inline-block;
  border-radius: 5px;
  padding: 0px 25px;
  box-sizing: content-box;
  font-size: 17px;
  background-color: ${(props) => (props.active ? "#dfe3e8" : "#f2581b")};
  font-weight: 600;
  border: none;
  color: ${(props) => (props.active ? "#111" : "#fff")};
  height: 60px;
  border: ${(props) => props.active && "solid 1px #d2d3d4"};
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
`;