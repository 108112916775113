import React from 'react';
import Route from "./routes";
import './css/select2.min.css'
import './css/style.css';
import './css/responsive.css';
import './css/elephant.css';

const App = () => {
  return <Route/>

}

export default App;
