import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import {
  Plans,
  DetailsForm,
  CompareNew1,
  FormValidation,
  Landing
} from "./modules";
import { Error, Success, LandingNavbar } from "./components";
import { Loader, Error404 } from "./components";

const Router = () => {



  return (
    <BrowserRouter>
      <LandingNavbar isGreetings />
      <Switch>
        <Route exact path="/plans" component={Plans} />
        <Route exact path="/" component={Landing} />
        <Route exact path="/detail-form/:id" component={DetailsForm} />
        <Route exact path="/loader" component={Loader} />
        {/* <Route exact path="/compare/:id/:sumInsure/:planType" component={CompareOld} /> */}
        <Route exact path="/compare/:id/:sumInsure/:planType" component={CompareNew1} />
        <Route exact path="/test" component={FormValidation} />
        <Route exact path="/success" component={Success} />
        <Route exact path="/error" component={Error} />
        {/* <Redirect to='/' /> */}
        <Route exact path="/404" component={Error404} />
        <Redirect to='/404' />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
