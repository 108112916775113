import React, { Fragment } from "react";
import styled, { keyframes } from "styled-components";

const Popup = ({ show, onClose, content, height, width, position, forLandingPage }) => {
  console.log(
    "SAHIL", forLandingPage
  );
  return (
    <Fragment>
      {show && (
        <PopupC visible={show}>
          <Content style={{ height: height, width: width }} position={position} maxwidth={width}>
            {!forLandingPage && <CloseButton
              onClick={() => {
                onClose(false);
              }}
            >
              &times;
            </CloseButton>}
            
            {content}
          </Content>
        </PopupC>
      )}
    </Fragment>
  );
};

export default Popup;

const moveDown = keyframes`
from{
    top:0;
    opacity:0;
}
to {
    top:${props => props.position === "top" ? "20%" : "35%"};
    opacity:1;
}
`;

const PopupC = styled.div`
  min-height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  background-color: rgba(1, 1, 1, 0.6);
  z-index: 9999;
  opacity: ${(props) => (props.visible === true ? "1" : "0")};
  visibility: ${(props) => (props.visible === true ? "visible" : "hidden")};
  transition: all 0.3s; 
`;

const Content = styled.div`
  position: absolute;
  animation: ${moveDown} 0.5s;
  top:${props => props.position === "top" ? "12rem" : "35%"};
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: #fff;
  transition: all 0.5s;
  border-radius:4px;
  @media (max-width:${props => props.maxwidth}){
    width:96% !important;
    height:auto !important;
  }
`;

const CloseButton = styled.a`
  float: right;
  font-size: 32px;
  margin-right: 10px;
  cursor: pointer;
  &:link,
  &:visited {
    text-decoration: none;
    color: #666;
  }
`;
