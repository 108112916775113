import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createGlobalStyle } from "styled-components";
import "./footer.scss";

const FooterLanding = () => {
  const todayDate = new Date();
  const presentYear = todayDate.getFullYear();

  const [height1, setHeight1] = useState(0);
  const [height2, setHeight2] = useState(0);
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const { footerCompany } = useSelector((state) => state.greeting);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setTimeout(() => {
      setHeight1(ref1.current?.clientHeight);
      setHeight2(ref2.current?.clientHeight);
    }, 1);
  });

  return (
    <>
      {/* <div className="footerLanding" ref={ref1}>
        <div className="alignment">
          <div className="footerInline">
            <span>Category: </span>Composite Broker
          </div>
          <div className="footerInline">
            <span className="footerBold">CIN No. </span>U67200MH2003PTC141621
          </div>
          <div className="footerInline ">
            <span>IRDAI Registration No. </span>217
          </div>
          <div className="footerCopy footerInline">
            <span>Copyright &copy; {presentYear}</span> <span>Alliance Insurance Brokers Pvt.
              Ltd.</span>
          </div>
        </div>
      </div> */}
      {!!footerCompany && (
        <>
          <div class="benifitFooterWrap" ref={ref2}>
            <div class="container">
              <div class="row beniRow">
                <div class="benifitFooterName">
                  <svg
                    class="benifitSvg"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 32.02"
                  >
                    <path
                      class="cls-crown-1"
                      d="M35.11,15.36h0l0,0h0a.51.51,0,0,1-.24.07.43.43,0,0,1-.22-.06s0,0-.07-.05l-.1-.08a0,0,0,0,1,0,0h0L25,.68,15.62,15.24h0a0,0,0,0,1,0,0,.28.28,0,0,1-.11.08s0,0-.06.05a.43.43,0,0,1-.22.06A.48.48,0,0,1,15,15.4h0l0,0h0L2.15,5.19l4.2,20.3h37.3l4.2-20.3Z"
                    ></path>
                    <rect
                      class="cls-crown-1"
                      x="6.39"
                      y="26.49"
                      width="37.22"
                      height="5.17"
                    ></rect>
                  </svg>
                  <span class="benifitTxt">
                    <span class="benifitFooterNameTitle">{footerCompany} </span>
                    <span class="benifitFooterNameSubTitle">
                      is a preferred partner organisation at Elephant.in
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <GlobalStyle height1={height1} height2={height2} />
        </>
      )}
    </>
  );
};

const GlobalStyle = createGlobalStyle`
body{
  padding-bottom: ${({ height2 }) => height2 + 60 + "px"} !important;
}
.benifitFooterWrap{
  bottom: ${({ height1 }) => height1 + "px"} !important;
}
.footerLanding{
  /* bottom: ${({ height2 }) => height2 + "px"} !important; */
  position: fixed;
}
.cmnTerms{
  bottom: ${({ height2, height1 }) => height2 + height1 + 5 + "px"} !important;
}

@media only screen and(min-width: 767px) and (max-width: 992px) {
  .benifitFooterWrap{
  bottom: ${({ height1 }) => height1 + 36 + "px"} !important;
  }
  .cmnTerms{
    bottom: ${({ height2, height1 }) => height2 + height1 + "px"} !important;
  }
}
@media only screen and(min-width: 340px) and (max-width: 767px) {
  .benifitFooterWrap{
  bottom: ${({ height1 }) => height1 + 16 + "px"} !important;
  }
  .cmnTerms{
    bottom: ${({ height2, height1 }) =>
      height2 + height1 + 5 + "px"} !important;
  }
}
@media only screen and (max-width: 340px) {
  .benifitFooterWrap{
  bottom: ${({ height1 }) => height1 + "px"} !important;
  }
  .cmnTerms{
    bottom: ${({ height2, height1 }) =>
      height2 + height1 + 5 + "px"} !important;
  }
}
`;

export default FooterLanding;
