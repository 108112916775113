import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

import { Form, Row } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import { useForm, Controller } from "react-hook-form";
import { Button } from "../../components";
import {
  getRegion,
  getRegion2,
  clearRegionDetails,
  clearRegionDetails2,
} from "./details.slice";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import styled from "styled-components";
import { numOnly } from "./utils";
import swal from "sweetalert";
import moment from "moment";
import DatePicker from "react-datepicker";
import { getYear, getMonth } from "date-fns";
import doubleArrow from "../../assets/images/double-arrow.svg";
import "react-datepicker/dist/react-datepicker.css";
import "./formFile.scss";
import _, { range } from "lodash";

const validationSchema = (hdfc, alternateAddress) =>
  yup.object({
    address: yup.string().required("Please enter your address"),
    address2: yup.string().required("Please enter your address"),
    pincode: yup
      .string()
      .required("Please enter your pincode")
      .matches(/^[1-9]{1}[0-9]{5}$/, "Please enter a valid pincode"),
    // state: yup
    //   .string()
    //   .required("Please select your state"),
    // city: yup
    //   .string()
    //   .required("Please enter your city"),
    ...(hdfc
      ? {
          source: yup.string().required("Please select your income source"),
        }
      : {
          ...(alternateAddress && {
            mailaddress1: yup.string().required("Please enter your address"),
            mailaddress2: yup.string().required("Please enter your address"),
            mailpincode: yup
              .string()
              .required("Please enter your pincode")
              .matches(/^[1-9]{1}[0-9]{5}$/, "Please enter a valid pincode"),
          }),
        }),
    start_date: yup.string().required("Please select your policy start date"),
  });

const FormComponent = ({
  formData,
  pinCode,
  validate,
  onSubmit,
  getEndDate,
  getPincode,
  ic_id,
}) => {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.detailsform);

  //states
  const [getpincode, setpincode] = useState(pinCode || "");
  const [getpincode2, setpincode2] = useState(formData.mailpincode || "");
  const [toggle, setToggler] = useState(formData?.toggle || false);
  const [pep, setPep] = useState(false);
  const [dateAlert, setDateAlert] = useState(false);
  const minDate = moment()._d;
  const [startDate, setStartDate] = useState(null);
  const { handleSubmit, control, register, reset, errors, setValue } = useForm({
    defaultValues: !_.isEmpty(formData) ? formData : {},
    resolver: yupResolver(validationSchema(ic_id === 2, toggle)),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  // const {
  //   handleSubmit: handleSubmit2,
  //   register: register2,
  //   reset: reset2,
  // } = useForm({
  //   defaultValues: !_.isEmpty(formData) ? formData : "",
  // });
  useEffect(() => {
    setpincode(String(formData.pincode));
    setPep(formData.pep === 1 ? true : false);

    reset({ pep: formData.pep === 1 ? true : false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const endDate = startDate
    ? moment(moment(startDate).format("DD/MM/YYYY"), "DD/MM/YYYY")
        // .add(1, "years")
        .add(364, "days")
        .format("DD/MM/YYYY")
    : formData?.endDate;
  const years = range(getYear(new Date(minDate)), 2030 + 1, 1);
  const months = moment.months();

  useEffect(() => {
    if (formData.start_date) {
      setStartDate(new Date(moment(formData.start_date, "YYYY-MM-DD")));
    }
  }, [formData.start_date]);
  useEffect(() => {
    if (dateAlert) {
      setDateAlert(false);
      swal("Oops", "Policy Start Date should not be more than 90days", "error");
      setStartDate(new Date());
    }
  }, [dateAlert]);

  useEffect(() => {
    const days = moment(startDate).diff(moment(), "days");
    if (days > 90) {
      setDateAlert(true);
    }

    if (startDate) {
      setValue("start_date", startDate, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  //api call for state city------------------
  useEffect(() => {
    if (getpincode?.length === 6) {
      let data = { pincode: getpincode };
      dispatch(getRegion(data));
    } else {
      dispatch(clearRegionDetails());
    }
  }, [getpincode, dispatch]);

  //api call for state2 city2------------------
  useEffect(() => {
    if (getpincode2?.length === 6) {
      let data = { pincode: getpincode2 };
      dispatch(getRegion2(data));
    } else {
      dispatch(clearRegionDetails2());
    }
  }, [getpincode2, dispatch]);
  //-----------------------------------------
  const TestSubmitBajaj = (data) => {
    getEndDate(moment(endDate, "DD/MM/YYYY").format("DD-MM-YYYY"));
    const x = startDate
      ? moment(startDate, "MM/DD/YYYY").format("DD/MM/YYYY")
      : formData.start_date
      ? moment(formData.start_date, "DD-MM-YYYY").format("DD/MM/YYYY")
      : "";
    const start_date = moment(x, "DD/MM/YYYY").format("YYYY-MM-DD");
    const newData = {
      ...data,
      toggle,
      start_date,
      endDate,
      pincode: getpincode,
      mailstate: response?.regionresponse2?.data?.state,
    };
    onSubmit(newData);
  };

  const TestSubmitHdfc = (data) => {
    getEndDate(endDate);
    const x = startDate
      ? moment(startDate, "MM/DD/YYYY").format("DD/MM/YYYY")
      : formData.start_date
      ? moment(formData.start_date, "DD-MM-YYYY").format("DD/MM/YYYY")
      : "";
    const start_date = moment(x, "DD/MM/YYYY").format("YYYY-MM-DD");
    getPincode(getpincode);
    delete data.address2;
    const newData = {
      ...data,
      pep: data.pep ? 1 : 0,
      start_date,
      endDate,
      pincode: getpincode,
      state: response?.regionresponse?.data?.state,
      city: response?.regionresponse?.data?.city,
    };
    onSubmit(newData);
  };

  return (
    <>
      {ic_id === 2 ? (
        //hdfc
        <FormTag
          style={{ width: `100%` }}
          noValidate
          onSubmit={handleSubmit(TestSubmitHdfc)}
        >
          <Row xs={1} sm={1} md={1} lg={3} xl={3}>
            <div className="px-2" style={{ padding: "0 10px" }}>
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }}>
                  Address line1
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="90"
                  minLength="3"
                  required
                  pattern="[^'\x22]+"
                  title="Invalid input"
                  name="address"
                  ref={register}
                  defaultValue={formData.address}
                />
                {validate?.errors?.address || errors?.address ? (
                  <p className="error">
                    {validate?.errors?.address || errors?.address?.message}
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
            <div className="px-2" style={{ padding: "0 10px" }}>
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }}>Pin-code</Form.Label>
                <Form.Control
                  required
                  type="text"
                  minlength="6"
                  name="pincode"
                  maxlength="6"
                  pattern="[0-9]{6}"
                  title="Pincode must be in number"
                  // value={pinCode}
                  onKeyPress={numOnly}
                  ref={register}
                  onChange={(selected) => {
                    setpincode(selected.target.value);
                    getPincode(selected.target.value);
                    return selected.target.value;
                  }}
                  defaultValue={formData.pincode}
                />
                {validate?.errors?.pincode || errors?.pincode ? (
                  <p className="error">
                    {validate?.errors?.pincode || errors?.pincode?.message}
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
            <div className="px-2" style={{ padding: "0 10px" }}>
              <Form.Group
                controlId="exampleForm.SelectCustom"
                className="select-custom"
              >
                <Form.Label style={{ fontSize: "14px" }}>State</Form.Label>
                <Form.Control name="state" as="select" required ref={register}>
                  <option disable selected="true">
                    {response?.regionresponse?.data?.state || "N/A"}
                  </option>
                </Form.Control>
                {validate?.errors?.pincode ||
                errors?.pincode ||
                validate?.errors?.state ? (
                  <p className="error">
                    {validate?.errors?.state || "Please select your state"}
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
          </Row>
          <Row xs={1} sm={1} md={1} lg={3} xl={3}>
            <div className="px-2" style={{ padding: "0 10px" }}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label style={{ fontSize: "14px" }}>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  ref={register}
                  value={response?.regionresponse?.data?.city || ""}
                  required
                ></Form.Control>
                {validate?.errors?.pincode ||
                errors?.pincode ||
                validate?.errors?.city ? (
                  <p className="error">
                    {validate?.errors?.city || "Please enter your city"}
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
            <div
              className="col-12 col-sm-12 col-lg-6 py-1 px-2"
              style={{ padding: "0 10px" }}
            >
              <div className="form-group">
                <label style={{ fontSize: "14px" }} className="px-2">
                  PEP status
                </label>
                <div className="toggleRadio py-2 px-0">
                  <label
                    htmlFor="pep"
                    className="selectLabel"
                    style={{
                      fontWeight: pep ? "normal" : "bolder",
                      color: pep ? "#777" : "#004b83",
                    }}
                  >
                    No
                  </label>
                  <label className="switch">
                    <input
                      className="pep"
                      name="pep"
                      type="checkbox"
                      onClick={(event) => {
                        setPep(event.target.checked);
                      }}
                      ref={register}
                    />
                    <span className="slider round"></span>
                  </label>
                  <label
                    htmlFor="pep"
                    className="selectLabel"
                    style={{
                      fontWeight: pep ? "bolder" : "normal",
                      color: pep ? "#004b83" : "#777",
                    }}
                  >
                    Yes
                  </label>
                </div>
              </div>
            </div>
          </Row>
          <Row xs={1} sm={1} md={1} lg={2} xl={2}>
            <div className="px-2" style={{ padding: "0 10px" }}>
              <Form.Group
                controlId="exampleForm.SelectCustom"
                className="select-custom"
              >
                <Form.Label style={{ fontSize: "14px" }}>
                  Source Income
                </Form.Label>
                <Form.Control as="select" ref={register} name="source">
                  <option
                    style={{ display: "none" }}
                    selected={!formData.source && true}
                    disabled
                    hidden
                  >
                    Select
                  </option>
                  <option
                    selected={formData.source === "Salary" && true}
                    value="Salary"
                  >
                    Salary
                  </option>
                  <option
                    selected={formData.source === "Business" && true}
                    value="Business"
                  >
                    Business
                  </option>
                  <option
                    selected={formData.source === "Others" && true}
                    value="Others"
                  >
                    Others
                  </option>
                </Form.Control>
                {validate?.errors?.source || errors?.source ? (
                  <p className="error">
                    {validate?.errors?.source || errors?.source?.message}
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
            <div className="px-2" style={{ padding: "0 10px" }}>
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }}>
                  Policy Start Date
                </Form.Label>
                <Controller
                  as={
                    <Border className="rdp">
                      <DatePicker
                        autoComplete="off"
                        minDate={minDate}
                        format="dd/MM/yyyy"
                        ref={register}
                        name={"start_date"}
                        placeholderText="DD/MM/YYYY"
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div className="date-header">
                            <button
                              className="date-button-left"
                              type="button"
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                            >
                              <img
                                src={doubleArrow}
                                alt="double-arrow"
                                className="d-rotate"
                              />
                            </button>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) =>
                                changeYear(value)
                              }
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button
                              className="date-button-right"
                              type="button"
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                            >
                              <img src={doubleArrow} alt="double-arrow" />
                            </button>
                          </div>
                        )}
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          return date;
                        }}
                        value={
                          startDate
                            ? moment(startDate, "MM/DD/YYYY").format(
                                "DD/MM/YYYY"
                              )
                            : formData.start_date
                            ? moment(formData.start_date, "YYYY-MM-DD").format(
                                "DD/MM/YYYY"
                              )
                            : ""
                        }
                      />
                    </Border>
                  }
                  name={"start_date"}
                  control={control}
                />
                {validate?.errors?.start_date || errors?.start_date ? (
                  <p className="error">
                    {validate?.errors?.start_date ||
                      errors?.start_date?.message}
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
          </Row>
          <Row xs={1} sm={1} md={1} lg={2} xl={2}>
            <div className="px-2">
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }}>
                  Policy End Date
                </Form.Label>
                <Form.Control
                  type="input"
                  placeholder="DD/MM/YYYY"
                  disabled
                  defaultValue=""
                  value={endDate || endDate}
                />
              </Form.Group>
            </div>
          </Row>
          <Row style={{ justifyContent: "center", marginTop: "19px" }}>
            <Button
              style={{
                fontWeight: "400",
                fontSize: "15px",
                borderRadius: "5%",
                height: "35px",
                paddingTop: "5px",
              }}
              type="submit"
            >
              Review & Submit
            </Button>
          </Row>
        </FormTag>
      ) : (
        //bajaj--------------------------------------------------
        <FormTag
          style={{ width: `100%` }}
          noValidate
          onSubmit={handleSubmit(TestSubmitBajaj)}
          className="bajaj-address-form"
        >
          <Row xs={1} sm={1} md={1} lg={2} xl={2}>
            <div className="p-1">
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }}>
                  Address line1
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="90"
                  minLength="3"
                  required
                  pattern="[^'\x22]+"
                  title="Invalid input"
                  ref={register}
                  name="address"
                  defaultValue={formData.address}
                />
                {validate?.errors?.address || errors?.address ? (
                  <p className="error">
                    {validate?.errors?.address || errors?.address?.message}
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
            <div className="p-1">
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }}>
                  Address line2
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="90"
                  minLength="3"
                  required
                  pattern="[^'\x22]+"
                  title="Invalid input"
                  ref={register}
                  name="address2"
                  defaultValue={formData.address2}
                />
                {validate?.errors?.address2 || errors?.address2 ? (
                  <p className="error">
                    {validate?.errors?.address2 || errors?.address2?.message}
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
          </Row>
          <Row xs={1} sm={1} md={1} lg={3} xl={3}>
            <div className="px-2" style={{ padding: "0 10px" }}>
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }}>Pin-code</Form.Label>
                <Form.Control
                  required
                  type="text"
                  minlength="6"
                  name="pincode"
                  maxlength="6"
                  pattern="[0-9]{6}"
                  title="Pincode must be in number"
                  // value={pinCode}
                  onKeyPress={numOnly}
                  ref={register}
                  onChange={(selected) => {
                    setpincode(selected.target.value);
                    getPincode(selected.target.value);
                    return selected.target.value;
                  }}
                  defaultValue={formData.pincode}
                />
                {validate?.errors?.pincode || errors?.pincode ? (
                  <p className="error">
                    {validate?.errors?.pincode || errors?.pincode?.message}
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
            <div className="p-1">
              <Form.Group
                controlId="exampleForm.SelectCustom"
                className="select-custom"
              >
                <Form.Label style={{ fontSize: "14px" }}>State</Form.Label>
                <Form.Control name="state" as="select" required>
                  <option disable selected="true">
                    {response?.regionresponse?.data?.state || "N/A"}
                  </option>
                </Form.Control>
                {validate?.errors?.pincode ||
                errors?.pincode ||
                validate?.errors?.state ? (
                  <p className="error">
                    {validate?.errors?.state || "Please select your state"}
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
            <div className="p-1">
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label style={{ fontSize: "14px" }}>City</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="city"
                  ref={register}
                  value={response?.regionresponse?.data?.city || ""}
                ></Form.Control>
                {validate?.errors?.pincode ||
                errors?.pincode ||
                validate?.errors?.city ? (
                  <p className="error">
                    {validate?.errors?.city || "Please enter your city"}
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
          </Row>
          <Row>
            <div className="text-left pt-3">
              <div
                className="mr-2"
                style={{
                  fontSize: "13px",
                  marginLeft: "12px",
                  marginBottom: "10px",
                  marginTop: "-10px",
                }}
              >
                Mailing address is different
                ?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span
                  className={toggle ? `light` : `bold`}
                  style={{ fontSize: "16px" }}
                >
                  No
                </span>
                <Switch
                  checked={toggle}
                  onChange={(e) => {
                    setToggler(e.target.checked);
                  }}
                  color="primary"
                  defaultValue="No"
                />
                <span
                  className={toggle ? "bold" : "light"}
                  style={{ fontSize: "16px" }}
                >
                  Yes
                </span>
              </div>
            </div>
          </Row>
          {toggle && (
            <>
              <Row xs={1} sm={1} md={1} lg={2} xl={2}>
                <div className="p-1">
                  <Form.Group>
                    <Form.Label style={{ fontSize: "14px" }}>
                      Address line1
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength="90"
                      minLength="3"
                      required
                      name="mailaddress1"
                      ref={register}
                      pattern="[^'\x22]+"
                      defaultValue={formData.mailaddress1}
                    />
                    {!!(
                      validate?.errors?.mailaddress1 || errors?.mailaddress1
                    ) && (
                      <p className="error">
                        {validate?.errors?.mailaddress1 ||
                          errors?.mailaddress1?.message}
                      </p>
                    )}
                  </Form.Group>
                </div>
                <div className="p-1">
                  <Form.Group>
                    <Form.Label style={{ fontSize: "14px" }}>
                      Address line2
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength="90"
                      minLength="3"
                      required
                      ref={register}
                      name="mailaddress2"
                      pattern="[^'\x22]+"
                      defaultValue={formData.mailaddress2}
                    />
                    {!!(
                      validate?.errors?.mailaddress2 || errors?.mailaddress2
                    ) && (
                      <p className="error">
                        {validate?.errors?.mailaddress2 ||
                          errors?.mailaddress2?.message}
                      </p>
                    )}
                  </Form.Group>
                </div>
              </Row>
              <Row xs={1} sm={1} md={1} lg={3} xl={3}>
                <div className="p-1">
                  <Form.Group>
                    <Form.Label style={{ fontSize: "14px" }}>
                      Pin-code
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      minlength="6"
                      maxlength="6"
                      pattern="[0-9]{6}"
                      title="Pincode must be in number"
                      // value={getpincode2}
                      ref={register}
                      name="mailpincode"
                      onKeyPress={numOnly}
                      onChange={(selected) => {
                        setpincode2(selected.target.value);
                        return selected;
                      }}
                      defaultValue={formData.mailpincode}
                    />
                    {!!(
                      validate?.errors?.mailpincode || errors?.mailpincode
                    ) && (
                      <p className="error">
                        {validate?.errors?.mailpincode ||
                          errors?.mailpincode?.message}
                      </p>
                    )}
                  </Form.Group>
                </div>
                <div className="p-1">
                  <Form.Group controlId="exampleForm.SelectCustom">
                    <Form.Label style={{ fontSize: "14px" }}>State</Form.Label>
                    <Form.Control name="mailstate" as="select" required>
                      <option disable selected="true">
                        {response?.regionresponse2?.data?.state ||
                          formData.mailstate ||
                          "N/A"}
                      </option>
                    </Form.Control>
                    {validate?.errors?.mailpincode ||
                    errors?.mailpincode ||
                    validate?.errors?.mailstate ? (
                      <p className="error">
                        {validate?.errors?.mailstate ||
                          "Please select your state"}
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="p-1">
                  <Form.Group controlId="exampleForm.SelectCustom">
                    <Form.Label style={{ fontSize: "14px" }}>City</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      ref={register}
                      name="mailcity"
                      value={
                        response?.regionresponse2?.data?.city ||
                        formData.mailcity ||
                        ""
                      }
                    ></Form.Control>
                    {validate?.errors?.mailpincode ||
                    errors?.mailpincode ||
                    validate?.errors?.mailcity ? (
                      <p className="error">
                        {validate?.errors?.mailcity || "Please enter your city"}
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
              </Row>
            </>
          )}
          <Row>
            <div
              style={{
                color: "#004b83",
                marginLeft: "12px",
                fontSize: "17px",
                fontFamily: "Open Sans",
                paddingBottom: "10px",
                marginTop: "0px",
              }}
            >
              <b style={{ fontWeight: "700" }}>Policy Details</b>
            </div>
          </Row>
          <Row xs={1} sm={1} md={1} lg={2} xl={2}>
            <div className="p-1">
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }}>
                  Policy Start Date
                </Form.Label>
                <Controller
                  as={
                    <Border className="rdp">
                      <DatePicker
                        autoComplete="off"
                        minDate={minDate}
                        format="dd/MM/yyyy"
                        name={"start_date"}
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div className="date-header">
                            <button
                              className="date-button-left"
                              type="button"
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                            >
                              <img
                                src={doubleArrow}
                                alt="double-arrow"
                                className="d-rotate"
                              />
                            </button>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) =>
                                changeYear(value)
                              }
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button
                              className="date-button-right"
                              type="button"
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                            >
                              <img src={doubleArrow} alt="double-arrow" />
                            </button>
                          </div>
                        )}
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          return date;
                        }}
                        placeholderText="DD/MM/YYYY"
                        value={
                          startDate
                            ? moment(startDate, "MM/DD/YYYY").format(
                                "DD/MM/YYYY"
                              )
                            : formData.start_date
                            ? moment(formData.start_date, "YYYY-MM-DD").format(
                                "DD/MM/YYYY"
                              )
                            : ""
                        }
                      />
                    </Border>
                  }
                  name={"start_date"}
                  control={control}
                />
                {validate?.errors?.start_date || errors?.start_date ? (
                  <p className="error">
                    {validate?.errors?.start_date ||
                      errors?.start_date?.message}
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
            <div className="p-1">
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }}>
                  Policy End Date
                </Form.Label>
                <Form.Control
                  type="input"
                  placeholder="DD/MM/YYYY"
                  disabled
                  defaultValue=""
                  value={endDate}
                />
              </Form.Group>
            </div>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Button
              style={{
                fontWeight: "400",
                fontSize: "15px",
                borderRadius: "5%",
                height: "35px",
                paddingTop: "5px",
              }}
              type="submit"
            >
              Review & Submit
            </Button>
          </Row>
        </FormTag>
      )}
    </>
  );
};

const FormTag = styled(Form)`
  padding: 15px;
  @media (max-width: 480px) {
    padding: 5px;
    padding-left: 10px !important;
  }
`;

const Border = styled.span`
  .react-datepicker__input-container input {
    border: 1px solid #ced4da;
  }

  @-moz-document url-prefix() {
    .date {
      border: 1px solid #ced4da;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .react-datepicker__input-container input {
      border: 1px solid #ced4da;
      padding: 8px !important;
    }
  }
`;

export default FormComponent;
