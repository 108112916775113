/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import styled from "styled-components";
import { PlanCard, Footer } from "../../components";
import { InputCard } from "./input_box/InputCard";
import { Compare } from "./compare/Compare";
import { Info } from "./info/Info";
import { Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  set_compare_active,
  // loadUser,
  clear,
  loadAllFeatures,
} from "./plans.slice";
import { setToken } from "../Landing/landing.slice";
import SendQuotes from "../../components/Popup/SendQuotes";
import { useLocation, useHistory } from "react-router";
import { runScript } from "./plans.helper";

export const Plans = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { policies, features, user, loading, success, error } = useSelector(
    (state) => state.plans
  );
  const { searchQuery } = useSelector((state) => state.greeting);
  //reducer
  // const { wait } = useSelector((state) => state.greeting);
  const [modal, setModal] = useState(false);
  const [compare, setCompare] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const utm_source = query.get("utm_source");
  const x = enquiry_id;
  useEffect(() => {
    if (!enquiry_id) {
      swal("Oops", "No Quotes Id Found", "info").then(() => {
        history.replace(`/${searchQuery ? "?" + searchQuery : ""}`);
      });
    }
    dispatch(loadAllFeatures());
    dispatch(setToken({ user_journey_id: enquiry_id }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (utm_source) {
      runScript(utm_source);
    }
  }, []);

  const [quoteId, setQuoteId] = useState();
  useEffect(() => {
    setQuoteId(enquiry_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId, x]);

  useEffect(() => {
    dispatch(set_compare_active(compare));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compare]);

  useEffect(() => {
    if (!loading && error) {
      swal("Oops", error, "warning");
    }
    if (!loading && success) {
      swal("Done", success, "success");
    }

    return () => {
      dispatch(clear());
    };
  }, [success, error, loading, dispatch]);

  return (
    <>
      <QuotesContainer>
        <p>Use your superpowers to compare and get best deals</p>
        <SendContainer>
          <p>Quote ID-{quoteId}</p>
          <button onClick={() => setModal(true)}>Send Quotes</button>
        </SendContainer>
      </QuotesContainer>
      <SendQuotes onClose={setModal} show={modal}></SendQuotes>
      <div>
        <InputCard searchQuery={searchQuery} />
        <div className='fullwidth plan-home'>
          <div className='container'>
            {/* <div className="row">
              <div className="col-12 text-right pr-3">
                <a
                  onClick={() => {
                    setCompare((prev) => !prev);
                  }}
                  className="btn view-all-btn view-all-btn1"
                >
                  {compare ? "View All" : "Compare Plan"}
                </a>
              </div>
            </div> */}
          </div>
        </div>
        <div className='fullwidth profileDiv'>
          <div className='container'>
            <div className='row mt-2'>
              {policies?.map((plan, index) => (
                <PlanCard
                  key={`plan${index}`}
                  ic_name={plan.ic_name}
                  ic_logo={plan.ic_logo}
                  policy_wording={plan.policy_wording}
                  ic_tagline={plan.ic_tagline}
                  gst_amount={plan.tax}
                  plan_features={plan.plan_features}
                  final_premium={Math.round(Number(plan.final_premium))}
                  sum_insured={plan.sum_insured}
                  index={index}
                  id={plan.id}
                  compare={compare}
                  master_plan={plan.master_plan}
                  master_plan_id={plan.master_plan_id}
                  recommended={plan.recommended}
                  ic_id={plan.ic_id}
                  features={features}
                  username={user?.firstname}
                  page='plans'
                />
              ))}
            </div>
            <p className='text-right' style={{ fontSize: "16px" }}>
              <b style={{ fontSize: "13px", fontWeight: "700" }}>
                <i>*Premiums are exclusive of GST</i>
              </b>
            </p>
          </div>
        </div>
        <Info />
        <Compare searchQuery={searchQuery} />
        {loading && (
          <Spinner
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
            animation='border'
            role='status'
          >
            <span className='sr-only'>Loading...</span>
          </Spinner>
        )}
      </div>
      <Footer />
    </>
  );
};

const SendContainer = styled.div`
  display: flex;
  font-family: basier_squaremedium;
  flex-wrap: wrap;
  justify-content: flex-end;
  @media (max-width: 450px) {
    justify-content: center;
  }
  & > p {
    color: #ffffff;
    font-size: 16px;
    align-self: center;
    margin-right: 30px;
    @media (max-width: 450px) {
      margin-right: 0;
    }
  }
  & > button {
    border: 1.5px solid #ffffff;
    background-color: #287592;
    border-radius: 5px;
    padding: 7px 23px;
    height: auto;
    width: auto;
    box-sizing: content-box;
    color: #ffffff;
    font-size: 16px;
  }
`;

const QuotesContainer = styled.div`
  display: flex;
  background-color: #287592;
  justify-content: space-around;
  padding: 8px 40px;
  font-family: basier_squaremedium;
  flex-wrap: wrap;
  text-align: center;

  & > p {
    color: #ffffff;
    font-size: 18px;
    align-self: center;
  }
`;
