import * as yup from "yup";

const mobile = /[6-9]{1}[0-9]{9}$/;
const noSpChar = /^[a-zA-Z]+$/;
export const greetingSchema = yup.object({
  firstname: yup
    .string()
    .required("First name is required")
    .matches(noSpChar, "Please enter letters only")
    .label("First Name"),
  lastname: yup
    .string()
    .required("Last name is required")
    .matches(noSpChar, "Please enter letters only")
    .label("Last Name"),
  email: yup
    .string()
    .email("Please enter valid email id")
    .required("Personal email id is required")
    .label("Email ID"),
  mobile_no: yup
    .string()
    .required("Mobile No. is required")
    .min(10, "Mobile No. should be 10 digits")
    .matches(mobile, "Please enter valid mobile no.")
    .label("Mobile No.")
    .test(
      "mobile_no",
      "Mobile No. cannot be all same digits",
      (value) =>
        value !== "9999999999" &&
        value !== "8888888888" &&
        value !== "7777777777" &&
        value !== "6666666666"
    ),
});
