import React, { useState, useEffect, useRef } from "react";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

import { Form, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { Button } from "../../components";
import doubleArrow from "../../assets/images/double-arrow.svg";
import _, { range } from "lodash";
import moment from "moment";
import styled from "styled-components";
import "../../css/bootstrap-datepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getYear, getMonth } from "date-fns";
import "./formFile.scss";
import { numOnly, noNum } from "../Landing/utils";
import { lowerCase } from "../../utils";

const Genders = [
  { gender: "Male", id: "Male", value: "M" },
  { gender: "Female", id: "Female", value: "F" },
];

const mobile = /[6-9]{1}[0-9]{9}$/;
const validationSchema = (hdfc) =>
  yup.object({
    title: yup.string().required("Please select a valid Title"),
    firstname: yup
      .string()
      .required("First name is required")
      .matches(/^[a-zA-Z ]*$/, "Please enter a valid first name")
      .label("First Name"),
    lastname: yup
      .string()
      .required("Last name is required")
      .matches(/^[a-zA-Z ]*$/, "Please enter a valid last name")
      .label("Last Name"),
    dob: yup.string().required("Please enter your Date of Birth"),
    email: yup
      .string()
      .required("Email id is required")
      .email("Please enter a valid email address")
      .label("Email ID"),
    mobile_no: yup
      .string()
      .required("Please enter your contact number")
      .min(10, "Please enter a valid 10 digit contact number")
      .matches(mobile, "Please enter a valid 10 digit contact number")
      .label("Mobile No.")
      .test(
        "mobile_no",
        "Mobile No. should not be all same digits",
        (value) =>
          value !== "9999999999" &&
          value !== "8888888888" &&
          value !== "7777777777" &&
          value !== "6666666666"
      ),
    ...(hdfc && {
      gender: yup.string().nullable().required("Please Select Gender"),
    }),
  });

const FormComponent = (props) => {
  const genderRef = useRef();
  const attachRef = (ref) => {
    register(ref);
    genderRef.current = ref;
  };
  const maxDate = moment().subtract(18, "years")._d;
  const [startDate, setStartDate] = useState();
  const years = range(1940, getYear(maxDate) + 1, 1);
  const months = moment.months();
  const { handleSubmit, control, register, reset, errors, setValue } = useForm({
    defaultValues: !_.isEmpty(props.formData.title)
      ? props.formData
      : props.user,
    resolver: yupResolver(validationSchema(props.ic_id === 2)),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (props.formData.title) {
      reset(props.formData);
    }
  }, [props.formData, reset]);

  useEffect(() => {
    if (startDate || props.formData.dob) {
      setValue("dob", startDate || props.formData.dob, {
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, props.formData]);

  const validate = props?.validate;
  const onSubmit = props?.onSubmit;
  const TestSubmit = (data) => {
    const x = startDate
      ? moment(startDate, "MM/DD/YYYY").format("DD/MM/YYYY")
      : props.formData.dob
      ? moment(props.formData.dob, "DD-MM-YYYY").format("DD/MM/YYYY")
      : "";
    const dob = moment(x, "DD/MM/YYYY").format("DD-MM-YYYY");
    const newData = { ...data, dob };
    onSubmit(newData);
  };

  return (
    <>
      {/* //hdfc---------------------------------------------------- */}
      <Form
        style={{ marginTop: "-35px" }}
        onSubmit={handleSubmit(TestSubmit)}
        noValidate
        className='personal-details-form'
      >
        <Row xs={1} sm={1} md={1} lg={3} xl={3}>
          <div className='p-2'>
            <Form.Group
              controlId='exampleForm.SelectCustom'
              className='select-custom'
            >
              <Form.Label style={{ fontSize: "14px" }}>Title</Form.Label>
              <Controller
                as={
                  <Form.Control as='select'>
                    <option selected='true' disabled hidden>
                      Select
                    </option>
                    <option>Mr</option>
                    <option>Mrs</option>
                    <option value='Ms'>Miss</option>
                  </Form.Control>
                }
                required
                name='title'
                control={control}
              />
              {validate?.errors?.title || errors?.title ? (
                <p className='error'>
                  {validate?.errors?.title || errors?.title?.message}
                </p>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
          <div className='p-2'>
            <Form.Group>
              <Form.Label style={{ fontSize: "14px" }}>First Name</Form.Label>
              <Controller
                as={
                  <Form.Control
                    className='text-capitalize'
                    type='input'
                    placeholder='Enter First Name'
                  />
                }
                required
                onInput={(e) => {
                  lowerCase(e);
                  noNum(e);
                }}
                name='firstname'
                control={control}
              />
              {validate?.errors?.firstname || errors?.firstname ? (
                <p className='error'>
                  {validate?.errors?.firstname || errors?.firstname?.message}
                </p>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
          <div className='p-2'>
            <Form.Group>
              <Form.Label style={{ fontSize: "14px" }}>Last Name</Form.Label>
              <Controller
                as={
                  <Form.Control
                    className='text-capitalize'
                    type='input'
                    placeholder='Enter Last Name'
                  />
                }
                required
                name='lastname'
                onInput={(e) => {
                  lowerCase(e);
                  noNum(e);
                }}
                control={control}
              />
              {validate?.errors?.lastname || errors?.lastname ? (
                <p className='error'>
                  {validate?.errors?.lastname || errors?.lastname?.message}
                </p>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
        </Row>
        <Row xs={1} sm={1} md={1} lg={3} xl={3}>
          <div className='p-2'>
            <Form.Group>
              <Form.Label style={{ fontSize: "14px" }}>
                Date of Birth
              </Form.Label>
              <Border className='rdp'>
                <DatePicker
                  maxDate={maxDate}
                  className='date'
                  dateFormat='dd/MM/yyyy'
                  placeholderText='DD/MM/YYYY'
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div className='date-header'>
                      <button
                        className='date-button-left'
                        type='button'
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        <img
                          src={doubleArrow}
                          alt='double-arrow'
                          className='d-rotate'
                        />
                      </button>

                      <select
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        className='date-button-right'
                        type='button'
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        <img src={doubleArrow} alt='double-arrow' />
                      </button>
                    </div>
                  )}
                  selected={startDate}
                  value={
                    startDate
                      ? moment(startDate, "MM/DD/YYYY").format("DD/MM/YYYY")
                      : props.formData.dob
                      ? moment(props.formData.dob, "DD-MM-YYYY").format(
                          "DD/MM/YYYY"
                        )
                      : ""
                  }
                  onChange={(date) => {
                    setStartDate(date);
                    return date;
                  }}
                />
                <input
                  type='hidden'
                  name='dob'
                  ref={register}
                  value={startDate || props.formData.dob}
                ></input>
                {validate?.errors?.dob || errors?.dob ? (
                  <p className='error'>
                    {validate?.errors?.dob || errors?.dob?.message}
                  </p>
                ) : (
                  ""
                )}
              </Border>
            </Form.Group>
          </div>
          {props.ic_id === 2 && (
            <div className='form-group p-2'>
              <label style={{ fontSize: "14px" }}>Gender</label>
              <div>
                {Genders.map((item) => (
                  <div class='custom-control custom-radio'>
                    <input
                      type='radio'
                      className='custom-control-input'
                      id={item?.gender}
                      name='gender'
                      value={item?.value}
                      ref={attachRef}
                    />
                    <label className='custom-control-label' for={item?.id}>
                      {item?.gender || "-"}
                    </label>
                  </div>
                ))}
                {validate?.errors?.gender || errors?.gender ? (
                  <p className='error'>
                    {validate?.errors?.gender || errors?.gender?.message}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
          <div className='p-2'>
            <Form.Group controlId='formGridEmail'>
              <Form.Label style={{ fontSize: "14px" }}>Email ID</Form.Label>
              <Controller
                as={
                  <Form.Control
                    type='email'
                    placeholder='Email'
                    pattern='^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{1,})$'
                  />
                }
                required
                name='email'
                onInput={lowerCase}
                control={control}
                pattern='^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{1,})$'
              />
              {validate?.errors?.email || errors?.email ? (
                <p className='error'>
                  {validate?.errors?.email || errors?.email?.message}
                </p>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
          <div className='p-2'>
            <Form.Group>
              <Form.Label style={{ fontSize: "14px" }}>
                Mobile Number
              </Form.Label>
              <Controller
                as={<Form.Control type='text' maxLength='10' />}
                required
                name='mobile_no'
                control={control}
                onKeyPress={numOnly}
              />
              {validate?.errors?.mobile_no || errors?.mobile_no ? (
                <p className='error'>
                  {validate?.errors?.mobile_no || errors?.mobile_no?.message}
                </p>
              ) : (
                ""
              )}
            </Form.Group>
          </div>
        </Row>
        <Row style={{ justifyContent: "center", marginTop: "10px" }}>
          <Button
            type='submit'
            style={{
              fontWeight: "400",
              fontSize: "15px",
              borderRadius: "0.25rem",
              height: "35px",
              paddingTop: "5px",
            }}
          >
            {props.ic_id === 2
              ? "Proceed to Address & Policy Details"
              : "Proceed to Security Details"}
          </Button>
        </Row>
      </Form>
    </>
  );
};
const Border = styled.span`
  .react-datepicker__input-container input {
    border: 1px solid #ced4da;
  }
  @-moz-document url-prefix() {
    .date {
      border: 1px solid #ced4da;
      z-index: 0;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .date {
      border: 1px solid #ced4da;
      padding: 7px !important;
      z-index: 0;
    }
  }
`;

export default FormComponent;
