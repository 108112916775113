/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-eval */
import React, { useState, useEffect, useContext } from "react";
import swal from "sweetalert";
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import { Footer } from '../../components'
import { Loader, PlanModal } from "../../components";
import { Accordion, Card, Table, Button } from "react-bootstrap";
import { featureTitle } from "./compareNew1.helper";
import styled from 'styled-components';
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useMediaPredicate } from "react-media-hook";
// import EmailComparison from "../../components/Popup/EmailComparison";
import FooterCompare from "../../components/FooterCompare/FooterCompare";
import { updateProgress, loadAllQuotes, set_compare, loadAllFeatures, clear } from "../plans/plans.slice";
import { setToken } from '../Landing/landing.slice'
import { useParams } from 'react-router-dom';


/*------------------Context Accordion-----------------------*/
const StyledButton = styled(Button)`
  display: flex;
  width: 20px;
  float: right;
  padding: 0;
  &:focus {
    box-shadow: none;
  }
  &&.btn-link {
    color: red;
    text-decoration: none;
  }
  @media screen and (max-width: 776px) {
    display: none;
  }
`;

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = currentEventKey === eventKey;
  return (
    <StyledButton
      variant="link"
      className="open-button"
      onClick={decoratedOnClick}
    >
      {isCurrentEventKey ? (
        <i className="arrow up"></i>
      ) : (
          <i className="arrow down"></i>
        )}
    </StyledButton>
  );
};


export const CompareNew1 = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { compare: comp, features, policies, success } = useSelector(state => state.plans);
  const { isAuthorized } = useSelector(state => state.greeting);
  const [modal, setModal] = useState(false);
  // const [modals, setModals] = useState(false);
  const lessthan768 = useMediaPredicate("(max-width: 768px)");
  const { id, sumInsure, planType } = useParams();

  const AllIds = id.split(",");

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const { searchQuery } = useSelector((state) => state.greeting);
  const Lead_Source = query.get("utm_source");
  const Lead_Medium = query.get("utm_medium");
  const Campaign_Name = query.get("utm_campaign");

  useEffect(() => {
    if (!enquiry_id) {
      swal("Oops", "No Quotes Id Found", "info").then(() => {
        history.replace(`/${searchQuery ? '?' + searchQuery : ''}`);
      })
    }
    dispatch(setToken({ user_journey_id: enquiry_id }));

    return () => { dispatch(clear()) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!compare.length) {
      dispatch(loadAllQuotes({ sum_insured: sumInsure, plan_type: planType }));
      dispatch(loadAllFeatures());
      // dispatch(loadUser());
    }
    return () => { dispatch(clear()) }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  useEffect(() => {
    return () => { dispatch(clear()) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  const compare = [...comp]
  const compId = compare.map((policy) => {
    return policy.id;
  })

  useEffect(() => {
    if (policies.length && !compare.length) {
      policies.forEach((plan) => {
        if (AllIds.includes(String(plan.id))) {
          dispatch(set_compare(plan))
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policies])

  useEffect(() => {
    if (comp.length && comp.length === AllIds.length) {
      dispatch(updateProgress({
        has_seen_compare: 1,
        user_journey_id: enquiry_id,
        compare: compare,
        compId: compId,
        Lead_Source,
        Lead_Medium,
        Campaign_Name
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comp])


  useEffect(() => {
    if (lessthan768 && compare.length) {
      compare.splice(2, 3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessthan768, compare])

  const tdWidth = (75 / compare.length) + '%';
  const sortedFeatures = [12, 4, 1, 8, 6, 7, 9, 17, 2, 10, 11, 18, 19]


  const comma = (str) => {
    return str.toLocaleString("en-IN");
  };

  const HeaderPlans = (compare) => {
    return (
      <div className="column">
        <Table bordered className='text-right'>
          <tbody>
            <tr>
              <TD fontSize="16px" width="25%" >
                <div className='tlLaxmi'>
                  <img src="/assets/image/laxmiImg.png" width='58px' height="58px" alt="laxmi" style={{ border: "solid 1px #d2d3d4", borderRadius: "50%" }} />
                  <span style={{ fontSize: "15px", fontFamily: "Inter-Regular" }}>Compare to get better understanding of plans</span>
                </div>
              </TD>
              {compare.map((policy, index) => (
                <TD width={tdWidth} textAlign="center" >
                  {!!policy.recommended && <div class="recomendedPlan mx-auto" style={{ fontFamily: "Inter-SemiBold" }}>Recommended for you</div>}
                  <div><img className={`compareLogo ${lessthan768 ? '' : 'mt-4'}`} src={policy.ic_logo} alt="company_Logo" /></div>
                  <div className="companyName-compare">{policy.ic_name}</div>
                  <div className="planPrice-compare">
                    <b> &#8377; {comma(policy.final_premium)} </b>
                  </div>
                  <div>
                    <div
                      className="text-center profile-right"
                      onClick={() => setModal(policy)}
                    >
                      <div className="buy-btn-compare hdfc-card "> <p>BUY NOW</p> </div>
                      <div onClick={(e) => { e.stopPropagation(); setModal(false) }}></div>
                    </div>
                  </div>
                </TD>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }

  const PlanDetails = (compare) => {
    if (compare.length) {
      return (
        <Table bordered style={{ textAlign: "right", fontFamily: "Inter-Regular", fontSize: "14px", lineHeight: "24px" }}>
          <tbody>
            <tr>
              <TD fontSize="16px" width="25%" ><b>Plan Name</b></TD>
              {compare.map((policy) => (<TD width={tdWidth} textAlign="center">
                <span className='mobPlanHeading'>Plan Name</span>
                {policy.ic_tagline}
              </TD>))}
            </tr>

            <tr>
              <TD fontSize="16px" width="25%"><b>Sum Insured</b></TD>
              {compare.map((policy) => (<TD width={tdWidth} textAlign="center">
                <span className='mobPlanHeading'>Sum Insured</span>
                ₹&nbsp;{comma(policy.sum_insured)}
              </TD>))}
            </tr>

            <tr>
              <TD fontSize="16px" width="25%"><b>Who can be Covered?</b></TD>
              {compare.map((policy) => <TD width={tdWidth} textAlign="center">
                <span className='mobPlanHeading'>Who can be Covered?</span>
                {policy.ic_id === 1 ?
                  "Loss of Damage arising out of targeted intrusion in Insured’s Computer through internet and other wireless networks which may not necessarily be connected to the internet."
                  :
                  policy.ic_id === 2 ?
                    "Loss or damage which arises out of use of Internet only."
                    :
                    "-"
                }
              </TD>)}
            </tr>
          </tbody>
        </Table>
      )
    }
    else {
      return null
    }
  }


  const PlanCoverages = (compare) => {

    if (compare.length) {
      return (
        <Table bordered style={{ textAlign: "right", fontFamily: "Inter-Regular", fontSize: "14px", lineHeight: "24px" }}>
          <tbody>
            {features.map((elem, index) => {
              const feature0 = compare[0]?.plan_features.find((val) => val.id === sortedFeatures[index]);
              const feature1 = compare[1]?.plan_features.find((val) => val.id === sortedFeatures[index]);
              // eslint-disable-next-line no-unused-vars
              const feature2 = compare[2]?.plan_features.find((val) => val.id === sortedFeatures[index]);
              // eslint-disable-next-line no-unused-vars
              const feature3 = compare[3]?.plan_features.find((val) => val.id === sortedFeatures[index]);

              if (feature0?.cover_percentage && feature1?.cover_percentage) {
                return (
                  <tr>
                    <TD fontSize="16px" width="25%"><b>{featureTitle[index]}</b></TD>
                    {compare.map((policy, i) => (<TD width={tdWidth} textAlign="center" >
                      <span className='mobPlanHeading'>{featureTitle[index]}</span>
                      {eval('feature' + i)?.cover_percentage}</TD>))}
                  </tr>

                );
              }
              else if (sortedFeatures[index] === 19) {
                return (
                  <tr>
                    <TD fontSize="16px" width="25%"><b>{featureTitle[index]}</b></TD>
                    {compare.map((policy, i) => (<TD width={tdWidth} textAlign="center" >
                      <span className='mobPlanHeading'>{featureTitle[index]}</span>
                      {policy?.ic_id === 2 ? 'Add-on cover available at extra premium' : eval('feature' + i)?.cover_percentage || 'Not Available'}
                    </TD>))}
                  </tr>
                );
              }
              else if (sortedFeatures[index] === 18) {
                return (
                  <tr>
                    <TD fontSize="16px" width="25%"><b>{featureTitle[index]}</b></TD>
                    {compare.map((policy, i) => (<TD width={tdWidth} textAlign="center" >
                      <span className='mobPlanHeading'>{featureTitle[index]}</span>
                      {policy?.ic_id === 2 ? 'Add-on cover up to 10% of Sum Insured, available at extra premium' : eval('feature' + i)?.cover_percentage || 'Not Available'}
                    </TD>))}

                  </tr>
                );
              }
              else {
                return (
                  <tr>
                    <TD fontSize="16px" width="25%"><b>{featureTitle[index]}</b></TD>
                    {compare.map((policy, i) => (<TD width={tdWidth} textAlign="center" >
                      <span className='mobPlanHeading'>{featureTitle[index]}</span>
                      {policy?.ic_id === 2 ? 'Not Available' : eval('feature' + i)?.cover_percentage || 'Not Available'}
                    </TD>))}
                  </tr>
                );
              }

            })}
            <tr>
              <TD fontSize="16px" width="25%"><b>Waiting Period</b><br /><span className="compare-header-tag">(Period of time which must pass before your coverage can begin)</span></TD>
              {compare.map((policy) => (<TD width={tdWidth} textAlign="center" >
                <span className='mobPlanHeading'>Waiting Period</span>
                {policy?.ic_id === 1 ? (" No waiting period") : policy?.ic_id === 2 ? (
                  <>
                    First 45 days from the policy inception date for following
                    coverages only:
                    <ul>
                      <li>Damage to E Reputation</li>
                      <li>Cyber Extortion Cover / E-Extortion</li>
                      <li>Cyber Bullying and Harassment</li>
                    </ul>
                  </>
                ) : ("-")}
              </TD>))}
            </tr>
            <tr>
              <TD fontSize="16px" width="25%"><b>Deductible</b></TD>
              {compare.map((policy) => (<TD width={tdWidth} textAlign="center" >
                <span className='mobPlanHeading'>Deductible</span>
                {policy?.ic_id === 1 ? ("Nil") : policy?.ic_id === 2 ? (
                  <ul>
                    <li>Sum Insured upto INR 500,000 - Nil</li>
                    <li>
                      Sum Insured above INR 500,000 - INR 3,500 for each and every
                      claim
                    </li>
                  </ul>
                ) : ("-")}
              </TD>))}
            </tr>
          </tbody>
        </Table>
      )
    }
    else {
      return null
    }
  }

  return policies.length ? (
    <>
      <div className="mb-3 compare-div">
        <div className="plan-home">
          <div className="container text-right">
            <a href="#plans"
              onClick={() => history.push(`/plans?enquiry_id=${enquiry_id}${searchQuery ? '&' + searchQuery : ''}`)}
              style={{ float: "left" }}
              className="btn back-btn-compare back-compare">
              <i className="fa fa-angle-left" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                &nbsp;<span>BACK</span>
            </a>
            {/* <a
              onClick={() => setModals(true)}
              show={modals} onClose={setModals}
              style={{ float: "right", cursor: 'pointer', color: "black", fontSize: "14px", fontWeight: "normal", textDecoration: "underline", paddingTop: "2px" }}
              className=" mobPlanHeading">
              Email Quotes
            </a>
            <EmailComparison show={modals} onClose={setModals}></EmailComparison> */}
          </div>
        </div>
        <div class="formHeaderCont" >
          <img src="/assets/image/laxmiImg.png" width='72px' height="72px" alt="laxmi" style={{ border: "solid 1px #d2d3d4", borderRadius: "50%" }} />
          <span style={{ fontSize: "14px" }}>Compare to get better understanding of plans</span>
        </div>
        <div className="container">
          {HeaderPlans(compare)}
        </div>
      </div>
      <div className="container">
        <div className="column" >
          <Accordion defaultActiveKey="0" style={{ backgroundColor: "#e1e4e6" }}>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0" style={{ backgroundColor: "#e1e4e6" }}>
                <span className="accordian-header">Plan Details</span>
                <ContextAwareToggle eventKey={"0"} />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {PlanDetails(compare)}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
      <div className="container" style={{ paddingTop: "20px" }}>
        <div className="column" >
          <Accordion defaultActiveKey="1" style={{ backgroundColor: "#e1e4e6" }}>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1" style={{ backgroundColor: "#e1e4e6" }}>
                <span className="accordian-header">Plan Covers</span>
                <ContextAwareToggle eventKey={"1"} />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1" >
                <Card.Body>
                  {PlanCoverages(compare)}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
      <PlanModal data={modal} show={!!modal} onHide={() => setModal(false)} />
      {/* <Footer /> */}
      <div style={{ paddingTop: "160px" }}>
        <FooterCompare></FooterCompare>
      </div>

    </>
  ) : <Loader />
}

const TD = styled.td`
font-size: ${({ fontSize }) => (fontSize || '13px')};
width: ${({ width }) => (width || 'auto')};
text-align: ${({ textAlign }) => (textAlign || 'auto')};
vertical-align: middle;
`
