import React from "react";
import { Col, Row } from "react-bootstrap";
import { _capitalizeText } from "../../utils";

const Summary = (props) => {
  const Data = props?.formData;
  return (
    <>
      {props.ic_id === 2 ? (
        //hdfc
        <div style={{ marginTop: "-30px" }}>
          <Row>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Title</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {(Data?.title &&
                    (Data?.title === "Ms" ? "Miss" : Data?.title)) ||
                    "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>First Name</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {(Data?.firstname && _capitalizeText(Data?.firstname || '')) || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Last Name</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {(Data?.lastname && _capitalizeText(Data?.lastname || '')) || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Date of Birth</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {Data?.dob || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Gender</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {Data?.gender === "M"
                    ? "Male"
                    : (Data?.gender === "F" ? "Female" : "N/A") || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Email ID</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px", wordWrap: 'break-word' }}>
                  {(Data?.email && (Data?.email).toLowerCase()) || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Mobile Number.</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "14px" }}>
                  {Data?.mobile_no || "N/A"}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        //bajaj
        <div style={{ marginTop: "-30px" }}>
          <Row>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Title</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {Data?.title || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>First Name</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {(Data?.firstname && _capitalizeText(Data?.firstname || '')) || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Last Name</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {(Data?.lastname && _capitalizeText(Data?.lastname || '')) || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Date of Birth</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {Data?.dob || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Email ID</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px", wordWrap: 'break-word' }}>
                  {(Data?.email && (Data?.email).toLowerCase()) || "N/A"}
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div class="form-group">
                <div>
                  <label style={{ fontSize: "14px" }}>Mobile Number.</label>
                </div>
                <div style={{ marginTop: "-11px", fontSize: "13px" }}>
                  {Data?.mobile_no || "N/A"}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Summary;
