import styled from 'styled-components';

export const Button = styled.button`
  padding: 11px 17px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  outline: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-color:#efbf00;
  outline-color:#efbf00;
  -webkit-tap-highlight-color: transparent;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s;
  border:none;
  border-bottom: 3px solid transparent;
  color: #000;
  background-color: #f8cf39;

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
    background-image: none;
  }

  &:focus{
    outline: none;
    box-shadow: none;
  }
  white-space: nowrap;

  &:hover{
    box-shadow: 6.994px 5.664px 21px rgba(255, 204, 15, .8)!important;
    border-bottom:3px solid #efbf00;
    border-color:#efbf00;
  }
  `
  
export default Button;
