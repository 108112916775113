import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { PlanModal } from "./modal-card";
import { useSelector, useDispatch } from "react-redux";
import { set_compare, remove_compare } from "../../modules/plans/plans.slice";
import { updateProgress } from "../../modules/plans/plans.slice";
import { CloseBtn } from "./style";
import { useOutsideClick } from "../../custom-hooks";
import styled from "styled-components";
import { useLocation } from "react-router";
import { _capitalizeText } from "../../utils";

export const PlanCard = ({
  ic_name,
  ic_logo,
  policy_wording,
  ic_tagline,
  gst_amount,
  plan_features,
  final_premium,
  sum_insured,
  index,
  id,
  compare,
  master_plan,
  master_plan_id,
  recommended,
  ic_id,
  features,
  username,
}) => {
  const dispatch = useDispatch();
  const { compare: compareArray } = useSelector((state) => state.plans);
  const flag = compareArray.some((policy) => policy.id === id);
  const [toggle, setToggle] = useState(false);
  const [modal, setModal] = useState(false);
  const [comparePolicy, setComparePolicy] = useState(flag ? true : false);
  const comma = (str) => {
    return str.toLocaleString("en-IN");
  };
  const infoTipRef = useRef(null);
  useOutsideClick(infoTipRef, () => setToggle(false));
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const Lead_Source = query.get("utm_source");
  const Lead_Medium = query.get("utm_medium");
  const Campaign_Name = query.get("utm_campaign");

  useEffect(() => {
    const flag = compareArray.some((policy) => policy.id === id);
    if (!flag) {
      setComparePolicy(false);
    }
  }, [compareArray, id]);

  useEffect(() => {
    if (modal) {
      dispatch(
        updateProgress({
          user_journey_id: enquiry_id,
          has_seen_plan_details: 1,
          Lead_Source,
          Lead_Medium,
          Campaign_Name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, dispatch]);

  useEffect(() => {
    if (comparePolicy) {
      dispatch(
        set_compare({
          ic_name,
          ic_logo,
          policy_wording,
          ic_tagline,
          gst_amount,
          plan_features,
          final_premium,
          sum_insured,
          id,
          master_plan,
          master_plan_id,
          ic_id,
          recommended,
        })
      );
    } else {
      dispatch(remove_compare(id));
    }
  }, [
    comparePolicy,
    dispatch,
    ic_name,
    ic_logo,
    policy_wording,
    ic_tagline,
    gst_amount,
    plan_features,
    final_premium,
    sum_insured,
    id,
    master_plan,
    master_plan_id,
    ic_id,
    recommended,
  ]);
  return (
    <>
      <div
        className={`col-12 
       ${index === 0 ? "" : "col-sm-12 col-md-12 col-lg-6"}
       ${
         index % 2 === 0 && index !== 0
           ? "paddingR-none"
           : index === 0
           ? "p-0 pl-0"
           : "paddingL-none"
       }
      profile-left m-t-25`}
        onClick={() => {
          if (compare) {
            setComparePolicy(!comparePolicy);
          }
        }}
      >
        <div className="box-shadow recommend centerInIE">
          {index === 0 && (
            <div className="col-md-4 profileBox dot-border">
              <div className="profile">
                <img src="/assets/image/laxmiImg.png" alt="" />
              </div>
              <div className="profile-name">
                <h3>Hi {_capitalizeText(username || "")},</h3>
                <p>
                  Here's what I recommend basis the details you have provided.
                </p>
              </div>
            </div>
          )}
          <div
            className={`${
              index === 0 ? "col-sm-7 col-md-8 col-lg-8" : "col-12"
            } profile-right`}
          >
            <div className="fullwidth main-div">
              <div className="logo-div">
                <img src={ic_logo} alt="imgLogo" />
                <div>
                  <span>
                    {ic_name}
                    <small>{ic_tagline}</small>
                  </span>
                  <a
                    target="_blank"
                    href={policy_wording}
                    rel="noopener noreferrer"
                    className="knowMoreHdfc"
                  >
                    Policy Wording
                  </a>
                </div>
                <label
                  className="checkbox-container"
                  style={
                    compare ? { display: "inline-block" } : { display: "none" }
                  }
                >
                  <input
                    className="bajajCheck"
                    checked={comparePolicy}
                    onChange={(e) => setComparePolicy(e.target.checked)}
                    name="plan-selected"
                    type="checkbox"
                    style={{ borderRadius: "50%" }}
                    value="HDFC Life Click 2 Protect 3D Plus"
                  />
                  <span
                    className="checkmark"
                    style={{ borderRadius: "50%" }}
                  ></span>
                </label>
              </div>
              <div
                className="text-center btn-div1"
                onClick={(e) => {
                  setModal({
                    ic_name,
                    ic_logo,
                    policy_wording,
                    ic_tagline,
                    gst_amount,
                    plan_features,
                    final_premium,
                    sum_insured,
                    id,
                    master_plan,
                    master_plan_id,
                    ic_id,
                  });
                  e.stopPropagation();
                }}
              >
                <div className="buy-btn hdfc-card text-left">
                  <p>BUY</p>
                  <span>
                    &#8377; {comma(final_premium)}
                    <i
                      className="fa fa-exclamation-circle info-tip info-icon"
                      aria-hidden="true"
                      onClick={(e) => {
                        e.stopPropagation();
                        setToggle(true);
                      }}
                    ></i>
                  </span>
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setModal(false);
                  }}
                  className={`info-tooltip ${!!toggle ? "toggle" : ""}`}
                  ref={infoTipRef}
                >
                  <CloseBtn
                    type="button"
                    onClick={(e) => setToggle(false)}
                    className="float-right"
                  >
                    <div>
                      <Pos>&times;</Pos>
                    </div>
                  </CloseBtn>
                  <div className="basic tax-premium">
                    <h5>PREMIUM</h5>
                    <span>&#8377; {comma(final_premium)}</span>
                    <ul>
                      <li>
                        TAX GST (18%) <span>&#8377; {comma(gst_amount)}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="basic total-amt">
                    <p>Amount</p>
                    <b>&#8377; {comma(final_premium + gst_amount)}</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="fullwidth add-on">
              <ul
                className={index === 0 ? "" : "threeCol"}
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {plan_features?.map((list, index) => (
                  <li
                    className={
                      plan_features.some(
                        (elem) =>
                          elem.id === list.id && (elem.id !== 19 || ic_id === 2)
                      )
                        ? ""
                        : "disabled"
                    }
                    key={`list${index}`}
                  >
                    {list.feature_name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <PlanModal
        data={modal}
        show={!!modal}
        onHide={() => setModal(false)}
        isProceed
      />
    </>
  );
};

// PropTypes
PlanCard.propTypes = {
  ic_name: PropTypes.string,
  ic_logo: PropTypes.string,
  policy_wording: PropTypes.string,
  ic_tagline: PropTypes.string,
  gst_amount: PropTypes.number,
  plan_features: PropTypes.array,
  final_premium: PropTypes.number,
  sum_insured: PropTypes.number,
  index: PropTypes.number,
  id: PropTypes.number,
  master_plan: PropTypes.string,
  master_plan_id: PropTypes.number,
  ic_id: PropTypes.number,
  features: PropTypes.array,
  username: PropTypes.string,
};

// DefaultTypes
PlanCard.defaultProps = {
  ic_name: "Database Issue",
  ic_logo: "assets/image/avtar.png",
  policy_wording: "https://www.google.com/",
  ic_tagline: "Sorry for convinence",
  gst_amount: 0,
  plan_features: [],
  final_premium: 0,
  sum_insured: 0,
  index: 0,
  id: 0,
  master_plan: "-",
  master_plan_id: 0,
  ic_id: 0,
  features: [],
  username: "Sir",
};

// const Pos = styled.div`
// @supports (-ms-ime-align:auto) {
//   .selector {
//   color: red;
// }
//  }
// `;

const Pos = styled.div`
  @viewport {
    .selector {
      color: red;
    }
  }
`;

// const Pos = styled.div`
// //_:-ms-lang(x), .selector { color: red; }
// @supports (object-fit:cover) {
//   .element {
//       height: 100%;
//       object-fit:cover;
//   }
// }
// `;
